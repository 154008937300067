import { useEffect, useState } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import useGroupData from '../../../../common/state/group/UseGroupData';
import useSearchGroupData from '../../../../common/state/searchGroup/UseSearchGroupData';
import useSecurityData from '../../../../common/state/security/UseSecurityData';
import useSelectOrgUnitData from '../../../../common/state/selectOrgUnit/UseSelectOrgUnitData';

import NavLinks from '../NavLinks';

const INITIAL_VIEW_STATE = {
  text: 'Search',
  isItemSelected: false
};

const TAXONOMIES = ['GroupsDetail'];
const SCOPE = 'Group';

const useGroupSearch = () => {
  const navigate = useNavigate();
  const { groupState, getGroup, resetGroupState } = useGroupData();
  const { searchGroupState } = useSearchGroupData();
  const { resetSelectOrgUnitState } = useSelectOrgUnitData();
  const { getContextSecurity } = useSecurityData();
  const [state, setState] = useState(INITIAL_VIEW_STATE);

  const onAddGroup = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(NavLinks.GROUP_ADD);
  };

  const onEditGroup = (e, group) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    getGroup(group.orgGroupId);
    getContextSecurity(group.orgUnitId, TAXONOMIES, SCOPE);

    setState({ ...state, isItemSelected: true });
  };

  useEffect(() => {
    if (state.isItemSelected === true) {
      if (groupState.isObjLoading !== true && groupState.isObjLoaded === true) {
        if (groupState.objData.orgGroupId > 0) {
          navigate(NavLinks.GROUP_INFO);
        } else {
          setState(INITIAL_VIEW_STATE);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, groupState]);

  useEffect(() => {
    resetSelectOrgUnitState();
    resetGroupState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    searchGroupState,
    onAddGroup,
    onEditGroup
  };
};

export default useGroupSearch;