import Checkbox from "../../../../../common/components/checkboxes/Checkbox";

import global from '../../../../../common/components/GlobalStyle.module.css';

const SmallGridRow = ({ member, selectedPersons, onCheckboxClicked }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableRowHead}><span className="hidden-xs">{member.firstName || ''} {member.lastName || ''}</span>&nbsp;
      {onCheckboxClicked && (member.isSelected === true || !selectedPersons.some(x => x === member.personOrgGroupId)) &&
        <div className={global.CheckboxMobile}>
          <Checkbox
            className={global.IconButton}
            label=""
            name="isSelected"
            onChange={() => { onCheckboxClicked(member.groupQualificationEventId, member.personOrgGroupId) }}
            checked={member.isSelected} />
        </div>
      }
    </div>
    <div className={global.SmallTableRowInfo}>
      <div className="row">
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>Name</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>{member.firstName || ''} {member.lastName || ''}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Event</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{member.eventName || ''}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Swim Time</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{member.swimTime || ''}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Rank</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{member.rank || ''}</div>
      </div>
    </div>
  </div>
);

const InviteGroupMemberGridSmall = ({ data, selectedPersons, isLoading, onCheckboxClicked }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {isLoading === true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>&nbsp;</div>
          <div className={global.SmallTableRowInfo}>
            <div className={global.SmallTableRowLabels}>Loading...</div>
          </div>
        </div>
      )
      : data.length > 0
        ? data.map((member, i) => <SmallGridRow key={i} member={member} selectedPersons={selectedPersons} onCheckboxClicked={onCheckboxClicked} />)
        : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>&nbsp;</div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Alternate Athletes</div>
            </div>
          </div>
        )
    }
  </div>
);

export default InviteGroupMemberGridSmall;