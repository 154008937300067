import { useContext } from 'react';

import { GroupStateContext } from './GroupContextProvider';
import { GroupFiltersStateContext } from './GroupFiltersContextProvider';

import GroupData from './GroupData';

const useGroupData = () => {
  const [groupState, setGroupState] = useContext(GroupStateContext);
  const [groupFiltersState, setGroupFiltersState] = useContext(GroupFiltersStateContext)

  const searchGroups = (filterObject) => {
    setGroupFiltersState({ ...groupFiltersState, filterObject });
    GroupData.searchGroups(JSON.stringify(filterObject), groupState, setGroupState);
  };

  const postGroup = (orgGroupObj) => GroupData.postGroup(orgGroupObj, groupState, setGroupState);

  const getGroup = (orgGroupId) => GroupData.getGroup(orgGroupId, groupState, setGroupState);

  const putGroup = (orgGroupId, orgGroupObj) => GroupData.putGroup(orgGroupId, orgGroupObj, groupState, setGroupState);

  const deleteGroup = (orgGroupId) => GroupData.deleteGroup(orgGroupId, groupState, setGroupState);

  const getGroupMembers = (orgGroupId, viewState, setViewState) => {
    GroupData.getGroupMembersData(orgGroupId, viewState, setViewState);
  };

  const getGroupCoaches = (coaches, state, setState) => {
    const personsIds = [];
    for (const coach of coaches) {
      if (coach.fieldValue > 0) {
        personsIds.push(coach.fieldValue);
      }
    }

    const personIdsString = personsIds.toString();
    if (personIdsString !== '') {
      GroupData.getGroupCoaches(personIdsString, state, setState);
    } else {
      setState({
        ...state,
        isArrayLoaded: true,
        isArrayLoading: false,
        arrayData: []
      });
    }
  };

  const resetGroupState = () => {
    setGroupState(GroupData.INITIAL_STATE);
  }

  const clearObjData = () => {
    setGroupState({
      ...groupState,
      isObjLoading: false,
      isObjLoaded: false,
      isSaving: false,
      isSaved: false,
      objData: {},
      message: ''
    });
  };

  const clearArrayData = () => {
    setGroupState({
      ...groupState,
      isArrayLoading: false,
      isArrayLoaded: false,
      arrayData: [],
      message: ''
    });
  };

  const clearGroupFilterData = () => {
    setGroupFiltersState({ filterObject: {} })
  };

  const confirmSave = () => {
    setGroupState({
      ...groupState,
      isSaved: false
    });
  };

  return {
    groupState,
    groupFiltersState,
    searchGroups,
    postGroup,
    getGroup,
    getGroupMembers,
    putGroup,
    deleteGroup,
    getGroupCoaches,
    resetGroupState,
    clearObjData,
    clearArrayData,
    clearGroupFilterData,
    confirmSave
  };
};

export default useGroupData;