import CompetitorListFormValidation from '../../components/forms/CompetitorListAttributesFormValidation';

import GroupInfoFormValidation from '../../../../../common/components/forms/groups/GroupInfoFormValidation';

const GroupCompetitorListAddValidation = async (formState) => {
  let errors = {
    ...GroupInfoFormValidation(formState),
    ...CompetitorListFormValidation(formState)
  };

  return errors ? errors : {};
};

export default GroupCompetitorListAddValidation;