import { CommonHttpHelper, NO_DATA_MESSAGE } from '../../../common/utils/HttpHelper';

const putGroupAlternateMembersIMXData = (orgGroupId, alternateMembersArray, state, setState) => {
  if (state.isSaving !== true && !state.message) {
    let newState = {
      ...state,
      isSaving: true,
      isSaved: false,
      message: 'Saving...'
    };
    setState(newState);

    const orgGroupIdForUrl = orgGroupId ? encodeURIComponent(orgGroupId) : 'NaN';
    const url = `/OrgGroup/${orgGroupIdForUrl}/ImxAlternate`;
    CommonHttpHelper(url, 'PUT', alternateMembersArray)
      .then((arrayData) => {
        if (!arrayData) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          newState = {
            ...newState,
            isArrayLoaded: true,
            isSaving: false,
            isSaved: true,
            arrayData,
            message: ''
          };
          setState(newState);
        }
      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...newState,
          isArrayLoaded: false,
          isSaving: false,
          isSaved: false,
          message: message
        });
      });
  }
};

export default putGroupAlternateMembersIMXData;