import { API_NAMES, initApi } from "../../../common/utils/HttpApiHelper";

const searchGroupByGroupCodeData = (filterObject, state, setState) => {
  if (filterObject) {
    const api = initApi(API_NAMES.COMMON, state, setState);

    const filterObjectForUrl = filterObject ? encodeURIComponent(filterObject) : 'NaN';
    const url = `/OrgGroup/search?filters=${filterObjectForUrl}`;

    return api.executeArray ? api.executeArray(url, 'GET') : null;
  }
};

const GroupLookupData = {
  searchGroupByGroupCodeData
};

export default GroupLookupData;