import React from 'react';

import useGroupReporting from './UseGroupReporting';

import ReportList from '../../../../common/components/embeddedReports/ReportList';

const GroupReporting = () => {
  const { reportParameters, contextId, routeName } = useGroupReporting();

  return (
    <ReportList listTitle="Group Reports" 
      routeName={routeName}
      contextId={contextId}
      reportParameters={reportParameters} />
  );
};

export default GroupReporting;