import React, { Fragment } from 'react';

import SearchGroup from '../../../../common/components/searches/searchGroup/SearchGroup';

import useGroupSearch from './UseGroupSearch';

const GroupSearch = () => {
  const { 
    searchGroupState, 
    onAddGroup, 
    onEditGroup 
  } = useGroupSearch();

  return (
    <Fragment>
      <SearchGroup
        pageTitle={'Group Search'}
        onAddGroup={onAddGroup}
        onEditGroup={onEditGroup}
        searchGroupState={searchGroupState}
      />
    </Fragment >
  );
};

export default GroupSearch;