import { BASIC_INITIAL_STATE } from "../../../common/utils/HttpHelper";

import getGroupAlternateMembersData from './GetGroupAlternateMembersData';
import putGroupAlternateMembersData from "./PutGroupAlternateMembersData";
import getGroupAlternateMembersIMXData from './GetGroupAlternateMembersIMXData';
import putGroupAlternateMembersIMXData from './PutGroupAlternateMembersIMXData';
 
const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE,
};

const GroupAlternateMembersData = {
  INITIAL_STATE,
  getGroupAlternateMembersData,
  getGroupAlternateMembersIMXData,
  putGroupAlternateMembersData,
  putGroupAlternateMembersIMXData
};

export default GroupAlternateMembersData;