import React, { Fragment } from 'react';

import useSearchMyGroups from './UseSearchMyGroups';

import SearchMyGroupsGrid from './SearchMyGroupsGrid';

import Constants from '../../../common/utils/Constants';

import Input from '../../../common/components/inputs/Input';
import ActionIntraPageButton from '../../../common/components/buttons/ActionIntraPageButton';
import Headings from '../../../common/components/headings/Headings';
import PopUpModal from '../../../common/components/dialogs/PopUpModal';
import GroupCategoriesTreeView from '../../../common/components/tree/groupCategoriesTree/GroupCategoriesTreeView';

import global from '../../../common/components/GlobalStyle.module.css';

const Form = ({ handleSubmit, formState, errorState, onFormValueChanged, clearForm, onAddGroup, orgUnitId }) => (
  <form onSubmit={handleSubmit} noValidate>
    <div className="row">
      {orgUnitId &&
        <div className="col-xs-12 col-sm-6 col-md-4">
          <GroupCategoriesTreeView
            label="Group Category*"
            name="groupCategories"
            value={formState.groupCategories}
            error={errorState.groupCategories}
            message={errorState.groupCategories}
            selectableLeavesOnly={false}
            singleSelect={false}
            onChange={(value) => { onFormValueChanged('groupCategories', value) }}
            orgUnitId={orgUnitId} />
        </div>
      }
      <div className="col-xs-12 col-sm-6 col-md-4">
        <Input
          label="Group Name"
          name="groupName"
          value={formState.groupName}
          error={errorState.groupName}
          message={errorState.groupName}
          onChange={(value) => { onFormValueChanged('groupName', value) }} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <Input
          label="Group Code"
          name="groupCode"
          value={formState.groupCode}
          error={errorState.groupCode}
          message={errorState.groupCode}
          onChange={(value) => { onFormValueChanged('groupCode', value) }} />
      </div>
      <div className='col-xs-6 col-sm-2 col-md-1'>
        <button className={global.PlainButton} type="submit">Filter</button>
      </div>
      <div className='col-xs-6 col-sm-4 col-md-2'>
        <button className={global.PlainButton} type="button" onClick={clearForm}>Clear Filter</button>
      </div>
    </div>
    {(errorState.filter || errorState.errorOnSubmitAction) &&
      <div className="row">
        <div className="col-xs-12">
          <p className={global.ErrorMessage}>{errorState.filter ? errorState.filter : ''}</p>
          <p className={global.ErrorMessage}>{errorState.errorOnSubmitAction || ''}</p>
        </div>
      </div>}
    <div className="row usas-extra-top-margin">
      <div className="col-xs-12 usas-extra-top-margin">
        {onAddGroup !== undefined ? <ActionIntraPageButton type="button" onClick={onAddGroup}>Add Group</ActionIntraPageButton> : <Fragment />}
      </div>
    </div>
  </form >
)

const SearchMyGroups = ({ pageTitle, onAddGroup, onEditGroup, searchGroupState, selectOrgUnitState }) => {
  const {
    formState,
    errorState,
    groupState,
    groupCategoryState,
    clearForm,
    handleSubmit,
    onFormValueChanged,
    onFiltersToggle
  } = useSearchMyGroups(selectOrgUnitState);

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>{pageTitle}</Headings.H3>
        </div>
      </div>
      {searchGroupState !== undefined && selectOrgUnitState.orgUnitId ?
        //toggles filters and results
        <Fragment>
          <div className={searchGroupState.showFilters === true ? global.DisplayComponent : global.HideComponent}>
            <Form
              formState={formState}
              errorState={errorState}
              clearForm={clearForm}
              handleSubmit={handleSubmit}
              onFormValueChanged={onFormValueChanged}
              onAddGroup={onAddGroup}
              orgUnitId={selectOrgUnitState.orgUnitId}
            />
          </div>
          <div className="row usas-extra-bottom-margin">
            <div className="col-xs-12">
              <SearchMyGroupsGrid
                groupState={groupState}
                searchGroupState={searchGroupState}
                onEditGroup={onEditGroup}
                onFiltersToggle={onFiltersToggle}
              />
            </div>
          </div>
        </Fragment>
        :
        //shows filters and results at the same time
        <Fragment>
          <Form
            formState={formState}
            errorState={errorState}
            clearForm={clearForm}
            handleSubmit={handleSubmit}
            onFormValueChanged={onFormValueChanged}
            onAddGroup={onAddGroup}
          />
          <div className="row usas-extra-bottom-margin">
            <div className="col-xs-12">
              <SearchMyGroupsGrid
                groupState={groupState}
                onEditGroup={onEditGroup}
              />
            </div>
          </div>
        </Fragment>
      }
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={groupState.isArrayLoading || groupState.isObjLoading || groupCategoryState.isArrayLoading} />
    </Fragment >
  );
};

export default SearchMyGroups;