import Constants from '../../../common/utils/Constants';
import { TimesHttpHelper, NO_DATA_MESSAGE, BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

export const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE,
  orgUnitId: '',
  timeStandardTypeId: Constants.DEFAULT_ID,
  items: []
};

const getTimeStandardByOrgUnitIdAndTimeStandardTypeId = (orgUnitId, timeStandardTypeId, state, setState) => {
  if (state.isArrayLoading !== true && orgUnitId && timeStandardTypeId > Constants.DEFAULT_ID && !state.message) {
    let newState = {
      ...state,
      isArrayLoading: true,
      isArrayLoaded: false,
      orgUnitId,
      timeStandardTypeId,
      message: 'Loading...'
    };
    setState(newState);

    const orgUnitIdForUrl = orgUnitId ? encodeURIComponent(orgUnitId) : '';
    const timeStandardTypeIdForUrl = timeStandardTypeId ? encodeURIComponent(timeStandardTypeId) : 0;

    const url = `/TimeStandard/GetForOrgUnitAndType/${orgUnitIdForUrl}/${timeStandardTypeIdForUrl}`;
    TimesHttpHelper(url, 'GET')
      .then((arrayData) => {
        if (!arrayData) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          newState = {
            ...newState,
            isArrayLoading: false,
            isArrayLoaded: true,
            arrayData,
            items: arrayData.map((x) => { return { id: x.timeStandardId, name: x.standardName } }),
            message: ''
          };
          setState(newState);
        }

      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...newState,
          isArrayLoading: false,
          isArrayLoaded: false,
          message: message
        });
      });
  }
};

const TimeStandardComboboxData = { INITIAL_STATE, getTimeStandardByOrgUnitIdAndTimeStandardTypeId };

export default TimeStandardComboboxData;