import { CommonHttpHelper, NO_DATA_MESSAGE, BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

const postGroupMemberIds = (orgGroupId, memberIds, state, setState) => {
  if (state.isSaving !== true && orgGroupId && Array.isArray(memberIds) && !state.message) {
    let newState = {
      ...state,
      isSaving: true,
      isSaved: false,
      message: 'Saving...'
    };
    setState(newState);

    const orgGroupIdForUrl = orgGroupId ? encodeURIComponent(orgGroupId) : '';
    const url = `/OrgGroup/${orgGroupIdForUrl}/MembersUpload`;
    CommonHttpHelper(url, 'POST', memberIds)
      .then((objData) => {
        if (!objData) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          newState = {
            ...newState,
            isSaving: false,
            isSaved: true,
            objData,
            message: ''
          };
          setState(newState);
        }

      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...newState,
          isSaving: false,
          isSaved: false,
          message: message
        });
      });
  }
};

const GroupMemberUploadData = { INITIAL_STATE: BASIC_INITIAL_STATE, postGroupMemberIds };

export default GroupMemberUploadData;

