import { API_NAMES, initApi } from '../../../common/utils/HttpApiHelper';

const postGroupMembersCopyData = (orgGroupId, copyOrgGroupId, state, setState) => {
  if (orgGroupId > 0 && copyOrgGroupId > 0) {
    const api = initApi(API_NAMES.COMMON, state, setState);

    const orgGroupIdForUrl = orgGroupId ? encodeURIComponent(orgGroupId) : 'NaN';
    const copyOrgGroupIdForUrl = copyOrgGroupId ? encodeURIComponent(copyOrgGroupId) : 'NaN';
    const url = `/OrgGroup/${orgGroupIdForUrl}/MemberCopyOrgGroup/${copyOrgGroupIdForUrl}`;
    
    return api.executeObject ? api.executeObject(url, 'POST') : null;
  }
};

const GroupCopyMembersData = {
  postGroupMembersCopyData
};

export default GroupCopyMembersData;

