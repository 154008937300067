import { useEffect } from 'react';

import { createGroupObjFromGroupRegistrationForm } from '../utils/GroupUtils';

import validate from '../components/forms/GroupRegistrationFormValidation';

import useGroupData from '../../../../common/state/group/UseGroupData';
import useOrgGroupTypesData from '../../../../common/state/orgGroupTypes/UseOrgGroupTypesData';
import useOrgRoleStaffRoleHierarchyData from '../../../../common/state/orgRoleStaffRoleHierarchy/UseOrgRoleStaffRoleHierarchyData';
import useEnvironmentVariableData from '../../../../common/state/environmentVariable/UseEnvironmentVariableData';

import useForm from '../../../../common/utils/UseForm';
import HierarchicalDataUtils from '../../../../common/utils/HierarchicalDataUtils';
import Constants from '../../../../common/utils/Constants';
import { formatDate } from '../../../../common/utils/DateFunctions';

const useGroupRegistration = () => {
  const { environmentVariableState, GROUP_ROLES_ROLE_GROUP_ID } = useEnvironmentVariableData();
  const { orgGroupTypesState } = useOrgGroupTypesData();
  const { orgRoleStaffRoleHierarchyState } = useOrgRoleStaffRoleHierarchyData();
  const { groupState, putGroup } = useGroupData();
  const { formState, errorState, onFormValueChanged, handleSubmit, setFormData, resetForm
  } = useForm(getInitialFormState, submitFormCallback, validate);

  useEffect(() => {
    if (groupState.isObjLoaded === true && orgRoleStaffRoleHierarchyState.isArrayLoaded === true && environmentVariableState.isLoaded === true
      && orgGroupTypesState.isArrayLoaded === true && orgRoleStaffRoleHierarchyState.roleGroupId === GROUP_ROLES_ROLE_GROUP_ID) {
      const groupObj = groupState.objData;

      setFormData({
        ...formState,
        hasRegistration: groupObj.hasRegistration ?? '',
        requiresApproval: groupObj.requiresApproval ?? '',
        hasOpenRegistration: groupObj.hasOpenRegistration ?? '',
        hasRequiresApproval: getHasRequiresApproval(groupObj.groupTypeId, orgGroupTypesState.arrayData),
        hasOrgRoleRestriction: groupObj.hasOrgRoleRestriction ?? '',
        registrationCloseDate: groupObj.registrationCloseDate ? formatDate(groupObj.registrationCloseDate) : Constants.BLANK_DATE_STRING,
        registrationOpenDate: groupObj.registrationOpenDate ? formatDate(groupObj.registrationOpenDate) : Constants.BLANK_DATE_STRING,
        eligibleRoles: HierarchicalDataUtils.GetNameIdPairs(orgRoleStaffRoleHierarchyState.treeData, groupObj.orgGroupEligibility.map(x => x.orgRoleId))
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupState, orgGroupTypesState, orgRoleStaffRoleHierarchyState, environmentVariableState]);

  function getHasRequiresApproval(groupTypeId, groupTypes) {
    const currentGroupType = groupTypes.find(x => x.groupTypeId === groupTypeId);

    if (currentGroupType) {
      return currentGroupType.hasRequiresApprovalOption === true;
    } else {
      return false;
    }
  };

  function submitFormCallback(formState) {
    if (groupState.isSaving === false) {
      putGroup(groupState.objData.orgGroupId, createGroupObjFromGroupRegistrationForm(groupState.objData, formState, groupState.objData.orgGroupId));
    }
  };

  function getInitialFormState() {
    return {
      hasRegistration: '',
      requiresApproval: '',
      hasOpenRegistration: '',
      hasRequiresApproval: false,
      hasOrgRoleRestriction: '',
      registrationCloseDate: Constants.BLANK_DATE_STRING,
      registrationOpenDate: Constants.BLANK_DATE_STRING,
      eligibleRoles: []
    };
  };

  return {
    isLoading: environmentVariableState.isLoading || orgGroupTypesState.isArrayLoading || groupState.isObjLoading,
    isSaving: groupState.isSaving,
    formState,
    errorState,
    handleSubmit,
    onFormValueChanged,
    onCancelClicked: resetForm,
    orgUnitId: groupState.objData.orgUnitId || '',
    GROUP_ROLES_ROLE_GROUP_ID: GROUP_ROLES_ROLE_GROUP_ID || Constants.DEFAULT_ID
  };
};

export default useGroupRegistration;