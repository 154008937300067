import { useState } from "react";

import useGroupMemberUploadData from "../../../state/groupMemberUpload/UseGroupMemberUploadData";

import useGroupData from "../../../../common/state/group/UseGroupData";

import Constants from "../../../../common/utils/Constants";
import { isValidMemberId } from "../../../../common/utils/validation";

const useGroupMemberImport = () => {
  const { groupState } = useGroupData();
  const { groupMemberUploadState, postGroupMemberIds, confirmSave } = useGroupMemberUploadData();
  const [fileState, setFileState] = useState({});
  const [memberIdArrayState, setMemberIdArrayState] = useState([]);
  const [errorState, setErrorState] = useState('');

  const onLoadFile = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    const file = e.target.files[0];

    if (file) {
      confirmSave();

      if (file.type === Constants.FILE_TYPE_CSV) {
        readCsvFile(file);
      } else {
        setErrorState(`Invalid file type ${file.type ? `(${file.type}) ` : ''}loaded`);
      }
    } else {
      setErrorState('No file loaded');
    }
  };

  const onDownloadJSON = () => {
    const json = JSON.stringify(memberIdArrayState);
    const blob = new Blob([json], { type: 'application/json' });
    const href = URL.createObjectURL(blob);
    const link = document.createElement('a');

    link.href = href;
    link.download = 'MemberIds.json';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const onUploadMemberIds = () => {
    postGroupMemberIds(groupState.objData?.orgGroupId, memberIdArrayState);
  };

  function readCsvFile(file) {
    const fileReader = new FileReader();

    fileReader.onload = function (event) {
      parseFileResult(event.target.result);
    };

    fileReader.readAsText(file);

    setFileState(file);
    setErrorState('');
  };

  function parseFileResult(result) {
    const fileContent = result.split(/\s/);

    setMemberIdArrayState(fileContent.filter(x => isValidMemberId(x) === true));
  };

  return {
    isSaving: groupMemberUploadState.isSaving,
    groupMemberUploadState,
    memberIdArrayState,
    fileState,
    errorState,
    onLoadFile,
    onDownloadJSON,
    onUploadMemberIds
  };
};

export default useGroupMemberImport;