import useListRegistrationForm from './UseListRegistrationForm';

import Dropdown from '../../../../../common/components/dropdowns/Dropdown';
import PrimaryButton from '../../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../../common/components/buttons/SecondaryButton';

import global from '../../../../../common/components/GlobalStyle.module.css';

const ListRegistrationForm = ({ personId, personOrgGroupId, personOrgGroupQualificationStatus, groupObj, memberGroupObj,
  primaryButtonText = 'Save', secondaryButtonText = 'Back', onSecondaryButtonClicked, onFormCompletion }) => {
  const {
    formState,
    errorState,
    optionsState,
    handleSubmit,
    onValueTextPairChanged
  } = useListRegistrationForm(personId, personOrgGroupId, personOrgGroupQualificationStatus, groupObj, memberGroupObj, onFormCompletion);

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-4">
          <Dropdown
            label={'Potential/Confirmed'}
            name="potentialOrConfirmedId"
            value={formState.potentialOrConfirmedId}
            error={errorState.potentialOrConfirmedId}
            message={errorState.potentialOrConfirmedId}
            areValuesIntegers={false}
            isLoading={optionsState.areOptionsLoaded !== true}
            options={optionsState.potentialOrConfirmedOptions}
            onChange={(newValue, newValueLabel) => { onValueTextPairChanged(newValue, 'potentialOrConfirmedId', newValueLabel, 'potentialOrConfirmedName'); }} />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
          <PrimaryButton type="submit">{primaryButtonText}</PrimaryButton>&nbsp;
          <SecondaryButton type="button" onClick={onSecondaryButtonClicked}>{secondaryButtonText}</SecondaryButton>
          {errorState && errorState.errorOnSubmitAction
            && <p className={global.ErrorMessage}>{errorState.errorOnSubmitAction}</p>
          }
        </div>
      </div>
    </form>
  );
};

export default ListRegistrationForm;