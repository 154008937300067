import React, { Fragment } from 'react';

import YearDropdown from '../../../../components/dropdowns/YearDropdown';

import DatePicker from '../../../../../common/components/datepickers/DatePicker';
import Combobox from '../../../../../common/components/comboboxes/Combobox';

const SSAAttributesForm = ({ formState, errorState, onFormValueChanged, onValueTextPairChanged, isComboboxLoading,
  combobboxItems }) => (
  <Fragment>
    <div className="row">
      <div className="col-xs-12 col-sm-6 col-md-4">
        <YearDropdown
          label="Year*"
          name="groupYear"
          value={formState.groupYear}
          error={errorState.groupYear}
          message={errorState.groupYear}
          countOfYears={15}
          startYearOffset={-5}
          onChange={(newValue) => { onFormValueChanged('groupYear', newValue) }} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <DatePicker label="Qualifying Start Date*"
          name="qualifyingStartDate"
          value={formState.qualifyingStartDate}
          error={errorState.qualifyingStartDate}
          message={errorState.qualifyingStartDate}
          onChange={(value) => { onFormValueChanged('qualifyingStartDate', value); }}
          countOfYears={15}
          startYearOffset={-5} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <DatePicker label="Qualifying End Date*"
          name="qualifyingEndDate"
          value={formState.qualifyingEndDate}
          error={errorState.qualifyingEndDate}
          message={errorState.qualifyingEndDate}
          onChange={(value) => { onFormValueChanged('qualifyingEndDate', value); }}
          countOfYears={15}
          startYearOffset={-5} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <Combobox
          label="Time Standard*"
          name="timeStandardId"
          valueToMatch={formState.timeStandardName}
          error={errorState.timeStandardId}
          message={errorState.timeStandardId}
          onChange={(newValue, newValueLabel, e) => { onValueTextPairChanged(newValue, 'timeStandardId', newValueLabel, 'timeStandardName', e); }}
          isLoading={isComboboxLoading}
          items={combobboxItems} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <Combobox
          label="Time Standard P1*"
          name="timeStandardP1Id"
          valueToMatch={formState.timeStandardP1Name}
          error={errorState.timeStandardP1Id}
          message={errorState.timeStandardP1Id}
          onChange={(newValue, newValueLabel, e) => { onValueTextPairChanged(newValue, 'timeStandardP1Id', newValueLabel, 'timeStandardP1Name', e); }}
          isLoading={isComboboxLoading}
          items={combobboxItems} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <Combobox
          label="Time Standard P2*"
          name="timeStandardP2Id"
          valueToMatch={formState.timeStandardP2Name}
          error={errorState.timeStandardP2Id}
          message={errorState.timeStandardP2Id}
          onChange={(newValue, newValueLabel, e) => { onValueTextPairChanged(newValue, 'timeStandardP2Id', newValueLabel, 'timeStandardP2Name', e); }}
          isLoading={isComboboxLoading}
          items={combobboxItems} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <Combobox
          label="Time Standard P3*"
          name="timeStandardP3Id"
          valueToMatch={formState.timeStandardP3Name}
          error={errorState.timeStandardP3Id}
          message={errorState.timeStandardP3Id}
          onChange={(newValue, newValueLabel, e) => { onValueTextPairChanged(newValue, 'timeStandardP3Id', newValueLabel, 'timeStandardP3Name', e); }}
          isLoading={isComboboxLoading}
          items={combobboxItems} />
      </div>
    </div>
  </Fragment>
);

export default SSAAttributesForm;