import DeleteIcon from "../../../../../common/components/icons/DeleteIcon";
import SGColumnHeader from "../../../../../common/components/grids/SGColumnHeader";

import useSortableGrid from "../../../../../common/utils/UseSortableGrid";
import SGConstants from "../../../../../common/utils/SGConstants";

import global from '../../../../../common/components/GlobalStyle.module.css';

const LargeGridRow = ({ member, onDeleteClicked }) => (
  <tr>
    <td>{member.firstName || ''} {member.lastName || ''}</td>
    <td>{member.memberId || ''}</td>
    <td>{member.email || ''}</td>
    <td>
      {onDeleteClicked &&
        <button
          className={global.IconButtonMargin}
          type="button"
          onClick={() => onDeleteClicked(member)}>
          <DeleteIcon />
        </button>
      }
    </td>
  </tr>
);

const DefaultGroupMemberGridLarge = ({ data, isLoading, onDeleteClicked }) => {
  const {
    sortableGridState,
    onColumnClicked
  } = useSortableGrid(data);

  return (
    <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
      <thead>
        <tr>
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Name'} columnField={'lastName'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Member Id'} columnField={'memberId'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Email'} columnField={'email'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {isLoading === true
          ? <tr><td colSpan="4">Loading...</td></tr>
          : sortableGridState.sortedGridData.length > 0
            ? sortableGridState.sortedGridData.map((member, i) => <LargeGridRow key={i} member={member} onDeleteClicked={onDeleteClicked} />)
            : <tr><td colSpan="4">No Group Members</td></tr>
        }
      </tbody>
    </table>
  );
};

export default DefaultGroupMemberGridLarge;