import { Fragment } from "react";

import CampGroupMemberGridLarge from "./CampGroupMemberGridLarge";
import CampGroupMemberGridSmall from "./CampGroupMemberGridSmall";

const CampGroupMemberGrid = ({ data, isLoading, onEditClicked, onDeleteClicked }) => (
  <Fragment>
    <CampGroupMemberGridLarge data={data} isLoading={isLoading} onEditClicked={onEditClicked} onDeleteClicked={onDeleteClicked} />
    <CampGroupMemberGridSmall data={data} isLoading={isLoading} onEditClicked={onEditClicked} onDeleteClicked={onDeleteClicked} />
  </Fragment>
);

export default CampGroupMemberGrid;