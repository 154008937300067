import { Fragment } from "react";

import DefaultGroupMemberGridLarge from "./DefaultGroupMemberGridLarge";
import DefaultGroupMemberGridSmall from "./DefaultGroupMemberGridSmall";

const DefaultGroupMemberGrid = ({ data, isLoading, onDeleteClicked }) => (
  <Fragment>
    <DefaultGroupMemberGridLarge data={data} isLoading={isLoading} onDeleteClicked={onDeleteClicked} />
    <DefaultGroupMemberGridSmall data={data} isLoading={isLoading} onDeleteClicked={onDeleteClicked} />
  </Fragment>
);

export default DefaultGroupMemberGrid;