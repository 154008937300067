import React, { Fragment } from 'react';

import useGroupListAdd from './UseGroupListAdd';

import GroupInfoForm from '../../../../../common/components/forms/groups/GroupInfoForm';
import PrimaryButton from '../../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../../common/components/buttons/SecondaryButton';
import Headings from '../../../../../common/components/headings/Headings';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';

import Constants from '../../../../../common/utils/Constants';

import global from '../../../../../common/components/GlobalStyle.module.css';

const GroupListAdd = () => {
  const {
    isLoading,
    formState,
    errorState,
    handleSubmit,
    onFormValueChanged,
    onBackClicked,
    orgUnitId
  } = useGroupListAdd();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Add {formState.groupTypeName} Group</Headings.H3>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <GroupInfoForm
          formState={formState}
          errorState={errorState}
          onFormValueChanged={onFormValueChanged}
          orgUnitId={orgUnitId} />
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
            <PrimaryButton type="submit">Continue</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onBackClicked}>Back</SecondaryButton>
            {errorState && errorState.errorOnSubmitAction
              && <p className={global.ErrorMessage}>{errorState.errorOnSubmitAction}</p>
            }
          </div>
        </div>
      </form>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
    </Fragment>
  );
};

export default GroupListAdd;