import NationalTeamFormValidation from '../../components/forms/NationalTeamAttributesFormValidation';

import GroupInfoFormValidation from '../../../../../common/components/forms/groups/GroupInfoFormValidation';

const GroupNationalTeamAddValidation = async (formState) => {
  let errors = {
    ...GroupInfoFormValidation(formState),
    ...NationalTeamFormValidation(formState)
  };

  return errors ? errors : {};
};

export default GroupNationalTeamAddValidation;