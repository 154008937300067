import { useEffect, useState } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../NavLinks';

import useGroupAddData from '../../../state/groupAdd/UseGroupAddData';

import useGroupData from '../../../../common/state/group/UseGroupData';
import useSecurityData from '../../../../common/state/security/UseSecurityData';
import useSearchGroupData from '../../../../common/state/searchGroup/UseSearchGroupData';
import useSelectOrgUnitData from '../../../../common/state/selectOrgUnit/UseSelectOrgUnitData';
import useOrgGroupTypesData from '../../../../common/state/orgGroupTypes/UseOrgGroupTypesData';
import useGroupFieldNamesData from '../../../../common/state/groupFieldNames/UseGroupFieldNamesData';

const INITIAL_ERROR_MODAL_STATE = {
  displayPopup: false
};

const INITIAL_VIEW_STATE = {
  text: 'Search',
  isItemSelected: false
};

const TAXONOMIES = ['GroupsDetail'];
const SCOPE = 'Group';

const useGroupMyGroups = () => {
  const navigate = useNavigate();
  const { getContextSecurity } = useSecurityData();
  const { groupState, getGroup, resetGroupState } = useGroupData();
  const { clearObjData } = useGroupAddData();
  const { searchGroupState } = useSearchGroupData();
  const { selectOrgUnitState } = useSelectOrgUnitData();
  const { orgGroupTypesState } = useOrgGroupTypesData();
  const { GROUP_CONSTANTS } = useGroupFieldNamesData();
  const [errorModalState, setErrorModalState] = useState(INITIAL_ERROR_MODAL_STATE);
  const [state, setState] = useState(INITIAL_VIEW_STATE);

  const onSelectOrgUnitClicked = () => {
    navigate(NavLinks.GROUP_SELECTION);
  };

  const onAddGroup = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    const targetGroup = orgGroupTypesState.arrayData.find(x => x.typeName === GROUP_CONSTANTS.GROUP_TYPE_NAME_LIST);

    if (targetGroup) {
      navigate(NavLinks.GROUP_ADD_LIST, { state: { groupTypeId: targetGroup.groupTypeId, groupTypeName: targetGroup.typeName } });
    }
  };

  const onEditGroup = (e, group) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    getGroup(group.orgGroupId);
    getContextSecurity(group.orgUnitId, TAXONOMIES, SCOPE);

    setState({ ...state, isItemSelected: true });
  };

  useEffect(() => {
    if (state.isItemSelected === true) {
      if (groupState.isObjLoading !== true && groupState.isObjLoaded === true) {
        if (groupState.objData.orgGroupId > 0) {
          navigate(NavLinks.GROUP_INFO);
        } else {
          setState(INITIAL_VIEW_STATE);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, groupState]);

  useEffect(() => {
    if (!selectOrgUnitState.orgUnitId) {
      setErrorModalState({
        ...errorModalState,
        displayPopup: true
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectOrgUnitState.orgUnitId]);

  useEffect(() => {
    resetGroupState();
    clearObjData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading: orgGroupTypesState.isArrayLoading,
    searchGroupState,
    selectOrgUnitState,
    errorModalState,
    onAddGroup,
    onEditGroup,
    onSelectOrgUnitClicked
  };
};

export default useGroupMyGroups;