import SAAFormValidation from '../../components/forms/SAAAttributesFormValidation';

import GroupInfoFormValidation from '../../../../../common/components/forms/groups/GroupInfoFormValidation';

const GroupSAAAddValidation = async (formState) => {
  let errors = {
    ...GroupInfoFormValidation(formState),
    ...SAAFormValidation(formState)
  };

  return errors ? errors : {};
};

export default GroupSAAAddValidation;