import React, { createContext, useState } from 'react';

import GroupData from './GroupData';

export const GroupStateContext = createContext();

const GroupContextProvider = ({ children }) => {
  const stateHook = useState(GroupData.INITIAL_STATE);

  return (
    <GroupStateContext.Provider value={stateHook}>
      {children}
    </GroupStateContext.Provider>
  );
};

export default GroupContextProvider;