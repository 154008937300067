import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "../../../../../common/wrappers/ReactRouterDom";

import NavLinks from "../../NavLinks";

import useGroupData from "../../../../../common/state/group/UseGroupData";
import useGroupFieldNamesData from "../../../../../common/state/groupFieldNames/UseGroupFieldNamesData";
import useMemberGroupsData from "../../../../../common/state/memberGroups/UseMemberGroupsData";
import useOrgGroupPropertiesData from "../../../../../common/state/orgGroupProperties/UseOrgGroupPropertiesData";

import Constants from "../../../../../common/utils/Constants";

const INITIAL_STATE = {
  personOrgGroupId: Constants.DEFAULT_ID,
  firstName: '',
  lastName: ''
};

const useGroupMemberEdit = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { orgGroupPropertiesState } = useOrgGroupPropertiesData();
  const { groupState, putGroup, confirmSave } = useGroupData();
  const { memberGroupsState, memberGroupsQualificationEventState, getPersonOrgGroup, getGroupQualificationEvents
  } = useMemberGroupsData();
  const { GROUP_CONSTANTS } = useGroupFieldNamesData();
  const [state, setState] = useState(INITIAL_STATE);

  const onBackClicked = () => {
    navigate(NavLinks.GROUP_MEMBERS);
  };

  const onFormCompletion = (groupMemberObj) => {
    const newGroupObj = JSON.parse(JSON.stringify(groupState.objData));
    const targetIndex = newGroupObj.personOrgGroup.findIndex(x => x.personOrgGroupId === state.personOrgGroupId);

    if (targetIndex >= 0) {
      newGroupObj.personOrgGroup.splice(targetIndex, 1, groupMemberObj);

      putGroup(newGroupObj.orgGroupId, newGroupObj);
    }
  };

  useEffect(() => {
    if (groupState.isSaved === true) {
      confirmSave();

      navigate(NavLinks.GROUP_MEMBERS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupState]);

  useEffect(() => {
    if (location.state?.member?.personOrgGroupId > 0) {
      const member = location.state.member;

      getPersonOrgGroup(member.personOrgGroupId);

      setState({
        ...state,
        personOrgGroupId: member.personOrgGroupId,
        firstName: member.firstName,
        lastName: member.lastName
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state]);

  useEffect(() => {
    if (memberGroupsState.isObjLoaded === true) {
      const memberGroupObj = memberGroupsState.objData;
      const personOrgGroupQualification = Array.isArray(memberGroupObj.personOrgGroupQualification) && memberGroupObj.personOrgGroupQualification.length > 0
        ? memberGroupObj.personOrgGroupQualification[0]
        : undefined;

      if (personOrgGroupQualification) {
        getGroupQualificationEvents(personOrgGroupQualification.personOrgGroupQualificationId, true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberGroupsState.isObjLoaded]);

  return {
    GROUP_CONSTANTS,
    isSaving: groupState.isSaving,
    isLoading: orgGroupPropertiesState.isArrayLoading || memberGroupsState.isObjLoading,
    isMemberGroupObjLoaded: memberGroupsState.isObjLoaded,
    isGridLoading: memberGroupsQualificationEventState.isArrayLoading,
    groupObj: groupState.objData || {},
    memberGroupObj: memberGroupsState.objData || {},
    groupQualificationEvents: memberGroupsQualificationEventState.arrayData || [],
    personId: memberGroupsState.objData?.personId || '',
    state,
    onBackClicked,
    onFormCompletion
  };
};

export default useGroupMemberEdit;