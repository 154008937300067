import { Fragment } from "react";

import InviteGroupMemberGridLarge from "./InviteGroupMemberGridLarge";
import InviteGroupMemberGridSmall from "./InviteGroupMemberGridSmall";

const InviteGroupMemberGrid = ({ data, selectedPersons, isLoading, onCheckboxClicked }) => (
  <Fragment>
    <InviteGroupMemberGridLarge data={data} selectedPersons={selectedPersons} isLoading={isLoading} onCheckboxClicked={onCheckboxClicked} />
    <InviteGroupMemberGridSmall data={data} selectedPersons={selectedPersons} isLoading={isLoading} onCheckboxClicked={onCheckboxClicked} />
  </Fragment>
);

export default InviteGroupMemberGrid;