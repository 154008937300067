import CampFormValidation from '../../components/forms/CampAttributesFormValidation';

import GroupInfoFormValidation from '../../../../../common/components/forms/groups/GroupInfoFormValidation';

const GroupCampAddValidation = async (formState) => {
  let errors = {
    ...GroupInfoFormValidation(formState),
    ...CampFormValidation(formState)
  };

  return errors ? errors : {};
};

export default GroupCampAddValidation;