import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from '../../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../../NavLinks';

import { createGroupObjFromGroupInfoForm, createOrgGroupFieldValues } from '../../utils/GroupUtils';

import validate from './GroupSAAAddValidation';

import useGroupAddData from '../../../../state/groupAdd/UseGroupAddData';
import useTimeStandardComboboxData from '../../../../state/timeStandardCombobox/UseTimeStandardComboboxData';

import useGroupCategoryData from '../../../../../common/state/groupCategory/UseGroupCategoryData';
import useEnvironmentVariableData from '../../../../../common/state/environmentVariable/UseEnvironmentVariableData';
import useOrgGroupPropertiesData from '../../../../../common/state/orgGroupProperties/UseOrgGroupPropertiesData';
import useSelectOrgUnitData from '../../../../../common/state/selectOrgUnit/UseSelectOrgUnitData';
import useGroupFieldNamesData from '../../../../../common/state/groupFieldNames/UseGroupFieldNamesData';

import useForm from '../../../../../common/utils/UseForm';
import Constants from '../../../../../common/utils/Constants';
import HierarchicalDataUtils from '../../../../../common/utils/HierarchicalDataUtils';

const useGroupSAAAdd = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { environmentVariableState, TIME_STANDARD_TYPE_ID_SAA } = useEnvironmentVariableData();
  const { GROUP_CONSTANTS } = useGroupFieldNamesData();
  const { selectOrgUnitState } = useSelectOrgUnitData();
  const { timeStandardComboboxState, getTimeStandards } = useTimeStandardComboboxData();
  const { groupAddState, updateGroupAddObj } = useGroupAddData();
  const { groupCategoryState } = useGroupCategoryData();
  const { orgGroupPropertiesState, getOrgGroupProperties } = useOrgGroupPropertiesData();
  const { formState, errorState, onFormValueChanged, handleSubmit, setFormState, setIsDirty, onValueTextPairChanged
  } = useForm(getInitialFormState, submitFormCallback, validate);
  const [state, setState] = useState(getInitialState);

  const onBackClicked = () => {
    navigate(NavLinks.GROUP_ADD_SELECTION);
  };

  useEffect(() => {
    if (groupCategoryState.isArrayLoaded === true && groupCategoryState.id === selectOrgUnitState.orgUnitId
      && orgGroupPropertiesState.isArrayLoaded === true && orgGroupPropertiesState.groupTypeId === state.groupTypeId
      && timeStandardComboboxState.isArrayLoaded === true) {
      // On entry from group select type
      if (location.state?.groupTypeId && location.state?.groupTypeName && selectOrgUnitState.orgUnitId) {
        setFormState({
          ...formState,
          groupTypeId: location.state.groupTypeId,
          groupTypeName: location.state.groupTypeName,
          orgUnitId: selectOrgUnitState.orgUnitId
        });

        setIsDirty(true);
      } else if (groupAddState.isObjLoaded === true) { // On entry from a back button 
        const groupObj = groupAddState.objData;

        const timeStandardId = groupObj.orgGroupFieldValue.find(x => x.groupTypeField?.fieldName === GROUP_CONSTANTS.ORG_GROUP_TYPE_FIELD_NAME_TIME_STANDARD)?.fieldValue || Constants.DEFAULT_ID;
        const timeStandardP1Id = groupObj.orgGroupFieldValue.find(x => x.groupTypeField?.fieldName === GROUP_CONSTANTS.ORG_GROUP_TYPE_FIELD_NAME_TIME_STANDARD_P1)?.fieldValue || Constants.DEFAULT_ID;
        const timeStandardP2Id = groupObj.orgGroupFieldValue.find(x => x.groupTypeField?.fieldName === GROUP_CONSTANTS.ORG_GROUP_TYPE_FIELD_NAME_TIME_STANDARD_P2)?.fieldValue || Constants.DEFAULT_ID;
        const timeStandardP3Id = groupObj.orgGroupFieldValue.find(x => x.groupTypeField?.fieldName === GROUP_CONSTANTS.ORG_GROUP_TYPE_FIELD_NAME_TIME_STANDARD_P3)?.fieldValue || Constants.DEFAULT_ID;

        const timeStandardName = timeStandardId > 0 ? timeStandardComboboxState.items.find(x => x.id === timeStandardId)?.name : '';
        const timeStandardP1Name = timeStandardP1Id > 0 ? timeStandardComboboxState.items.find(x => x.id === timeStandardP1Id)?.name : '';
        const timeStandardP2Name = timeStandardP2Id > 0 ? timeStandardComboboxState.items.find(x => x.id === timeStandardP2Id)?.name : '';
        const timeStandardP3Name = timeStandardP3Id > 0 ? timeStandardComboboxState.items.find(x => x.id === timeStandardP3Id)?.name : '';

        setFormState({
          ...formState,
          orgUnitId: groupObj.orgUnitId,
          groupTypeId: groupObj.groupTypeId,
          groupTypeName: groupAddState.groupTypeName,
          groupCategories: HierarchicalDataUtils.GetNameIdPairs(groupCategoryState.treeData, [groupObj.groupCategoryId]),
          groupName: groupObj.groupName,
          groupCode: groupObj.groupCode,
          groupDescription: groupObj.groupDescription,
          groupYear: groupObj.orgGroupFieldValue.find(x => x.groupTypeField?.fieldName === GROUP_CONSTANTS.ORG_GROUP_TYPE_FIELD_NAME_YEAR)?.fieldValue,
          qualifyingStartDate: groupObj.orgGroupFieldValue.find(x => x.groupTypeField?.fieldName === GROUP_CONSTANTS.ORG_GROUP_TYPE_FIELD_NAME_QUALIFYING_START_DATE)?.fieldValue,
          qualifyingEndDate: groupObj.orgGroupFieldValue.find(x => x.groupTypeField?.fieldName === GROUP_CONSTANTS.ORG_GROUP_TYPE_FIELD_NAME_QUALIFYING_END_DATE)?.fieldValue,
          timeStandardId,
          timeStandardName,
          timeStandardP1Id,
          timeStandardP1Name,
          timeStandardP2Id,
          timeStandardP2Name,
          timeStandardP3Id,
          timeStandardP3Name
        });

        setIsDirty(true);
      } else {
        navigate(NavLinks.GROUP_ADD_SELECTION);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupCategoryState, orgGroupPropertiesState, state, timeStandardComboboxState]);

  useEffect(() => {
    if (environmentVariableState.isLoaded === true && selectOrgUnitState.orgUnitId) {
      getTimeStandards(selectOrgUnitState.orgUnitId, TIME_STANDARD_TYPE_ID_SAA);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [environmentVariableState]);

  useEffect(() => {
    if (location.state?.groupTypeId) { // On entry from group select type
      setState({ ...state, groupTypeId: location.state.groupTypeId });

      if (orgGroupPropertiesState.groupTypeId !== location.state.groupTypeId) {
        getOrgGroupProperties(location.state.groupTypeId);
      }
    } else if (groupAddState.isObjLoaded === true && groupAddState.objData.groupTypeId) { // On entry from a back button 
      setState({ ...state, groupTypeId: groupAddState.objData.groupTypeId });

      if (orgGroupPropertiesState.groupTypeId !== groupAddState.objData.groupTypeId) {
        getOrgGroupProperties(groupAddState.objData.groupTypeId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (state.tryRedirect === true) { // After continue button clicked
      navigate(NavLinks.GROUP_ADD_REGISTRATION);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.tryRedirect]);

  function submitFormCallback(formState) {
    const newOrgGroupObj = createGroupObjFromGroupInfoForm(groupAddState.objData, formState);

    newOrgGroupObj.orgGroupFieldValue = createOrgGroupFieldValues([
      { fieldName: GROUP_CONSTANTS.ORG_GROUP_TYPE_FIELD_NAME_YEAR, fieldValue: formState.groupYear },
      { fieldName: GROUP_CONSTANTS.ORG_GROUP_TYPE_FIELD_NAME_QUALIFYING_START_DATE, fieldValue: formState.qualifyingStartDate },
      { fieldName: GROUP_CONSTANTS.ORG_GROUP_TYPE_FIELD_NAME_QUALIFYING_END_DATE, fieldValue: formState.qualifyingEndDate },
      { fieldName: GROUP_CONSTANTS.ORG_GROUP_TYPE_FIELD_NAME_TIME_STANDARD, fieldValue: formState.timeStandardId },
      { fieldName: GROUP_CONSTANTS.ORG_GROUP_TYPE_FIELD_NAME_TIME_STANDARD_P1, fieldValue: formState.timeStandardP1Id },
      { fieldName: GROUP_CONSTANTS.ORG_GROUP_TYPE_FIELD_NAME_TIME_STANDARD_P2, fieldValue: formState.timeStandardP2Id },
      { fieldName: GROUP_CONSTANTS.ORG_GROUP_TYPE_FIELD_NAME_TIME_STANDARD_P3, fieldValue: formState.timeStandardP3Id }
    ], orgGroupPropertiesState.arrayData);

    updateGroupAddObj(newOrgGroupObj, formState.groupTypeName);

    setState({ ...state, tryRedirect: true });
  };

  function getInitialFormState() {
    return {
      orgUnitId: '',
      groupTypeId: Constants.DEFAULT_ID,
      groupTypeName: '',
      groupCategories: [],
      groupName: '',
      groupCode: '',
      groupDescription: '',
      groupYear: 'Year',
      qualifyingStartDate: Constants.BLANK_DATE_STRING,
      qualifyingEndDate: Constants.BLANK_DATE_STRING,
      timeStandardId: Constants.DEFAULT_ID,
      timeStandardName: '',
      timeStandardP1Id: Constants.DEFAULT_ID,
      timeStandardP1Name: '',
      timeStandardP2Id: Constants.DEFAULT_ID,
      timeStandardP2Name: '',
      timeStandardP3Id: Constants.DEFAULT_ID,
      timeStandardP3Name: ''
    };
  };

  function getInitialState() {
    return {
      tryRedirect: false,
      groupTypeId: Constants.DEFAULT_ID
    };
  };

  return {
    isLoading: groupCategoryState.isArrayLoading || environmentVariableState.isLoading || orgGroupPropertiesState.isArrayLoading,
    isComboboxLoading: timeStandardComboboxState.isArrayLoading,
    comboboxItems: timeStandardComboboxState.items,
    formState,
    errorState,
    handleSubmit,
    onFormValueChanged,
    onBackClicked,
    onValueTextPairChanged,
    orgUnitId: selectOrgUnitState.orgUnitId
  };
};

export default useGroupSAAAdd;