import validate from './AddGroupMemberFormValidation';

import useForm from "../../../../../common/utils/UseForm";

const INITIAL_FORM_STATE = {
  memberId: '',
  matchedMember: {}
};

const useAddGroupMemberForm = (onSubmitFormCallback) => {
  const { formState, errorState, isSubmitting, setFormData, updateFormState, handleSubmit
  } = useForm(INITIAL_FORM_STATE, onSubmitFormCallback, validate);

  return {
    isLoading: isSubmitting,
    formState,
    errorState,
    onFormValueChanged: updateFormState,
    handleSubmit,
    setFormData
  };
};

export default useAddGroupMemberForm;