import { useEffect, useState } from 'react';

import validate from './SearchGroupValidation';

import useGroupData from '../../../state/group/UseGroupData';
import useSearchGroupData from '../../../state/searchGroup/UseSearchGroupData';
import useOrgGroupCategoriesData from '../../../state/orgGroupCategories/UseOrgGroupCategoriesData';
import useOrgUnitData from '../../../state/orgUnit/UseOrgUnitData';

import useForm from '../../../utils/UseForm';
import HierarchicalDataUtils from '../../../utils/HierarchicalDataUtils';

const useSearchGroup = () => {
  const { groupState, searchGroups, groupFiltersState } = useGroupData();
  const { searchGroupState, setSearchGroupState } = useSearchGroupData();
  const { orgGroupCategoriesState } = useOrgGroupCategoriesData();
  const { orgUnitState } = useOrgUnitData();
  const { formState, errorState, handleSubmit, updateFormState, setFormState, setErrors,
    setIsDirty, isDirty } = useForm(getInitialFormState(), submitFormCallback, validate);
  const [state, setState] = useState(getInitialState);

  const clearForm = () => {
    setFormState(getInitialFormState());
    setErrors({});
  };

  const onFiltersToggle = () => {
    setSearchGroupState({ ...searchGroupState, showFilters: !searchGroupState.showFilters });
    setIsDirty(true);
  }

  useEffect(() => {
    if (Object.keys(groupFiltersState.filterObject).length > 0 && orgUnitState.isArrayLoaded === true
      && orgGroupCategoriesState.isArrayLoaded === true) {
      const oldSearchValues = JSON.parse(JSON.stringify(groupFiltersState.filterObject));

      setFormState({
        ...formState,
        ...oldSearchValues,
        orgUnit: HierarchicalDataUtils.GetNameIdPairs(orgUnitState.treeData, [oldSearchValues.orgUnitId]) || [],
        groupCategories: HierarchicalDataUtils.GetNameIdPairs(orgGroupCategoriesState.treeData, oldSearchValues.groupCategoryId) || [],
      });
      setIsDirty(true);

      if (groupState.isArrayLoading === false && state.isInitialLoad === true) {
        searchGroups(groupFiltersState.filterObject);

        setState({
          ...state,
          isInitialLoad: false
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgGroupCategoriesState, orgUnitState]);

  useEffect(() => {
    if (searchGroupState.showFilters === true && isDirty === false) {
      setIsDirty(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchGroupState.showFilters, isDirty]);

  function submitFormCallback(formState) {
    setSearchGroupState({ ...searchGroupState, tryRedirect: false });

    if (groupState.isArrayLoading === false) {
      const filterObject = createSearchFilterObj(formState);
      searchGroups(filterObject);
    }
  };

  function createSearchFilterObj(formState) {
    return {
      orgUnitId: formState.orgUnit.length > 0 ? formState.orgUnit[0].id : undefined,
      groupCategoryId: formState.groupCategories.length > 0 ? formState.groupCategories.map(x => x.id) : undefined,
      groupName: formState.groupName ? formState.groupName.trim() : undefined,
      groupCode: formState.groupCode ? formState.groupCode.trim() : undefined
    }
  }

  function getInitialFormState() {
    return {
      orgUnit: [],
      groupCategories: [],
      groupName: '',
      groupCode: '',
      groupType: ''
    }
  };

  function getInitialState() {
    return {
      isInitialLoad: true
    };
  };

  return {
    formState,
    errorState,
    groupState,
    searchGroupState,
    setSearchGroupState,
    orgUnitState,
    orgGroupCategoriesState,
    clearForm,
    onFormValueChanged: updateFormState,
    handleSubmit,
    setIsDirty,
    onFiltersToggle
  };
};

export default useSearchGroup;