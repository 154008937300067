import { useEffect } from 'react';

import { createOrgGroupFieldValues } from '../../utils/GroupUtils';

import validate from '../../components/forms/SAAAttributesFormValidation';

import useTimeStandardComboboxData from '../../../../state/timeStandardCombobox/UseTimeStandardComboboxData';

import useEnvironmentVariableData from '../../../../../common/state/environmentVariable/UseEnvironmentVariableData';
import useOrgGroupPropertiesData from '../../../../../common/state/orgGroupProperties/UseOrgGroupPropertiesData';
import useGroupData from '../../../../../common/state/group/UseGroupData';
import useGroupFieldNamesData from '../../../../../common/state/groupFieldNames/UseGroupFieldNamesData';

import useForm from '../../../../../common/utils/UseForm';
import Constants from '../../../../../common/utils/Constants';
import ToIntIfInt from '../../../../../common/utils/ToIntIfInt';

const useGroupSAAAttributes = () => {
  const { environmentVariableState, TIME_STANDARD_TYPE_ID_SAA } = useEnvironmentVariableData();
  const { GROUP_CONSTANTS } = useGroupFieldNamesData();
  const { groupState, putGroup } = useGroupData();
  const { orgGroupPropertiesState, getOrgGroupProperties } = useOrgGroupPropertiesData();
  const { timeStandardComboboxState, getTimeStandards } = useTimeStandardComboboxData();
  const { formState, errorState, onFormValueChanged, handleSubmit, setFormData, resetForm, onValueTextPairChanged
  } = useForm(getInitialFormState, submitFormCallback, validate);

  useEffect(() => {
    if (groupState.isObjLoaded === true && timeStandardComboboxState.isArrayLoaded === true) {
      if (orgGroupPropertiesState.isArrayLoaded === true &&
        orgGroupPropertiesState.groupTypeId === groupState.objData.groupTypeId) {
        const groupObj = groupState.objData;

        const timeStandardId = groupObj.orgGroupFieldValue.find(x => x.groupTypeField?.fieldName === GROUP_CONSTANTS.ORG_GROUP_TYPE_FIELD_NAME_TIME_STANDARD)?.fieldValue || Constants.DEFAULT_ID;
        const timeStandardP1Id = groupObj.orgGroupFieldValue.find(x => x.groupTypeField?.fieldName === GROUP_CONSTANTS.ORG_GROUP_TYPE_FIELD_NAME_TIME_STANDARD_P1)?.fieldValue || Constants.DEFAULT_ID;
        const timeStandardP2Id = groupObj.orgGroupFieldValue.find(x => x.groupTypeField?.fieldName === GROUP_CONSTANTS.ORG_GROUP_TYPE_FIELD_NAME_TIME_STANDARD_P2)?.fieldValue || Constants.DEFAULT_ID;
        const timeStandardP3Id = groupObj.orgGroupFieldValue.find(x => x.groupTypeField?.fieldName === GROUP_CONSTANTS.ORG_GROUP_TYPE_FIELD_NAME_TIME_STANDARD_P3)?.fieldValue || Constants.DEFAULT_ID;

        const timeStandardName = timeStandardId > 0 ? timeStandardComboboxState.items.find(x => x.id === ToIntIfInt(timeStandardId))?.name : '';
        const timeStandardP1Name = timeStandardP1Id > 0 ? timeStandardComboboxState.items.find(x => x.id === ToIntIfInt(timeStandardP1Id))?.name : '';
        const timeStandardP2Name = timeStandardP2Id > 0 ? timeStandardComboboxState.items.find(x => x.id === ToIntIfInt(timeStandardP2Id))?.name : '';
        const timeStandardP3Name = timeStandardP3Id > 0 ? timeStandardComboboxState.items.find(x => x.id === ToIntIfInt(timeStandardP3Id))?.name : '';

        setFormData({
          ...formState,
          groupYear: groupObj.orgGroupFieldValue.find(x => x.groupTypeField?.fieldName === GROUP_CONSTANTS.ORG_GROUP_TYPE_FIELD_NAME_YEAR)?.fieldValue,
          qualifyingStartDate: groupObj.orgGroupFieldValue.find(x => x.groupTypeField?.fieldName === GROUP_CONSTANTS.ORG_GROUP_TYPE_FIELD_NAME_QUALIFYING_START_DATE)?.fieldValue,
          qualifyingEndDate: groupObj.orgGroupFieldValue.find(x => x.groupTypeField?.fieldName === GROUP_CONSTANTS.ORG_GROUP_TYPE_FIELD_NAME_QUALIFYING_END_DATE)?.fieldValue,
          timeStandardId,
          timeStandardName,
          timeStandardP1Id,
          timeStandardP1Name,
          timeStandardP2Id,
          timeStandardP2Name,
          timeStandardP3Id,
          timeStandardP3Name
        });
      } else if (orgGroupPropertiesState.isArrayLoading === false && groupState.objData.groupTypeId) {
        getOrgGroupProperties(groupState.objData.groupTypeId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgGroupPropertiesState, groupState, timeStandardComboboxState]);

  useEffect(() => {
    if (environmentVariableState.isLoaded === true && groupState.objData.orgUnitId) {
      getTimeStandards(groupState.objData.orgUnitId, TIME_STANDARD_TYPE_ID_SAA);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [environmentVariableState, groupState.objData.orgUnitId]);

  function submitFormCallback(formState) {
    if (groupState.isSaving === false) {
      const newOrgGroupObj = JSON.parse(JSON.stringify(groupState.objData));

      newOrgGroupObj.orgGroupFieldValue = createOrgGroupFieldValues([
        { fieldName: GROUP_CONSTANTS.ORG_GROUP_TYPE_FIELD_NAME_YEAR, fieldValue: formState.groupYear },
        { fieldName: GROUP_CONSTANTS.ORG_GROUP_TYPE_FIELD_NAME_QUALIFYING_START_DATE, fieldValue: formState.qualifyingStartDate },
        { fieldName: GROUP_CONSTANTS.ORG_GROUP_TYPE_FIELD_NAME_QUALIFYING_END_DATE, fieldValue: formState.qualifyingEndDate },
        { fieldName: GROUP_CONSTANTS.ORG_GROUP_TYPE_FIELD_NAME_TIME_STANDARD, fieldValue: formState.timeStandardId },
        { fieldName: GROUP_CONSTANTS.ORG_GROUP_TYPE_FIELD_NAME_TIME_STANDARD_P1, fieldValue: formState.timeStandardP1Id },
        { fieldName: GROUP_CONSTANTS.ORG_GROUP_TYPE_FIELD_NAME_TIME_STANDARD_P2, fieldValue: formState.timeStandardP2Id },
        { fieldName: GROUP_CONSTANTS.ORG_GROUP_TYPE_FIELD_NAME_TIME_STANDARD_P3, fieldValue: formState.timeStandardP3Id }
      ], orgGroupPropertiesState.arrayData, newOrgGroupObj.orgGroupId);

      if (newOrgGroupObj.orgGroupFieldValue.length > 0) {
        for (const orgGroupField of newOrgGroupObj.orgGroupFieldValue) {
          orgGroupField.groupTypeField = undefined;
        }
      }

      putGroup(newOrgGroupObj.orgGroupId, newOrgGroupObj);
    }
  };

  function getInitialFormState() {
    return {
      groupYear: 'Year',
      qualifyingStartDate: Constants.BLANK_DATE_STRING,
      qualifyingEndDate: Constants.BLANK_DATE_STRING,
      timeStandardId: Constants.DEFAULT_ID,
      timeStandardName: '',
      timeStandardP1Id: Constants.DEFAULT_ID,
      timeStandardP1Name: '',
      timeStandardP2Id: Constants.DEFAULT_ID,
      timeStandardP2Name: '',
      timeStandardP3Id: Constants.DEFAULT_ID,
      timeStandardP3Name: ''
    };
  };

  return {
    isSaving: groupState.isSaving,
    isLoading: environmentVariableState.isLoading || orgGroupPropertiesState.isArrayLoading || timeStandardComboboxState.isArrayLoading,
    isComboboxLoading: timeStandardComboboxState.isArrayLoading,
    comboboxItems: timeStandardComboboxState.items,
    formState,
    errorState,
    handleSubmit,
    onFormValueChanged,
    onValueTextPairChanged,
    onCancelClicked: resetForm
  };
};

export default useGroupSAAAttributes;