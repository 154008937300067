import { useEffect } from 'react';

import { createOrgGroupFieldValues } from '../../utils/GroupUtils';

import validate from '../../components/forms/CompetitorListAttributesFormValidation';

import useOrgGroupPropertiesData from '../../../../../common/state/orgGroupProperties/UseOrgGroupPropertiesData';
import useGroupData from '../../../../../common/state/group/UseGroupData';
import useGroupFieldNamesData from '../../../../../common/state/groupFieldNames/UseGroupFieldNamesData';

import useForm from '../../../../../common/utils/UseForm';

const useGroupCompetitorListAttributes = () => {
  const { GROUP_CONSTANTS } = useGroupFieldNamesData();
  const { groupState, putGroup } = useGroupData();
  const { orgGroupPropertiesState, getOrgGroupProperties } = useOrgGroupPropertiesData();
  const { formState, errorState, onFormValueChanged, handleSubmit, setFormData, resetForm
  } = useForm(getInitialFormState, submitFormCallback, validate);

  useEffect(() => {
    if (groupState.isObjLoaded === true) {
      if (orgGroupPropertiesState.isArrayLoaded === true &&
        orgGroupPropertiesState.groupTypeId === groupState.objData.groupTypeId) {
        const groupObj = groupState.objData;

        setFormData({
          ...formState,
          groupYear: groupObj.orgGroupFieldValue.find(x => x.groupTypeField?.fieldName === GROUP_CONSTANTS.ORG_GROUP_TYPE_FIELD_NAME_YEAR)?.fieldValue
        });
      } else if (orgGroupPropertiesState.isArrayLoading === false && groupState.objData.groupTypeId) {
        getOrgGroupProperties(groupState.objData.groupTypeId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgGroupPropertiesState, groupState]);

  function submitFormCallback(formState) {
    if (groupState.isSaving === false) {
      const newOrgGroupObj = JSON.parse(JSON.stringify(groupState.objData));

      newOrgGroupObj.orgGroupFieldValue = createOrgGroupFieldValues([
        { fieldName: GROUP_CONSTANTS.ORG_GROUP_TYPE_FIELD_NAME_YEAR, fieldValue: formState.groupYear }
      ], orgGroupPropertiesState.arrayData, newOrgGroupObj.orgGroupId);

      if (newOrgGroupObj.orgGroupFieldValue.length > 0) {
        for (const orgGroupField of newOrgGroupObj.orgGroupFieldValue) {
          orgGroupField.groupTypeField = undefined;
        }
      }

      putGroup(newOrgGroupObj.orgGroupId, newOrgGroupObj);
    }
  };

  function getInitialFormState() {
    return {
      groupYear: 'Year'
    };
  };

  return {
    isSaving: groupState.isSaving,
    isLoading: orgGroupPropertiesState.isArrayLoading,
    formState,
    errorState,
    handleSubmit,
    onFormValueChanged,
    onCancelClicked: resetForm
  };
};

export default useGroupCompetitorListAttributes;