import { Fragment } from "react";

import CompetitorListGroupMemberGridLarge from "./CompetitorListGroupMemberGridLarge";
import CompetitorListGroupMemberGridSmall from "./CompetitorListGroupMemberGridSmall";

const CompetitorListGroupMemberGrid = ({ data, isLoading, onEditClicked, onDeleteClicked }) => (
  <Fragment>
    <CompetitorListGroupMemberGridLarge data={data} isLoading={isLoading} onEditClicked={onEditClicked} onDeleteClicked={onDeleteClicked} />
    <CompetitorListGroupMemberGridSmall data={data} isLoading={isLoading} onEditClicked={onEditClicked} onDeleteClicked={onDeleteClicked} />
  </Fragment>
);

export default CompetitorListGroupMemberGrid;