import { Fragment } from 'react';

import useSearchGroupPopup from './UseSearchGroupPopup';

import SearchGroupPopupGrid from "./SearchGroupPopupGrid";

import Input from "../../../../../common/components/inputs/Input";
import LookupIcon from "../../../../../common/components/icons/LookupIcon";
import PopUpModal from "../../../../../common/components/dialogs/PopUpModal";
import PrimaryButton from "../../../../../common/components/buttons/PrimaryButton";
import SecondaryButton from "../../../../../common/components/buttons/SecondaryButton";

const SearchGroupPopup = ({ label = "Group Id", orgGroupIdKey = "orgGroupId", viewFormState, viewErrorState,
  setViewFormData, onViewFormValueChanged }) => {
  const {
    state,
    formState,
    errorState,
    groupLookupState,
    onFormValueChanged,
    handleSubmit,
    onDisplayPopupClicked,
    onHidePopupClicked,
    onSelectGroupClicked
  } = useSearchGroupPopup(viewFormState, setViewFormData, orgGroupIdKey);

  return (
    <Fragment>
      <div className="usas-extra-bottom-margin">
        <Input
          label={label}
          name={orgGroupIdKey}
          value={viewFormState[orgGroupIdKey]}
          error={viewErrorState[orgGroupIdKey]}
          message={viewErrorState[orgGroupIdKey]}
          onChange={(value) => { onViewFormValueChanged(orgGroupIdKey, value); }} />
        <a onClick={onDisplayPopupClicked}><LookupIcon /></a>
      </div>
      <PopUpModal
        widthPct={90}
        maxWidth={400}
        title={'Group Id Lookup'}
        displayPopUp={state.displayPopup}
        onModalCanceled={onHidePopupClicked}>
        <div className="row usas-extra-bottom-margin">
          <div className="col-xs-12">
            <SearchGroupPopupGrid
              data={groupLookupState.arrayData}
              isLoading={groupLookupState.isArrayLoading}
              onSelectClicked={onSelectGroupClicked} />
          </div>
        </div>
        <div className="row usas-extra-top-margin">
          <div className="col-xs-12">
            <Input
              label="Group Code*"
              name="groupCode"
              value={formState.groupCode}
              error={errorState.groupCode}
              message={errorState.groupCode}
              onChange={(value) => { onFormValueChanged('groupCode', value) }} />
          </div>
        </div>
        <div className="row usas-extra-top-margin">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={handleSubmit}>Search</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onHidePopupClicked}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
    </Fragment>
  );
};

export default SearchGroupPopup;