import { Fragment } from "react";

import SAAGroupMemberGridLarge from "./SAAGroupMemberGridLarge";
import SAAGroupMemberGridSmall from "./SAAGroupMemberGridSmall";

const SAAGroupMemberGrid = ({ data, isLoading, onEditClicked, onDeleteClicked }) => (
  <Fragment>
    <SAAGroupMemberGridLarge data={data} isLoading={isLoading} onEditClicked={onEditClicked} onDeleteClicked={onDeleteClicked} />
    <SAAGroupMemberGridSmall data={data} isLoading={isLoading} onEditClicked={onEditClicked} onDeleteClicked={onDeleteClicked} />
  </Fragment>
);

export default SAAGroupMemberGrid;