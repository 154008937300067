import { useEffect } from 'react';

import useOrgGroupCategoriesData from '../../../state/orgGroupCategories/UseOrgGroupCategoriesData';

const useOrgGroupCategoriesTreeView = (groupCategoryLevel) => {
  const { orgGroupCategoriesState, getOrgGroupCategoriesH } = useOrgGroupCategoriesData();

  useEffect(() => {
    if (groupCategoryLevel) {
      getOrgGroupCategoriesH(groupCategoryLevel);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupCategoryLevel]);

  return {
    orgGroupCategoriesState
  };
};

export default useOrgGroupCategoriesTreeView;