import Constants from '../../../../../common/utils/Constants';
import { isValidNumber, isValidDate } from '../../../../../common/utils/validation';

const SAAAttributesFormValidation = (formState) => {
  let errors = {};

  const qualifyingStartDate = new Date(formState.qualifyingStartDate);
  const qualifyingEndDate = new Date(formState.qualifyingEndDate);

  if (!isValidNumber(formState.groupYear)) {
    errors.groupYear = 'Year is required';
  }

  if (formState.qualifyingStartDate === Constants.BLANK_DATE_STRING || formState.qualifyingStartDate === '') {
    errors.qualifyingStartDate = 'Qualifying Start Date is required';
  } else if (!isValidDate(formState.qualifyingStartDate)) {
    errors.qualifyingStartDate = 'Qualifying Start Date must be a valid date';
  } else if (qualifyingEndDate < qualifyingStartDate || formState.qualifyingStartDate === formState.qualifyingEndDate) {
    errors.qualifyingStartDate = 'Qualifying Start Date must be before the Qualifying End Date';
  }

  if (formState.qualifyingEndDate === Constants.BLANK_DATE_STRING || formState.qualifyingEndDate === '') {
    errors.qualifyingEndDate = 'Qualifying End Date is required';
  } else if (!isValidDate(formState.qualifyingEndDate)) {
    errors.qualifyingEndDate = 'Qualifying End Date must be a valid date';
  } else if (qualifyingEndDate < qualifyingStartDate || formState.qualifyingStartDate === formState.qualifyingEndDate) {
    errors.qualifyingEndDate = 'Qualifying End Date must be after the Qualifying Start Date';
  }

  if (formState.timeStandardName === '') {
    errors.timeStandardId = 'Time Standard is required';
  } else if (formState.timeStandardId === Constants.DEFAULT_ID || formState.timeStandardId === '') {
    errors.timeStandardId = 'Time Standard must be valid';
  } else if (formState.timeStandardId === formState.timeStandardP1Id) {
    errors.timeStandardId = 'Time Standard cannot be the same as Time Standard P1';
  } else if (formState.timeStandardId === formState.timeStandardP2Id) {
    errors.timeStandardId = 'Time Standard cannot be the same as Time Standard P2';
  } else if (formState.timeStandardId === formState.timeStandardP3Id) {
    errors.timeStandardId = 'Time Standard cannot be the same as Time Standard P3';
  }

  if (formState.timeStandardP1Name === '') {
    errors.timeStandardP1Id = 'Time Standard P1 is required';
  } else if (formState.timeStandardP1Id === Constants.DEFAULT_ID || formState.timeStandardP1Id === '') {
    errors.timeStandardP1Id = 'Time Standard P1 must be valid';
  } else if (formState.timeStandardP1Id === formState.timeStandardId) {
    errors.timeStandardP1Id = 'Time Standard P1 cannot be the same as Time Standard';
  } else if (formState.timeStandardP1Id === formState.timeStandardP2Id) {
    errors.timeStandardP1Id = 'Time Standard P1 cannot be the same as Time Standard P2';
  } else if (formState.timeStandardP1Id === formState.timeStandardP3Id) {
    errors.timeStandardP1Id = 'Time Standard P1 cannot be the same as Time Standard P3';
  }

  if (formState.timeStandardP2Name === '') {
    errors.timeStandardP2Id = 'Time Standard P2 is required';
  } else if (formState.timeStandardP2Id === Constants.DEFAULT_ID || formState.timeStandardP2Id === '') {
    errors.timeStandardP2Id = 'Time Standard P2 must be valid';
  } else if (formState.timeStandardP2Id === formState.timeStandardId) {
    errors.timeStandardP2Id = 'Time Standard P2 cannot be the same as Time Standard';
  } else if (formState.timeStandardP2Id === formState.timeStandardP1Id) {
    errors.timeStandardP2Id = 'Time Standard P2 cannot be the same as Time Standard P1';
  } else if (formState.timeStandardP2Id === formState.timeStandardP3Id) {
    errors.timeStandardP2Id = 'Time Standard P2 cannot be the same as Time Standard P3';
  }

  if (formState.timeStandardP3Name === '') {
    errors.timeStandardP3Id = 'Time Standard P3 is required';
  } else if (formState.timeStandardP3Id === Constants.DEFAULT_ID || formState.timeStandardP3Id === '') {
    errors.timeStandardP3Id = 'Time Standard P3 must be valid';
  } else if (formState.timeStandardP3Id === formState.timeStandardId) {
    errors.timeStandardP3Id = 'Time Standard P3 cannot be the same as Time Standard';
  } else if (formState.timeStandardP3Id === formState.timeStandardP1Id) {
    errors.timeStandardP3Id = 'Time Standard P3 cannot be the same as Time Standard P1';
  } else if (formState.timeStandardP3Id === formState.timeStandardP2Id) {
    errors.timeStandardP3Id = 'Time Standard P3 cannot be the same as Time Standard P2';
  }

  return errors;
};

export default SAAAttributesFormValidation;