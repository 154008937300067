import { Fragment } from "react";

import InviteGroupMemberIMXGridLarge from "./InviteGroupMemberIMXGridLarge";
import InviteGroupMemberIMXGridSmall from "./InviteGroupMemberIMXGridSmall";

const InviteGroupMemberIMXGrid = ({ data, isLoading, onCheckboxClicked }) => (
  <Fragment>
    <InviteGroupMemberIMXGridLarge data={data} isLoading={isLoading} onCheckboxClicked={onCheckboxClicked} />
    <InviteGroupMemberIMXGridSmall data={data} isLoading={isLoading} onCheckboxClicked={onCheckboxClicked} />
  </Fragment>
);

export default InviteGroupMemberIMXGrid;