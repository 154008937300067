import { isValidAlphanumericAndDollarSign } from '../../../../../common/utils/validation';

export const localValidate = (formState) => {
  let errors = {};

  if (!formState.groupCode) {
    errors.groupCode = 'Group Code is required';
  } else if (!isValidAlphanumericAndDollarSign(formState.groupCode)) {
    errors.groupCode = 'Group Code can only contain numbers, letters, and dollar signs';
  }

  return errors;
};

const SearchGroupPopupValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
}

export default SearchGroupPopupValidation;