import { useEffect } from "react";
import { useNavigate } from "../../../../../common/wrappers/ReactRouterDom";

import NavLinks from "../../NavLinks";

import useGroupAlternateMembersData from "../../../../state/groupAlternateMembers/UseGroupAlternateMembersData";

import useGroupData from "../../../../../common/state/group/UseGroupData";

const useGroupInviteAlternateMembersIMX = () => {
  const navigate = useNavigate();
  const { groupState } = useGroupData();
  const { groupAlternateMembersState, getGroupAlternateMembersIMX, putGroupAlternateMembersIMX, updateMemberIMXIsSelected
  } = useGroupAlternateMembersData();

  const onInviteAthletesClicked = () => {
    if (groupAlternateMembersState.arrayData.some(x => x.isSelected === true)) {
      putGroupAlternateMembersIMX(groupState.objData.orgGroupId, groupAlternateMembersState.arrayData);
    }
  };

  const onBackClicked = () => {
    navigate(NavLinks.GROUP_MEMBERS);
  };

  const onCheckboxClicked = (groupQualificationImxId) => {
    if (groupQualificationImxId && groupAlternateMembersState.isArrayLoading !== true) {
      updateMemberIMXIsSelected(groupQualificationImxId);
    }
  };

  useEffect(() => {
    if (groupState.isObjLoaded === true) {
      getGroupAlternateMembersIMX(groupState.objData.orgGroupId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupState]);

  return {
    isSaving: groupAlternateMembersState.isSaving,
    isLoading: groupState.isObjLoading,
    isGridLoading: groupAlternateMembersState.isArrayLoading,
    gridData: groupAlternateMembersState.arrayData,
    onInviteAthletesClicked,
    onBackClicked,
    onCheckboxClicked
  };
};

export default useGroupInviteAlternateMembersIMX;