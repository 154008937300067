import { useEffect, useState } from "react";
import { useNavigate } from "../../../../../common/wrappers/ReactRouterDom";

import NavLinks from "../../NavLinks";

import useGroupData from "../../../../../common/state/group/UseGroupData";
import useGroupFieldNamesData from "../../../../../common/state/groupFieldNames/UseGroupFieldNamesData";

import Constants from "../../../../../common/utils/Constants";

const useGroupNationalTeamMembers = () => {
  const navigate = useNavigate();
  const { GROUP_CONSTANTS } = useGroupFieldNamesData();
  const { groupState, getGroupMembers, putGroup, confirmSave } = useGroupData();
  const [gridState, setGridState] = useState(getInitialGridState);
  const [modalState, setModalState] = useState(getInitialModalState);

  const onDeleteModalConfirmClicked = () => {
    const newGroupObj = JSON.parse(JSON.stringify(groupState.objData));

    newGroupObj.personOrgGroup = newGroupObj.personOrgGroup.filter(x => x.personId !== modalState.personId);

    putGroup(newGroupObj.orgGroupId, newGroupObj);
    setModalState(getInitialModalState);
  };

  const onDeleteModalCancelClicked = () => {
    setModalState(getInitialModalState);
  };

  const onAddMemberToGroupClicked = () => {
    navigate(NavLinks.GROUP_MEMBERS_ADD);
  };

  const onInviteAlternateClicked = () => {
    navigate(NavLinks.GROUP_MEMBERS_INVITE_ALTERNATE);
  };

  const onEditGroupMemberClicked = (member) => {
    navigate(NavLinks.GROUP_MEMBERS_EDIT, { state: { member } });
  };

  const onDeleteGroupMember = (member) => {
    setModalState({
      ...modalState,
      displayModal: true,
      firstName: member.firstName,
      lastName: member.lastName,
      personId: member.personId
    });
  };

  useEffect(() => {
    if (groupState.isObjLoaded === true && gridState.isArrayLoading === false) {
      if (groupState.isSaved === true) {
        confirmSave();

        getGroupMembers(groupState.objData.orgGroupId, gridState, setGridState);
      } else if (gridState.isArrayLoaded === false) {
        getGroupMembers(groupState.objData.orgGroupId, gridState, setGridState);
      } else {
        setGridState({
          ...gridState,
          gridData: getGridData(gridState.arrayData)
        });
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupState, gridState.isArrayLoaded, gridState.isArrayLoading]);

  function getGridData(arrayData) {
    const newGridData = [];

    for (const member of arrayData) {
      newGridData.push({
        personId: member.personId,
        firstName: member.firstName,
        lastName: member.lastName,
        eventName: member.eventName,
        personOrgGroupId: member.personOrgGroupId,
        groupAttendeeType: Array.isArray(member.personGroupTypeField)
          ? member.personGroupTypeField.find(x => x.fieldName === GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_ATTENDEE_TYPE)?.fieldValue
          : '',
        groupRoleType: Array.isArray(member.personGroupTypeField)
          ? member.personGroupTypeField.find(x => x.fieldName === GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_ROLE_TYPE)?.fieldValue
          : '',
        potentialOrConfirmed: Array.isArray(member.personGroupTypeField)
          ? member.personGroupTypeField.find(x => x.fieldName === GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_POTENTIAL_CONFIRMED)?.fieldValue
          : ''
      });
    }

    return newGridData;
  };

  function getInitialGridState() {
    return {
      arrayData: [],
      isArrayLoading: false,
      isArrayLoaded: false,
      gridData: []
    };
  };

  function getInitialModalState() {
    return {
      displayModal: false,
      firstName: '',
      lastName: '',
      personId: Constants.DEFAULT_ID
    };
  };

  return {
    isLoading: gridState.isArrayLoading || groupState.isObjLoading,
    isSaving: groupState.isSaving,
    gridState,
    modalState,
    onAddMemberToGroupClicked,
    onDeleteGroupMember,
    onDeleteModalConfirmClicked,
    onDeleteModalCancelClicked,
    onInviteAlternateClicked,
    onEditGroupMemberClicked
  };
};

export default useGroupNationalTeamMembers;