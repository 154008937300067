import React, { Fragment } from 'react';

import useGroupMemberAdd from './UseGroupMemberAdd';

import AddGroupMemberForm from '../../components/forms/AddGroupMemberForm';
import ListRegistrationForm from '../../components/forms/ListRegistrationForm';

import Headings from '../../../../../common/components/headings/Headings';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';
import SecondaryButton from '../../../../../common/components/buttons/SecondaryButton';
import CompetitorListRegistrationForm from '../../../../../common/components/forms/groups/CompetitorListRegistrationForm';
import CampRegistrationForm from '../../../../../common/components/forms/groups/CampRegistrationForm';
import SAARegistrationForm from '../../../../../common/components/forms/groups/SAARegistrationForm';
import NationalTeamRegistrationForm from '../../../../../common/components/forms/groups/NationalTeamRegistrationForm';
import ErrorMessage from '../../../../../common/components/errors/ErrorMessage';

import Constants from '../../../../../common/utils/Constants';

const GroupRegistrationForm = ({ GROUP_CONSTANTS, groupTypeName, personId, groupObj, memberGroupObj,
  onBackClicked, onFormCompletion }) => {
  switch (groupTypeName) {
    case GROUP_CONSTANTS.GROUP_TYPE_NAME_LIST:
      return <ListRegistrationForm personId={personId} personOrgGroupId={groupObj.personOrgGroupId}
        personOrgGroupQualificationStatus={groupObj.status} groupObj={groupObj} memberGroupObj={memberGroupObj}
        onSecondaryButtonClicked={onBackClicked} onFormCompletion={onFormCompletion} />;
    case GROUP_CONSTANTS.GROUP_TYPE_NAME_COMPETITOR_LIST:
      return <CompetitorListRegistrationForm personId={personId} personOrgGroupId={groupObj.personOrgGroupId}
        personOrgGroupQualificationStatus={groupObj.status} groupObj={groupObj} memberGroupObj={memberGroupObj}
        disableValidation={true} onSecondaryButtonClicked={onBackClicked} onFormCompletion={onFormCompletion} />;
    case GROUP_CONSTANTS.GROUP_TYPE_NAME_CAMP:
      return <CampRegistrationForm personId={personId} personOrgGroupId={groupObj.personOrgGroupId}
        personOrgGroupQualificationStatus={groupObj.status} groupObj={groupObj} memberGroupObj={memberGroupObj}
        disableValidation={true} onSecondaryButtonClicked={onBackClicked} onFormCompletion={onFormCompletion} />;
    case GROUP_CONSTANTS.GROUP_TYPE_NAME_SAA:
      return <SAARegistrationForm personId={personId} personOrgGroupId={groupObj.personOrgGroupId}
        personOrgGroupQualificationStatus={groupObj.status} groupObj={groupObj} memberGroupObj={memberGroupObj}
        disableValidation={true} onSecondaryButtonClicked={onBackClicked} onFormCompletion={onFormCompletion} />;
    case GROUP_CONSTANTS.GROUP_TYPE_NAME_NATIONAL_TEAM:
      return <NationalTeamRegistrationForm personId={personId} personOrgGroupId={groupObj.personOrgGroupId}
        personOrgGroupQualificationStatus={groupObj.status} groupObj={groupObj} memberGroupObj={memberGroupObj}
        disableValidation={true} onSecondaryButtonClicked={onBackClicked} onFormCompletion={onFormCompletion} />;
    default:
      return <ErrorMessage message={'An error occurred'} />
  }
};

const GroupMemberAdd = () => {
  const {
    GROUP_CONSTANTS,
    isSaving,
    state,
    errorModalState,
    groupObj,
    memberGroupObj,
    onAddMemberSubmitFormCallback,
    onFormCompletion,
    onBackClicked,
  } = useGroupMemberAdd();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Add Group Member</Headings.H3>
        </div>
      </div>
      {state.isMemberLoaded !== true
        ? (
          <AddGroupMemberForm
            onSubmitFormCallback={onAddMemberSubmitFormCallback}
            onSecondaryButtonClicked={onBackClicked} />
        ) : (
          <GroupRegistrationForm
            GROUP_CONSTANTS={GROUP_CONSTANTS}
            groupTypeName={groupObj.groupType?.typeName}
            personId={state.personId}
            groupObj={groupObj}
            memberGroupObj={memberGroupObj}
            onFormCompletion={onFormCompletion}
            onBackClicked={onBackClicked} />
        )
      }
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={isSaving} />
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={'Duplicate Member Error'}
        displayPopUp={errorModalState.displayModal}
        onModalCanceled={onBackClicked}>
        <div className="row">
          <div className="col-xs-12">
            <p>{errorModalState.firstName || ''} {errorModalState.lastName || ''} is already a member of this group</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <SecondaryButton type="button" onClick={onBackClicked}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
    </Fragment>
  );
};

export default GroupMemberAdd;