import { useEffect } from 'react';

import { createOrgGroupFieldValues } from '../../utils/GroupUtils';

import validate from '../../../../../common/components/forms/groups/ClubSiteAttributesFormValidation';

import useOrgGroupPropertiesData from '../../../../../common/state/orgGroupProperties/UseOrgGroupPropertiesData';
import useGroupData from '../../../../../common/state/group/UseGroupData';
import useGroupCoachesData from '../../../../../common/state/groupCoaches/UseGroupCoachesData';
import useGroupFacilitiesData from '../../../../../common/state/groupFacilities/UseGroupFacilitiesData';
import useGroupFieldNamesData from '../../../../../common/state/groupFieldNames/UseGroupFieldNamesData';

import useForm from '../../../../../common/utils/UseForm';
import Constants from '../../../../../common/utils/Constants';

const useGroupClubSiteAttributes = () => {
  const { GROUP_CONSTANTS } = useGroupFieldNamesData();
  const { groupState, putGroup } = useGroupData();
  const { orgGroupPropertiesState, getOrgGroupProperties } = useOrgGroupPropertiesData();
  const { groupCoachesState } = useGroupCoachesData();
  const { groupFacilitiesState } = useGroupFacilitiesData();
  const { formState, errorState, onFormValueChanged, handleSubmit, setFormData, resetForm, onValueTextPairChanged
  } = useForm(getInitialFormState, submitFormCallback, validate);

  useEffect(() => {
    if (groupState.isObjLoaded === true
      && orgGroupPropertiesState.isArrayLoaded === true && orgGroupPropertiesState.groupTypeId === groupState.objData.groupTypeId
      && groupCoachesState.isArrayLoaded === true && groupCoachesState.orgUnitId === groupState.objData.orgUnitId
      && groupFacilitiesState.isArrayLoaded === true && groupFacilitiesState.orgUnitId === groupState.objData.orgUnitId) {
      const groupObj = groupState.objData;

      const facilityFieldValue = groupObj.orgGroupFieldValue.find(x => x.groupTypeField?.fieldName === GROUP_CONSTANTS.ORG_GROUP_TYPE_FIELD_NAME_FACILITY)?.fieldValue;
      const targetFacility = groupFacilitiesState.options.find(x => `${x.id}` === facilityFieldValue);

      const leadCoachFieldValue = groupObj.orgGroupFieldValue.find(x => x.groupTypeField?.fieldName === GROUP_CONSTANTS.ORG_GROUP_TYPE_FIELD_NAME_LEAD_COACH)?.fieldValue;
      const targetCoach = groupCoachesState.dataAsOptionsWithNames.find(x => `${x.id}` === leadCoachFieldValue);

      setFormData({
        ...formState,
        facilityId: targetFacility?.id || Constants.DEFAULT_ID,
        facilityName: targetFacility?.name || '--',
        leadCoachId: targetCoach?.id || '',
        leadCoachName: targetCoach?.name || '--',
        effectiveDate: groupObj.orgGroupFieldValue.find(x => x.groupTypeField?.fieldName === GROUP_CONSTANTS.ORG_GROUP_TYPE_FIELD_NAME_EFFECTIVE_DATE)?.fieldValue,
        expirationDate: groupObj.orgGroupFieldValue.find(x => x.groupTypeField?.fieldName === GROUP_CONSTANTS.ORG_GROUP_TYPE_FIELD_NAME_EXPIRATION_DATE)?.fieldValue
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgGroupPropertiesState, groupState, groupCoachesState, groupFacilitiesState]);

  useEffect(() => {
    if (groupState.isObjLoaded === true && groupState.objData.groupTypeId !== orgGroupPropertiesState.groupTypeId) {
      getOrgGroupProperties(groupState.objData.groupTypeId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupState, orgGroupPropertiesState]);

  function submitFormCallback(formState) {
    if (groupState.isSaving === false) {
      const newOrgGroupObj = JSON.parse(JSON.stringify(groupState.objData));

      newOrgGroupObj.orgGroupFieldValue = createOrgGroupFieldValues([
        { fieldName: GROUP_CONSTANTS.ORG_GROUP_TYPE_FIELD_NAME_EFFECTIVE_DATE, fieldValue: formState.effectiveDate },
        { fieldName: GROUP_CONSTANTS.ORG_GROUP_TYPE_FIELD_NAME_EXPIRATION_DATE, fieldValue: formState.expirationDate },
        { fieldName: GROUP_CONSTANTS.ORG_GROUP_TYPE_FIELD_NAME_FACILITY, fieldValue: formState.facilityId },
        { fieldName: GROUP_CONSTANTS.ORG_GROUP_TYPE_FIELD_NAME_LEAD_COACH, fieldValue: formState.leadCoachId }
      ], orgGroupPropertiesState.arrayData, newOrgGroupObj.orgGroupId);
  
      if (newOrgGroupObj.orgGroupFieldValue.length > 0) {
        for (const orgGroupField of newOrgGroupObj.orgGroupFieldValue) {
          orgGroupField.groupTypeField = undefined;
        }
      }

      putGroup(newOrgGroupObj.orgGroupId, newOrgGroupObj);
    }
  };

  function getInitialFormState() {
    return {
      facilityId: Constants.DEFAULT_ID,
      facilityName: '--',
      leadCoachId: '',
      leadCoachName: '--',
      effectiveDate: Constants.BLANK_DATE_STRING,
      expirationDate: Constants.BLANK_DATE_STRING
    };
  };

  return {
    isSaving: groupState.isSaving,
    isLoading: orgGroupPropertiesState.isArrayLoading,
    formState,
    errorState,
    handleSubmit,
    onFormValueChanged,
    onValueTextPairChanged,
    onCancelClicked: resetForm,
    orgUnitId: groupState.objData.orgUnitId || ''
  };
};

export default useGroupClubSiteAttributes;