import { Fragment } from 'react';

import useAddGroupMemberForm from './UseAddGroupMemberForm';

import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';
import PrimaryButton from '../../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../../common/components/buttons/SecondaryButton';
import SearchMemberWithLscPopup from '../../../../../common/components/searches/searchMember/SearchMemberWithLscPopup';

import Constants from '../../../../../common/utils/Constants';

import global from '../../../../../common/components/GlobalStyle.module.css';

const AddGroupMemberForm = ({ primaryButtonText = 'Add Member', secondaryButtonText = 'Back', onSubmitFormCallback,
  onSecondaryButtonClicked }) => {
  const {
    isLoading,
    formState,
    errorState,
    handleSubmit,
    setFormData,
    onFormValueChanged
  } = useAddGroupMemberForm(onSubmitFormCallback);

  return (
    <Fragment>
      <form onSubmit={handleSubmit} noValidate>
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          <div className="col-xs-12 col-sm-6 col-md-4">
            <SearchMemberWithLscPopup
              formState={formState}
              errorState={errorState}
              setFormData={setFormData}
              onFormValueChanged={onFormValueChanged}
              isCurrentOrgUnitMember={true} />
          </div>
        </div>
        {errorState.apiError &&
          <div className="row">
            <div className="col-xs-12 usas-extra-top-margin">
              <p className={global.ErrorMessage}>{errorState.apiError}</p>
            </div>
          </div>
        }
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="submit">{primaryButtonText}</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onSecondaryButtonClicked}>{secondaryButtonText}</SecondaryButton>
          </div>
        </div>
      </form>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
    </Fragment>
  );
};

export default AddGroupMemberForm;