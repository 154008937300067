import { useEffect, useState } from 'react';

import useGroupData from '../../../../common/state/group/UseGroupData';

const INITIAL_VIEW_STATE = {
  reportParameters: { orgGroupId: '' },
  contextId: '',
  routeName: ''
};

const useGroupReporting = () => {
  const [viewState, setViewState] = useState({ ...INITIAL_VIEW_STATE });
  const { groupState } = useGroupData();

  useEffect(() => {
    if (groupState.objData?.orgGroupId) {
      setViewState({
        ...viewState,
        reportParameters: { orgGroupId: groupState.objData?.orgGroupId },
        contextId: groupState.objData?.orgGroupId,
        routeName: 'GROUP_REPORTING'
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupState.objData]);

  return { ...viewState };
};

export default useGroupReporting;