import useOrgGroupCategoriesTreeView from './UseOrgGroupCategoriesTreeView';

import TreeView from '../TreeView';

import global from '../../GlobalStyle.module.css';

const OrgGroupCategoriesTreeView = ({ label, name, value, error, message, selectableLeavesOnly, singleSelect,
  onChange, groupCategoryLevel }) => {
  const { orgGroupCategoriesState } = useOrgGroupCategoriesTreeView(groupCategoryLevel || null);

  return orgGroupCategoriesState.message
    ? <span className={global.LoadingMsg}>{orgGroupCategoriesState.message}</span>
    : <TreeView
      label={label}
      name={name}
      value={value}
      error={error}
      message={message}
      isLoading={orgGroupCategoriesState.isArrayLoading}
      options={orgGroupCategoriesState.treeData}
      selectableLeavesOnly={selectableLeavesOnly}
      singleSelect={singleSelect}
      onChange={onChange} />
};

export default OrgGroupCategoriesTreeView;