import { isValidNumber } from '../../../../../common/utils/validation';

const CompetitorListFormValidation = (formState) => {
  let errors = {};

  if (!isValidNumber(formState.groupYear)) {
    errors.groupYear = 'Year is required';
  }

  return errors;
};

export default CompetitorListFormValidation;