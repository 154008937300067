import { isValidAlphanumericAndDollarSign } from '../../../utils/validation';

export const localValidate = (formState) => {
  let errors = {};
  let filterCount = 0;

  // Organization
  if (formState.orgUnit.length > 0) {
    filterCount += 1;
  }

  // Group Category
  if (formState.groupCategories.length > 0) {
    filterCount += 1;
  }

  //Group Name
  if (formState.groupName) {
    filterCount += 1;
  }

  //Group Code
  if (formState.groupCode) {
    filterCount += 1;

    if (!isValidAlphanumericAndDollarSign(formState.groupCode)) {
      errors.groupCode = 'Group Code can only contain numbers, letters, and dollar signs';
    }
  }

  if (filterCount < 1) {
    errors.filter = 'Please enter information in at least one search filter';
  }

  return errors;
};

const SearchGroupValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
}

export default SearchGroupValidation;