import NavLinks from '../../NavLinks';

import useEnvironmentVariableData from '../../../../../common/state/environmentVariable/UseEnvironmentVariableData';

const useOrgUnitSelection = () => {
  const { environmentVariableState, GROUP_SELECTOR_ROLES_ROLE_GROUP_ID } = useEnvironmentVariableData();

  return {
    isLoaded: environmentVariableState.isLoaded === true,
    NavLinks,
    GROUP_SELECTOR_ROLES_ROLE_GROUP_ID
  };
};

export default useOrgUnitSelection;