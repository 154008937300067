import { useNavigate } from '../../../common/wrappers/ReactRouterDom';

import NavLinks from './NavLinks';

import useGroupData from '../../../common/state/group/UseGroupData';
import useSecurityData from '../../../common/state/security/UseSecurityData';

const BACK_TO_LABELS = {
  groupSearchBackToLabel: 'Back to Group Search',
  groupMyGroupsBackToLabel: 'Back to My Groups'
};

const useGroupContextView = () => {
  const navigate = useNavigate();
  const { groupState, clearObjData } = useGroupData();
  const { securityState } = useSecurityData();

  const onHomeClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(NavLinks.CLUB_INFO);
  };

  const onBackClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    if (Array.isArray(securityState?.userInfo?.appRoutes)) {
      const appRoutes = securityState.userInfo.appRoutes;

      if (appRoutes.some(x => x.routeName === 'GROUP_SEARCH')) {
        goToGroupSearch();
      } else if (appRoutes.some(x => x.routeName === 'GROUP_MY_GROUPS')) {
        goToGroupMyGroups();
      }
    }
  };

  function goToGroupSearch() {
    clearObjData();
    navigate(NavLinks.GROUP_SEARCH);
  };

  function goToGroupMyGroups() {
    clearObjData();
    navigate(NavLinks.GROUP_MY_GROUPS);
  };

  function getBackToLabel() {
    if (Array.isArray(securityState?.userInfo?.appRoutes)) {
      const appRoutes = securityState.userInfo.appRoutes;

      if (appRoutes.some(x => x.routeName === 'GROUP_SEARCH')) {
        return BACK_TO_LABELS.groupSearchBackToLabel;
      } else if (appRoutes.some(x => x.routeName === 'GROUP_MY_GROUPS')) {
        return BACK_TO_LABELS.groupMyGroupsBackToLabel;
      }
    } else {
      return 'Back To Groups';
    }
  };

  return {
    groupState,
    backToLabel: getBackToLabel(),
    onBackClicked,
    onHomeClicked
  };
};

export default useGroupContextView;