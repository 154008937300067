import { Fragment } from 'react';

import PlusIcon from '../../../../../common/components/icons/PlusIcon';

import global from '../../../../../common/components/GlobalStyle.module.css';

const GridRow = ({ group, onSelectClicked }) => (
  <tr>
    <td>{group.groupCode || ''}</td>
    <td>{group.groupName || ''}</td>
    <td>
      <button
        className={global.IconButtonMargin}
        type="button"
        onClick={() => onSelectClicked(group.orgGroupId)}>
        <PlusIcon />
      </button>
    </td>
  </tr>
);

const SearchGroupPopupGrid = ({ data, isLoading, onSelectClicked }) => (
  <Fragment>
    <table className={global.UsasTable}>
      <thead>
        <tr>
          <th>Group Code</th>
          <th>Group Name</th>
          <th>Select</th>
        </tr>
      </thead>
      <tbody>
        {isLoading === true
          ? <tr><td colSpan="3">Loading...</td></tr>
          : Array.isArray(data) && data.length > 0
            ? data.map((group, i) => <GridRow key={i} group={group} onSelectClicked={onSelectClicked} />)
            : <tr><td colSpan="3">No Results - Group Not Found</td></tr>
        }
      </tbody>
    </table>
  </Fragment>
);

export default SearchGroupPopupGrid;