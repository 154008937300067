import Constants from '../../../../../common/utils/Constants';
import { isValidNumber, isValidDate, isValidAddressLine, isValid5DigitZip, isValid9DigitZip } from '../../../../../common/utils/validation';

const CampAttributesFormValidation = (formState) => {
  let errors = {};

  const qualifyingStartDate = new Date(formState.qualifyingStartDate);
  const qualifyingEndDate = new Date(formState.qualifyingEndDate);
  const startDate = new Date(formState.startDate);
  const endDate = new Date(formState.endDate);
  const invitedAthletesValue = parseInt(formState.invitedAthletesCount);

  if (!isValidNumber(formState.groupYear)) {
    errors.groupYear = 'Year is required';
  }

  if (formState.city.trim().length === 0) {
    errors.city = 'City is required';
  } else if (!isValidAddressLine(formState.city.trim())) {
    errors.city = 'City cannot exceed 100 characters. Letters, numbers, number signs, spaces, periods, dashes, slashes, and apostrophes are allowed.';
  }

  if (formState.stateName === '') {
    errors.stateCode = 'State is required';
  } else if (formState.stateCode === Constants.DEFAULT_ID || formState.stateCode === '') {
    errors.stateCode = 'State must be valid';
  }

  if (formState.zip.trim() === '') {
    errors.zip = 'Zip Code is required';
  } else if (!isValid5DigitZip(formState.zip.trim()) && !isValid9DigitZip(formState.zip.trim())) {
    errors.zip = 'Zip Code must be 5 digits, or 9 digits in XXXXX-XXXX format';
  }

  if (formState.qualifyingStartDate === Constants.BLANK_DATE_STRING || formState.qualifyingStartDate === '') {
    errors.qualifyingStartDate = 'Qualifying Start Date is required';
  } else if (!isValidDate(formState.qualifyingStartDate)) {
    errors.qualifyingStartDate = 'Qualifying Start Date must be a valid date';
  } else if (qualifyingEndDate < qualifyingStartDate || formState.qualifyingStartDate === formState.qualifyingEndDate) {
    errors.qualifyingStartDate = 'Qualifying Start Date must be before the Qualifying End Date';
  }

  if (formState.qualifyingEndDate === Constants.BLANK_DATE_STRING || formState.qualifyingEndDate === '') {
    errors.qualifyingEndDate = 'Qualifying End Date is required';
  } else if (!isValidDate(formState.qualifyingEndDate)) {
    errors.qualifyingEndDate = 'Qualifying End Date must be a valid date';
  } else if (qualifyingEndDate < qualifyingStartDate || formState.qualifyingStartDate === formState.qualifyingEndDate) {
    errors.qualifyingEndDate = 'Qualifying End Date must be after the Qualifying Start Date';
  }

  if (formState.startDate === Constants.BLANK_DATE_STRING || formState.startDate === '') {
    errors.startDate = 'Start Date is required';
  } else if (!isValidDate(formState.startDate)) {
    errors.startDate = 'Start Date must be a valid date';
  } else if (endDate < startDate || formState.startDate === formState.endDate) {
    errors.startDate = 'Start Date must be before the End Date';
  }

  if (formState.endDate === Constants.BLANK_DATE_STRING || formState.endDate === '') {
    errors.endDate = 'End Date is required';
  } else if (!isValidDate(formState.endDate)) {
    errors.endDate = 'End Date must be a valid date';
  } else if (endDate < startDate || formState.startDate === formState.endDate) {
    errors.endDate = 'End Date must be after the Start Date';
  }

  if (isNaN(invitedAthletesValue)) {
    errors.invitedAthletesCount = 'Must be a valid number';
  } else if (invitedAthletesValue < 0) {
    errors.invitedAthletesCount = 'Cannot be a negative number';
  }

  return errors;
};

export default CampAttributesFormValidation;