import React, {createContext, useState} from 'react';

export const GroupFiltersStateContext = createContext();

const INITIAL_STATE = {
  filterObject: {},
};

const GroupFiltersContextProvider = ({children}) => {
  const stateHook = useState(INITIAL_STATE);

  return (
    <GroupFiltersStateContext.Provider value={stateHook}>
      {children}
    </GroupFiltersStateContext.Provider>
  );
};

export default GroupFiltersContextProvider;