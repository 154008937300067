import { Fragment } from "react";

import NationalTeamGroupMemberGridLarge from "./NationalTeamGroupMemberGridLarge";
import NationalTeamGroupMemberGridSmall from "./NationalTeamGroupMemberGridSmall";

const NationalTeamGroupMemberGrid = ({ data, isLoading, onEditClicked, onDeleteClicked }) => (
  <Fragment>
    <NationalTeamGroupMemberGridLarge data={data} isLoading={isLoading} onEditClicked={onEditClicked} onDeleteClicked={onDeleteClicked} />
    <NationalTeamGroupMemberGridSmall data={data} isLoading={isLoading} onEditClicked={onEditClicked} onDeleteClicked={onDeleteClicked} />
  </Fragment>
);

export default NationalTeamGroupMemberGrid;