import { useEffect } from 'react';

import { createOrgGroupFieldValues } from '../../utils/GroupUtils';

import validate from '../../components/forms/CampAttributesFormValidation';

import useStatesCombobox from '../../../../../common/components/comboboxes/statesCombobox/UseStatesCombobox';

import useOrgGroupPropertiesData from '../../../../../common/state/orgGroupProperties/UseOrgGroupPropertiesData';
import useGroupData from '../../../../../common/state/group/UseGroupData';
import useGroupFieldNamesData from '../../../../../common/state/groupFieldNames/UseGroupFieldNamesData';

import useForm from '../../../../../common/utils/UseForm';
import Constants from '../../../../../common/utils/Constants';

const useGroupCampAttributes = () => {
  const { GROUP_CONSTANTS } = useGroupFieldNamesData();
  const { groupState, putGroup } = useGroupData();
  const { orgGroupPropertiesState, getOrgGroupProperties } = useOrgGroupPropertiesData();
  const { formState, errorState, onFormValueChanged, handleSubmit, setFormData, resetForm, onValueTextPairChanged
  } = useForm(getInitialFormState, submitFormCallback, validate);
  const { getStateNameById } = useStatesCombobox();

  useEffect(() => {
    if (groupState.isObjLoaded === true) {
      if (orgGroupPropertiesState.isArrayLoaded === true &&
        orgGroupPropertiesState.groupTypeId === groupState.objData.groupTypeId) {
        const groupObj = groupState.objData;

        setFormData({
          ...formState,
          groupYear: groupObj.orgGroupFieldValue.find(x => x.groupTypeField?.fieldName === GROUP_CONSTANTS.ORG_GROUP_TYPE_FIELD_NAME_YEAR)?.fieldValue,
          city: groupObj.orgGroupFieldValue.find(x => x.groupTypeField?.fieldName === GROUP_CONSTANTS.ORG_GROUP_TYPE_FIELD_NAME_CITY)?.fieldValue,
          stateCode: groupObj.orgGroupFieldValue.find(x => x.groupTypeField?.fieldName === GROUP_CONSTANTS.ORG_GROUP_TYPE_FIELD_NAME_STATE)?.fieldValue,
          stateName: getStateNameById(groupObj.orgGroupFieldValue.find(x => x.groupTypeField?.fieldName === GROUP_CONSTANTS.ORG_GROUP_TYPE_FIELD_NAME_STATE)?.fieldValue),
          zip: groupObj.orgGroupFieldValue.find(x => x.groupTypeField?.fieldName === GROUP_CONSTANTS.ORG_GROUP_TYPE_FIELD_NAME_ZIP)?.fieldValue,
          qualifyingStartDate: groupObj.orgGroupFieldValue.find(x => x.groupTypeField?.fieldName === GROUP_CONSTANTS.ORG_GROUP_TYPE_FIELD_NAME_QUALIFYING_START_DATE)?.fieldValue,
          qualifyingEndDate: groupObj.orgGroupFieldValue.find(x => x.groupTypeField?.fieldName === GROUP_CONSTANTS.ORG_GROUP_TYPE_FIELD_NAME_QUALIFYING_END_DATE)?.fieldValue,
          startDate: groupObj.orgGroupFieldValue.find(x => x.groupTypeField?.fieldName === GROUP_CONSTANTS.ORG_GROUP_TYPE_FIELD_NAME_START_DATE)?.fieldValue,
          endDate: groupObj.orgGroupFieldValue.find(x => x.groupTypeField?.fieldName === GROUP_CONSTANTS.ORG_GROUP_TYPE_FIELD_NAME_END_DATE)?.fieldValue,
          invitedAthletesCount: groupObj.orgGroupFieldValue.find(x => x.groupTypeField?.fieldName === GROUP_CONSTANTS.ORG_GROUP_TYPE_FIELD_NAME_TOTAL_ATHLETES)?.fieldValue,
        });
      } else if (orgGroupPropertiesState.isArrayLoading === false && groupState.objData.groupTypeId) {
        getOrgGroupProperties(groupState.objData.groupTypeId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgGroupPropertiesState, groupState]);

  function submitFormCallback(formState) {
    if (groupState.isSaving === false) {
      const newOrgGroupObj = JSON.parse(JSON.stringify(groupState.objData));

      newOrgGroupObj.orgGroupFieldValue = createOrgGroupFieldValues([
        { fieldName: GROUP_CONSTANTS.ORG_GROUP_TYPE_FIELD_NAME_YEAR, fieldValue: formState.groupYear },
        { fieldName: GROUP_CONSTANTS.ORG_GROUP_TYPE_FIELD_NAME_CITY, fieldValue: formState.city },
        { fieldName: GROUP_CONSTANTS.ORG_GROUP_TYPE_FIELD_NAME_STATE, fieldValue: formState.stateCode },
        { fieldName: GROUP_CONSTANTS.ORG_GROUP_TYPE_FIELD_NAME_ZIP, fieldValue: formState.zip },
        { fieldName: GROUP_CONSTANTS.ORG_GROUP_TYPE_FIELD_NAME_QUALIFYING_START_DATE, fieldValue: formState.qualifyingStartDate },
        { fieldName: GROUP_CONSTANTS.ORG_GROUP_TYPE_FIELD_NAME_QUALIFYING_END_DATE, fieldValue: formState.qualifyingEndDate },
        { fieldName: GROUP_CONSTANTS.ORG_GROUP_TYPE_FIELD_NAME_START_DATE, fieldValue: formState.startDate },
        { fieldName: GROUP_CONSTANTS.ORG_GROUP_TYPE_FIELD_NAME_END_DATE, fieldValue: formState.endDate },
        { fieldName: GROUP_CONSTANTS.ORG_GROUP_TYPE_FIELD_NAME_TOTAL_ATHLETES, fieldValue: formState.invitedAthletesCount }
      ], orgGroupPropertiesState.arrayData, newOrgGroupObj.orgGroupId);

      if (newOrgGroupObj.orgGroupFieldValue.length > 0) {
        for (const orgGroupField of newOrgGroupObj.orgGroupFieldValue) {
          orgGroupField.groupTypeField = undefined;
        }
      }

      putGroup(newOrgGroupObj.orgGroupId, newOrgGroupObj);
    }
  };

  function getInitialFormState() {
    return {
      groupYear: 'Year',
      city: '',
      stateCode: '',
      stateName: '',
      zip: '',
      invitedAthletesCount: '',
      qualifyingStartDate: Constants.BLANK_DATE_STRING,
      qualifyingEndDate: Constants.BLANK_DATE_STRING,
      startDate: Constants.BLANK_DATE_STRING,
      endDate: Constants.BLANK_DATE_STRING
    };
  };

  return {
    isSaving: groupState.isSaving,
    isLoading: orgGroupPropertiesState.isArrayLoading,
    formState,
    errorState,
    handleSubmit,
    onFormValueChanged,
    onValueTextPairChanged,
    onCancelClicked: resetForm
  };
};

export default useGroupCampAttributes;