const NavLinks = {
  GROUP_ROOT: '/group',
  GROUP_SEARCH: '/group/search',
  GROUP_ADD: '/group/add',
  GROUP_ADD_SELECTION: '/group/add/selection',
  GROUP_ADD_MEMBERS: '/group/add/members',
  GROUP_ADD_LIST: '/group/add/list',
  GROUP_ADD_COMPETITOR_LIST: '/group/add/competitorlist',
  GROUP_ADD_CAMP: '/group/add/camp',
  GROUP_ADD_SCHOLASTIC: '/group/add/scholasticallamerica',
  GROUP_ADD_NATIONALTEAM: '/group/add/nationalteam',
  GROUP_ADD_REGISTRATION: '/group/add/registration',
  GROUP_INFO: '/group/info',
  GROUP_REGISTRATION: '/group/registration',
  GROUP_MEMBERS: '/group/members',
  GROUP_MEMBERS_ADD: '/group/members/add',
  GROUP_MEMBERS_EDIT: '/group/members/edit',
  GROUP_MEMBERS_INVITE_ALTERNATE: '/group/members/invitealternate',
  GROUP_MEMBERS_INVITE_ALTERNATE_IMX: '/group/members/invitealternateimx',
  GROUP_SELECTION: '/group/selection',
  GROUP_MY_GROUPS: '/group/mygroups'
};

export default NavLinks;