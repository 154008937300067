import { useEffect } from 'react';

import NavLinks from '../NavLinks';

import useEnvironmentVariableData from '../../../../common/state/environmentVariable/UseEnvironmentVariableData';
import useGroupData from '../../../../common/state/group/UseGroupData';

const useGroupSelection = () => {
  const { environmentVariableState, GROUP_SELECTOR_ROLES_ROLE_GROUP_ID } = useEnvironmentVariableData();
  const { clearGroupFilterData } = useGroupData();

  useEffect(() => {
    clearGroupFilterData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoaded: environmentVariableState.isLoaded === true,
    NavLinks,
    GROUP_SELECTOR_ROLES_ROLE_GROUP_ID
  };
};

export default useGroupSelection;