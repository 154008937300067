import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from '../../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../../NavLinks';

import { createGroupObjFromGroupInfoForm, createOrgGroupFieldValues } from '../../utils/GroupUtils';

import validate from './GroupCompetitorListAddValidation';

import useGroupAddData from '../../../../state/groupAdd/UseGroupAddData';

import useGroupCategoryData from '../../../../../common/state/groupCategory/UseGroupCategoryData';
import useOrgGroupPropertiesData from '../../../../../common/state/orgGroupProperties/UseOrgGroupPropertiesData';
import useSelectOrgUnitData from '../../../../../common/state/selectOrgUnit/UseSelectOrgUnitData';
import useGroupFieldNamesData from '../../../../../common/state/groupFieldNames/UseGroupFieldNamesData';

import useForm from '../../../../../common/utils/UseForm';
import Constants from '../../../../../common/utils/Constants';
import HierarchicalDataUtils from '../../../../../common/utils/HierarchicalDataUtils';

const useGroupCompetitorListAdd = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { GROUP_CONSTANTS } = useGroupFieldNamesData();
  const { selectOrgUnitState } = useSelectOrgUnitData();
  const { groupAddState, updateGroupAddObj } = useGroupAddData();
  const { groupCategoryState } = useGroupCategoryData();
  const { orgGroupPropertiesState, getOrgGroupProperties } = useOrgGroupPropertiesData();
  const { formState, errorState, onFormValueChanged, handleSubmit, setFormState, setIsDirty
  } = useForm(getInitialFormState, submitFormCallback, validate);
  const [state, setState] = useState(getInitialState);

  const onBackClicked = () => {
    navigate(NavLinks.GROUP_ADD_SELECTION);
  };

  useEffect(() => {
    if (groupCategoryState.isArrayLoaded === true && groupCategoryState.id === selectOrgUnitState.orgUnitId
      && orgGroupPropertiesState.isArrayLoaded === true && orgGroupPropertiesState.groupTypeId === state.groupTypeId) {
      // On entry from group select type
      if (location.state?.groupTypeId && location.state?.groupTypeName && selectOrgUnitState.orgUnitId) {
        setFormState({
          ...formState,
          groupTypeId: location.state.groupTypeId,
          groupTypeName: location.state.groupTypeName,
          orgUnitId: selectOrgUnitState.orgUnitId
        });

        setIsDirty(true);
      } else if (groupAddState.isObjLoaded === true) { // On entry from a back button 
        const groupObj = groupAddState.objData;

        setFormState({
          ...formState,
          orgUnitId: groupObj.orgUnitId,
          groupTypeId: groupObj.groupTypeId,
          groupTypeName: groupAddState.groupTypeName,
          groupCategories: HierarchicalDataUtils.GetNameIdPairs(groupCategoryState.treeData, [groupObj.groupCategoryId]),
          groupName: groupObj.groupName,
          groupCode: groupObj.groupCode,
          groupDescription: groupObj.groupDescription,
          groupYear: groupObj.orgGroupFieldValue.find(x => x.groupTypeField?.fieldName === GROUP_CONSTANTS.ORG_GROUP_TYPE_FIELD_NAME_YEAR)?.fieldValue
        });

        setIsDirty(true);
      } else {
        navigate(NavLinks.GROUP_ADD_SELECTION);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupCategoryState, orgGroupPropertiesState, state]);

  useEffect(() => {
    if (location.state?.groupTypeId) { // On entry from group select type
      setState({ ...state, groupTypeId: location.state.groupTypeId });

      if (orgGroupPropertiesState.groupTypeId !== location.state.groupTypeId) {
        getOrgGroupProperties(location.state.groupTypeId);
      }
    } else if (groupAddState.isObjLoaded === true && groupAddState.objData.groupTypeId) { // On entry from a back button 
      setState({ ...state, groupTypeId: groupAddState.objData.groupTypeId });

      if (orgGroupPropertiesState.groupTypeId !== groupAddState.objData.groupTypeId) {
        getOrgGroupProperties(groupAddState.objData.groupTypeId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (state.tryRedirect === true) { // After continue button clicked
      navigate(NavLinks.GROUP_ADD_REGISTRATION);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.tryRedirect]);

  function submitFormCallback(formState) {
    const newOrgGroupObj = createGroupObjFromGroupInfoForm(groupAddState.objData, formState);

    newOrgGroupObj.orgGroupFieldValue = createOrgGroupFieldValues([
      { fieldName: GROUP_CONSTANTS.ORG_GROUP_TYPE_FIELD_NAME_YEAR, fieldValue: formState.groupYear }
    ], orgGroupPropertiesState.arrayData);

    updateGroupAddObj(newOrgGroupObj, formState.groupTypeName);

    setState({ ...state, tryRedirect: true });
  };

  function getInitialFormState() {
    return {
      orgUnitId: '',
      groupTypeId: Constants.DEFAULT_ID,
      groupTypeName: '',
      groupCategories: [],
      groupName: '',
      groupCode: '',
      groupDescription: '',
      groupYear: 'Year'
    };
  };

  function getInitialState() {
    return {
      tryRedirect: false,
      groupTypeId: Constants.DEFAULT_ID
    };
  };

  return {
    isLoading: groupCategoryState.isArrayLoading || orgGroupPropertiesState.isArrayLoading,
    formState,
    errorState,
    handleSubmit,
    onFormValueChanged,
    onBackClicked,
    orgUnitId: selectOrgUnitState.orgUnitId || ''
  };
};

export default useGroupCompetitorListAdd;