import React, { Fragment } from 'react';

import useGroupContextView from './UseGroupContextView';

import NavLinks from './NavLinks';

import Headings from '../../../common/components/headings/Headings';
import LeftArrowIcon from '../../../common/components/icons/LeftArrowIcon';
import PageNotFound from '../../../common/components/PageNotFound';
import MobileContextBasedNav from '../../../common/components/contextBasedNav/MobileContextBasedNav';
import LargeContextBasedNav from '../../../common/components/contextBasedNav/LargeContextBasedNav';

import global from '../../../common/components/GlobalStyle.module.css';
import Constants from '../../../common/utils/Constants';
import PopUpModal from '../../../common/components/dialogs/PopUpModal';

const GroupContextView = ({ children }) => {
  const {
    groupState,
    backToLabel,
    onBackClicked,
    onHomeClicked
  } = useGroupContextView();

  if (!groupState || groupState.isObjLoaded === false) {
    return groupState.isObjLoading === false
      ? (
        <Fragment>
          <div className="row usas-extra-bottom-margin">
            <div className="col-xs-12">
              <button className={global.BackToSearchButton}
                type="button"
                onClick={onBackClicked}>
                <LeftArrowIcon />  {backToLabel}
              </button>
            </div>
          </div>
          <div className="row usas-extra-bottom-margin">
            <div className="col-xs-12">
              <PageNotFound />
            </div>
          </div>
        </Fragment>
      ) : (
        <div className="row usas-extra-bottom-margin">
          <div className="col-xs-12">
            <PopUpModal
              widthPct={50}
              maxWidth={250}
              title={Constants.LOADING_MSG}
              displayPopUp={true} />
          </div>
        </div>
      );
  }

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <button className={global.HeaderButton} onClick={onHomeClicked}>
            <Headings.H3
              className={[global.NoBottomMargin, 'col-xs-12'].join(' ')}
              excludeBar={true}>
              View/Edit Group
            </Headings.H3>
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <hr className="usas-bar-turmeric" />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <button className={global.BackToSearchButton}
            type="button"
            onClick={onBackClicked}>
            <LeftArrowIcon />  {backToLabel}
          </button>
        </div>
      </div>
      {(groupState.objData?.groupName || groupState.objData?.groupCode || groupState.objData?.groupType?.typeName)
        ? <div>
          <div className="row">
            <div className="col-xs-12">
              <p className={global.HeaderText}><strong>Group Name:</strong>&nbsp;{groupState.objData?.groupName}</p>
              <p className={global.HeaderText}><strong>Group Code:</strong>&nbsp;{groupState.objData?.groupCode}</p>
              <p className={global.HeaderText}><strong>Group Type:</strong>&nbsp;{groupState.objData?.groupType?.typeName}</p>
            </div>
          </div>
        </div>
        : <Fragment />
      }
      <div className="row">
        <div className="visible-xs col-xs-12">
          <MobileContextBasedNav taxonomyName="GroupsDetail" renderPillsOnly={true} />
        </div>
        <div className="hidden-xs col-xs-12">
          <LargeContextBasedNav taxonomyName="GroupsDetail" homeRoute={NavLinks.GROUP_INFO} renderPillsOnly={true} />
        </div>
      </div>
      {children}
    </Fragment>
  );
};

export default GroupContextView;