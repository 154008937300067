import Checkbox from "../../../../../common/components/checkboxes/Checkbox";

import global from '../../../../../common/components/GlobalStyle.module.css';

const SmallGridRow = ({ member, onCheckboxClicked }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableRowHead}><span className="hidden-xs">{member.firstName || ''} {member.lastName || ''}</span>&nbsp;
      {onCheckboxClicked &&
        <div className={global.CheckboxMobile}>
          <Checkbox
            className={global.IconButton}
            label=""
            name="isSelected"
            onChange={() => { onCheckboxClicked(member.groupQualificationImxId) }}
            checked={member.isSelected} />
        </div>
      }
    </div>
    <div className={global.SmallTableRowInfo}>
      <div className="row">
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>Name</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>{member.firstName || ''} {member.lastName || ''}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>IMX Score</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{member.imxScore || ''}</div>
      </div>
    </div>
  </div>
);

const InviteGroupMemberIMXGridSmall = ({ data, isLoading, onCheckboxClicked }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {isLoading === true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>&nbsp;</div>
          <div className={global.SmallTableRowInfo}>
            <div className={global.SmallTableRowLabels}>Loading...</div>
          </div>
        </div>
      )
      : data.length > 0
        ? data.map((member, i) => <SmallGridRow key={i} member={member} onCheckboxClicked={onCheckboxClicked} />)
        : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>&nbsp;</div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Alternate Athletes</div>
            </div>
          </div>
        )
    }
  </div>
);

export default InviteGroupMemberIMXGridSmall;