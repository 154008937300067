import React, { Fragment } from 'react';

import Headings from '../../../../../common/components/headings/Headings';

import global from '../../../../../common/components/GlobalStyle.module.css';

const GroupListAttributes = () => {

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>List Group Attributes</Headings.H3>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
          <p className={global.HeaderText}>List Group has no unique attributes</p>
        </div>
      </div>
    </Fragment>
  );
};

export default GroupListAttributes;