import { useState, useEffect } from 'react';
import { useNavigate } from '../../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../../NavLinks';

import useGroupAddData from '../../../../state/groupAdd/UseGroupAddData';

import useOrgGroupTypesData from '../../../../../common/state/orgGroupTypes/UseOrgGroupTypesData';
import useGroupFieldNamesData from '../../../../../common/state/groupFieldNames/UseGroupFieldNamesData';
import useSecurityData from '../../../../../common/state/security/UseSecurityData';

import useForm from '../../../../../common/utils/UseForm';

const useGroupSelectType = () => {
  const navigate = useNavigate();
  const { GROUP_CONSTANTS } = useGroupFieldNamesData();
  const { securityState } = useSecurityData();
  const { clearObjData } = useGroupAddData();
  const { orgGroupTypesState } = useOrgGroupTypesData();
  const { formState, errorState, setIsDirty, setFormState
  } = useForm(getInitialFormState);
  const [state, setState] = useState(getInitialState);

  const onContinueClicked = () => {
    const targetGroup = state.orgGroupTypes.find(x => x.value === formState.selectedGroupType);

    if (targetGroup?.value) {
      switch (targetGroup.value) {
        case GROUP_CONSTANTS.GROUP_TYPE_NAME_LIST:
          navigate(NavLinks.GROUP_ADD_LIST, { state: { groupTypeId: targetGroup.id, groupTypeName: targetGroup.value } });
          break;
        case GROUP_CONSTANTS.GROUP_TYPE_NAME_COMPETITOR_LIST:
          navigate(NavLinks.GROUP_ADD_COMPETITOR_LIST, { state: { groupTypeId: targetGroup.id, groupTypeName: targetGroup.value } });
          break;
        case GROUP_CONSTANTS.GROUP_TYPE_NAME_CAMP:
          navigate(NavLinks.GROUP_ADD_CAMP, { state: { groupTypeId: targetGroup.id, groupTypeName: targetGroup.value } });
          break;
        case GROUP_CONSTANTS.GROUP_TYPE_NAME_SAA:
          navigate(NavLinks.GROUP_ADD_SCHOLASTIC, { state: { groupTypeId: targetGroup.id, groupTypeName: targetGroup.value } });
          break;
        case GROUP_CONSTANTS.GROUP_TYPE_NAME_NATIONAL_TEAM:
          navigate(NavLinks.GROUP_ADD_NATIONALTEAM, { state: { groupTypeId: targetGroup.id, groupTypeName: targetGroup.value } });
          break;
        default:
          setState({
            ...state,
            continueErrorMessage: 'An error occurred'
          });
      }
    } else {
      setState({
        ...state,
        continueErrorMessage: 'You must select a group type to continue'
      });
    }
  };

  const onBackClicked = () => {
    if (Array.isArray(securityState?.userInfo?.appRoutes)) {
      const appRoutes = securityState.userInfo.appRoutes;

      if (appRoutes.some(x => x.routeName === 'GROUP_SEARCH')) {
        navigate(NavLinks.GROUP_SEARCH);
      } else if (appRoutes.some(x => x.routeName === 'GROUP_MY_GROUPS')) {
        navigate(NavLinks.GROUP_MY_GROUPS);
      }
    }
  };

  const onSelectedGroupTypeChange = (value) => {
    const newDescription = state.orgGroupTypes.find(x => x.value === value)?.description;
    setIsDirty(true);
    setFormState({
      ...formState,
      selectedGroupType: value,
      selectedGroupTypeDescription: newDescription || ''
    });
  };

  useEffect(() => {
    clearObjData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (orgGroupTypesState.isArrayLoaded === true) {
      setState({
        ...state,
        orgGroupTypes: filterGroupTypes(orgGroupTypesState.arrayData)
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgGroupTypesState]);

  function filterGroupTypes(groupTypes) {
    const filteredGroupTypes = [];

    for (let i = 0; i < groupTypes.length; i++) {
      if (groupTypes[i].typeName !== GROUP_CONSTANTS.GROUP_TYPE_NAME_TRAVEL_TRIP
        && groupTypes[i].typeName !== GROUP_CONSTANTS.GROUP_TYPE_NAME_PRACTICE_GROUP
        && groupTypes[i].typeName !== GROUP_CONSTANTS.GROUP_TYPE_NAME_CLUB_SITE) {
        filteredGroupTypes.push({
          id: groupTypes[i].groupTypeId,
          value: groupTypes[i].typeName,
          label: groupTypes[i].typeName,
          description: groupTypes[i].typeDescription
        });
      }
    }

    return filteredGroupTypes;
  }

  function getInitialFormState() {
    return {
      selectedGroupType: '',
      selectedGroupTypeDescription: 'Please select a Group Type'
    };
  }

  function getInitialState() {
    return {
      orgGroupTypes: [],
      continueErrorMessage: ''
    };
  }

  return {
    isLoading: orgGroupTypesState.isArrayLoading,
    state,
    formState,
    errorState,
    onSelectedGroupTypeChange,
    onContinueClicked,
    onBackClicked
  }
};

export default useGroupSelectType;