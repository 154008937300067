import { useEffect, useState } from 'react';
import { useNavigate } from '../../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../../NavLinks';

import validate from '../../components/forms/GroupRegistrationFormValidation';

import { createGroupObjFromGroupRegistrationForm } from '../../utils/GroupUtils';

import useGroupAddData from '../../../../state/groupAdd/UseGroupAddData';

import useEnvironmentVariableData from '../../../../../common/state/environmentVariable/UseEnvironmentVariableData';
import useOrgRoleStaffRoleHierarchyData from '../../../../../common/state/orgRoleStaffRoleHierarchy/UseOrgRoleStaffRoleHierarchyData';
import useOrgGroupTypesData from '../../../../../common/state/orgGroupTypes/UseOrgGroupTypesData';
import useGroupFieldNamesData from '../../../../../common/state/groupFieldNames/UseGroupFieldNamesData';

import useForm from '../../../../../common/utils/UseForm';
import Constants from '../../../../../common/utils/Constants';
import HierarchicalDataUtils from '../../../../../common/utils/HierarchicalDataUtils';
import { formatDate } from '../../../../../common/utils/DateFunctions';

const useGroupRegistrationAdd = () => {
  const navigate = useNavigate();
  const { environmentVariableState, GROUP_ROLES_ROLE_GROUP_ID } = useEnvironmentVariableData();
  const { GROUP_CONSTANTS } = useGroupFieldNamesData();
  const { orgGroupTypesState } = useOrgGroupTypesData();
  const { orgRoleStaffRoleHierarchyState } = useOrgRoleStaffRoleHierarchyData();
  const { groupAddState, updateGroupAddObj, postGroup } = useGroupAddData();
  const { formState, errorState, onFormValueChanged, handleSubmit, setFormState, setIsDirty
  } = useForm(getInitialFormState, submitFormCallback, validate);
  const [state, setState] = useState(getInitialState);

  const onBackClicked = () => {
    updateGroupAddObj(createGroupObjFromGroupRegistrationForm(groupAddState.objData, formState));

    setState({ ...state, tryRedirect: true });
  };

  useEffect(() => {
    if (groupAddState.isObjLoaded === true) {
      if (orgRoleStaffRoleHierarchyState.isArrayLoaded === true && orgGroupTypesState.isArrayLoaded === true
        && environmentVariableState.isLoaded === true && orgRoleStaffRoleHierarchyState.roleGroupId === GROUP_ROLES_ROLE_GROUP_ID) {
        const groupObj = groupAddState.objData;

        setFormState({
          ...formState,
          hasRegistration: groupObj.hasRegistration ?? '',
          requiresApproval: groupObj.requiresApproval ?? '',
          hasOpenRegistration: groupObj.hasOpenRegistration ?? '',
          hasRequiresApproval: getHasRequiresApproval(groupObj.groupTypeId, orgGroupTypesState.arrayData),
          hasOrgRoleRestriction: groupObj.hasOrgRoleRestriction ?? '',
          registrationCloseDate: groupObj.registrationCloseDate ? formatDate(groupObj.registrationCloseDate) : Constants.BLANK_DATE_STRING,
          registrationOpenDate: groupObj.registrationOpenDate ? formatDate(groupObj.registrationOpenDate) : Constants.BLANK_DATE_STRING,
          eligibleRoles: HierarchicalDataUtils.GetNameIdPairs(orgRoleStaffRoleHierarchyState.treeData, groupObj.orgGroupEligibility.map(x => x.orgRoleId))
        });

        setIsDirty(true);
      }
    } else {
      navigate(NavLinks.GROUP_ADD);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgRoleStaffRoleHierarchyState, orgGroupTypesState]);

  useEffect(() => {
    if (groupAddState.isSaved === true) {
      const orgGroupId = groupAddState.objData?.orgGroupId;

      navigate(NavLinks.GROUP_ADD_MEMBERS, { state: { orgGroupId } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupAddState.isSaved]);

  useEffect(() => {
    if (state.tryRedirect === true) { //After back button clicked
      const targetGroupTypeName = groupAddState.groupTypeName;

      switch (targetGroupTypeName) {
        case GROUP_CONSTANTS.GROUP_TYPE_NAME_LIST:
          navigate(NavLinks.GROUP_ADD_LIST);
          break;
        case GROUP_CONSTANTS.GROUP_TYPE_NAME_COMPETITOR_LIST:
          navigate(NavLinks.GROUP_ADD_COMPETITOR_LIST);
          break;
        case GROUP_CONSTANTS.GROUP_TYPE_NAME_CAMP:
          navigate(NavLinks.GROUP_ADD_CAMP);
          break;
        case GROUP_CONSTANTS.GROUP_TYPE_NAME_SAA:
          navigate(NavLinks.GROUP_ADD_SCHOLASTIC);
          break;
        case GROUP_CONSTANTS.GROUP_TYPE_NAME_NATIONAL_TEAM:
          navigate(NavLinks.GROUP_ADD_NATIONALTEAM);
          break;
        default:
          navigate(NavLinks.GROUP_ADD);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.tryRedirect]);

  function getHasRequiresApproval(groupTypeId, groupTypes) {
    const currentGroupType = groupTypes.find(x => x.groupTypeId === groupTypeId);

    if (currentGroupType) {
      return currentGroupType.hasRequiresApprovalOption === true;
    } else {
      return false;
    }
  };

  function submitFormCallback(formState) {
    const groupObj = JSON.parse(JSON.stringify(groupAddState.objData));

    if (groupObj.orgGroupFieldValue.length > 0) {
      for (const orgGroupField of groupObj.orgGroupFieldValue) {
        orgGroupField.groupTypeField = undefined;
      }
    }

    postGroup(createGroupObjFromGroupRegistrationForm(groupObj, formState));
  };

  function getInitialFormState() {
    return {
      hasRegistration: '',
      requiresApproval: '',
      hasOpenRegistration: '',
      hasRequiresApproval: false,
      hasOrgRoleRestriction: '',
      registrationCloseDate: Constants.BLANK_DATE_STRING,
      registrationOpenDate: Constants.BLANK_DATE_STRING,
      eligibleRoles: []
    };
  };

  function getInitialState() {
    return {
      tryRedirect: false
    };
  };

  return {
    isSaving: groupAddState.isSaving,
    isLoading: environmentVariableState.isLoading || orgGroupTypesState.isArrayLoading,
    formState,
    errorState,
    handleSubmit,
    onFormValueChanged,
    onBackClicked,
    groupTypeName: groupAddState.groupTypeName,
    GROUP_ROLES_ROLE_GROUP_ID: GROUP_ROLES_ROLE_GROUP_ID || Constants.DEFAULT_ID
  };
};

export default useGroupRegistrationAdd;