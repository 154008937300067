import React, { Fragment } from 'react';

import useSearchGroup from './UseSearchGroup';

import SearchGroupGrid from './SearchGroupGrid';

import Constants from '../../../utils/Constants';

import Input from '../../inputs/Input';
import PrimaryButton from '../..//buttons/PrimaryButton';
import SecondaryButton from '../../buttons/SecondaryButton';
import ActionIntraPageButton from '../../buttons/ActionIntraPageButton';
import Headings from '../../headings/Headings';
import OrgUnitTreeView from '../../tree/orgUnitTree/OrgUnitTreeView';
import OrgGroupCategoriesTreeView from '../../tree/orgGroupCategoriesTree/OrgGroupCategoriesTreeView';
import PopUpModal from '../../dialogs/PopUpModal';

import global from '../../GlobalStyle.module.css';

const Form = ({ handleSubmit, formState, errorState,
  onFormValueChanged, clearForm, onAddGroup, onCancelClicked }) => (
  <form onSubmit={handleSubmit} noValidate>
    <div className="row">
      <div className="col-xs-12 col-sm-6 col-md-4">
        <OrgUnitTreeView
          label="Group Owner"
          name="orgUnit"
          value={formState.orgUnit}
          error={errorState.orgUnit}
          message={errorState.orgUnit}
          selectableLeavesOnly={false}
          singleSelect={true}
          onChange={(newValue) => { onFormValueChanged('orgUnit', newValue) }}
          organizationId={undefined} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <OrgGroupCategoriesTreeView
          label="Group Category"
          name="groupCategories"
          value={formState.groupCategories}
          error={errorState.groupCategories}
          message={errorState.groupCategories}
          selectableLeavesOnly={false}
          singleSelect={false}
          onChange={(newValue) => { onFormValueChanged('groupCategories', newValue) }}
          groupCategoryLevel={'HQ' /*TODO env? */} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <Input
          label="Group Name"
          name="groupName"
          value={formState.groupName}
          error={errorState.groupName}
          message={errorState.groupName}
          onChange={(value) => { onFormValueChanged('groupName', value) }} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <Input
          label="Group Code"
          name="groupCode"
          value={formState.groupCode}
          error={errorState.groupCode}
          message={errorState.groupCode}
          onChange={(value) => { onFormValueChanged('groupCode', value) }} />
      </div>
    </div>
    {(errorState.filter || errorState.errorOnSubmitAction) &&
      <div className="row">
        <div className="col-xs-12">
          <p className={global.ErrorMessage}>{errorState.filter ? errorState.filter : ''}</p>
          <p className={global.ErrorMessage}>{errorState.errorOnSubmitAction || ''}</p>
        </div>
      </div>}
    <div className="row usas-extra-top-margin usas-extra-bottom-margin">
      <div className="col-xs-12 usas-extra-top-margin">
        <PrimaryButton type="submit">Search for Group</PrimaryButton>&nbsp;
        <SecondaryButton type="button" onClick={() => clearForm()}>Clear Form</SecondaryButton>&nbsp;
        {onAddGroup !== undefined ? <ActionIntraPageButton type="button" onClick={onAddGroup}>Add Group </ActionIntraPageButton> : <Fragment />}
        {onCancelClicked !== undefined ? <SecondaryButton type="button" onClick={() => onCancelClicked()}>Cancel</SecondaryButton> : <Fragment />}
      </div>
    </div>
  </form >
)

const SearchGroup = ({ pageTitle, onAddGroup, onEditGroup, searchGroupState, onCancelClicked }) => {
  const {
    formState,
    errorState,
    groupState,
    orgUnitState,
    orgGroupCategoriesState,
    clearForm,
    handleSubmit,
    onFormValueChanged,
    onFiltersToggle
  } = useSearchGroup();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>{pageTitle}</Headings.H3>
        </div>
      </div>
      {searchGroupState !== undefined ?
        //toggles filters and results
        <Fragment>
          <div className={searchGroupState.showFilters === true ? global.DisplayComponent : global.HideComponent}>
            <Form
              formState={formState}
              errorState={errorState}
              clearForm={clearForm}
              handleSubmit={handleSubmit}
              onFormValueChanged={onFormValueChanged}
              onAddGroup={onAddGroup}
              onCancelClicked={onCancelClicked}
            />
          </div>
          <div className="row usas-extra-bottom-margin">
            <div className="col-xs-12">
              <SearchGroupGrid
                groupState={groupState}
                searchGroupState={searchGroupState}
                onEditGroup={onEditGroup}
                onFiltersToggle={onFiltersToggle}
                onCancelClicked={onCancelClicked}
              />
            </div>
          </div>
          {onCancelClicked && searchGroupState.showFilters === false ?
            <Fragment>
              <div className="row usas-extra-top-margin usas-extra-bottom-margin">
                <div className="col-xs-12 usas-extra-top-margin">
                  <SecondaryButton type="button" onClick={() => onCancelClicked()}>Cancel</SecondaryButton>
                </div>
              </div>
            </Fragment> : <Fragment />}
        </Fragment>
        :
        //shows filters and results at the same time
        <Fragment>
          <Form
            formState={formState}
            errorState={errorState}
            clearForm={clearForm}
            handleSubmit={handleSubmit}
            onFormValueChanged={onFormValueChanged}
            onAddGroup={onAddGroup}
          />
          <div className="row usas-extra-bottom-margin">
            <div className="col-xs-12">
              <SearchGroupGrid
                groupState={groupState}
                onEditGroup={onEditGroup}
              />
            </div>
          </div>
        </Fragment>
      }
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={groupState.isArrayLoading || groupState.isObjLoading || orgUnitState.isArrayLoading || orgGroupCategoriesState.isArrayLoading} />
    </Fragment >
  )
}

export default SearchGroup;