import React, { Fragment } from 'react';

import YesNoSwitch from '../../../../../common/components/yesNoSwitch/YesNoSwitch';
import DatePicker from '../../../../../common/components/datepickers/DatePicker';
import OrgRoleStaffRoleTreeView from '../../../../../common/components/tree/orgRoleTree/OrgRoleStaffRoleTreeView';

import global from '../../../../../common/components/GlobalStyle.module.css';

const GroupRegistrationForm = ({ formState, errorState, onFormValueChanged, roleGroupId }) => (
  <Fragment>
    <div className='row'>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <YesNoSwitch
          label="Has Registration?*"
          name="hasRegistration"
          checked={formState.hasRegistration}
          error={errorState.hasRegistration}
          message={errorState.hasRegistration}
          onChange={(value) => { onFormValueChanged('hasRegistration', value) }} />
      </div>
      {formState.hasRequiresApproval === true &&
        <div className="col-xs-12 col-sm-6 col-md-4">
          <YesNoSwitch
            label="Requires Approval?*"
            name="requiresApproval"
            checked={formState.requiresApproval}
            error={errorState.requiresApproval}
            message={errorState.requiresApproval}
            onChange={(value) => { onFormValueChanged('requiresApproval', value) }} />
        </div>
      }
      <div className={formState.hasRegistration === true ? global.DisplayComponent : global.HideComponent}>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <YesNoSwitch
            label="Has Open Registration?*"
            name="hasOpenRegistration"
            checked={formState.hasOpenRegistration}
            error={errorState.hasOpenRegistration}
            message={errorState.hasOpenRegistration}
            onChange={(value) => { onFormValueChanged('hasOpenRegistration', value) }} />
        </div>
      </div>
      <div className={formState.hasRegistration === true && formState.hasOpenRegistration === true ? global.DisplayComponent : global.HideComponent}>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <YesNoSwitch
            label="Restrict Group Eligibility By Role?*"
            name="hasOrgRoleRestriction"
            checked={formState.hasOrgRoleRestriction}
            error={errorState.hasOrgRoleRestriction}
            message={errorState.hasOrgRoleRestriction}
            onChange={(value) => { onFormValueChanged('hasOrgRoleRestriction', value) }} />
        </div>
      </div>
      <div className={formState.hasRegistration === true ? global.DisplayComponent : global.HideComponent}>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <DatePicker
            label="Registration Start Date*"
            name="registrationOpenDate"
            value={formState.registrationOpenDate}
            error={errorState.registrationOpenDate}
            message={errorState.registrationOpenDate}
            onChange={(value) => { onFormValueChanged('registrationOpenDate', value) }}
            countOfYears={15}
            startYearOffset={-5} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <DatePicker
            label="Registration End Date*"
            name="registrationCloseDate"
            value={formState.registrationCloseDate}
            error={errorState.registrationCloseDate}
            message={errorState.registrationCloseDate}
            onChange={(value) => { onFormValueChanged('registrationCloseDate', value) }}
            countOfYears={15}
            startYearOffset={-5} />
        </div>
      </div>
      <div className={formState.hasRegistration === true && formState.hasOpenRegistration === true && formState.hasOrgRoleRestriction === true ? global.DisplayComponent : global.HideComponent}>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <OrgRoleStaffRoleTreeView
            label={'Allowed Roles*'}
            name={'eligibleRoles'}
            value={formState.eligibleRoles}
            error={errorState.eligibleRoles}
            message={errorState.eligibleRoles}
            singleSelect={false}
            onChange={(value) => onFormValueChanged('eligibleRoles', value)}
            roleGroupId={roleGroupId} />
        </div>
      </div>
    </div>
  </Fragment>
);

export default GroupRegistrationForm;