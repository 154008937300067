import useGroupData from "../../../../common/state/group/UseGroupData";
import useGroupFieldNamesData from "../../../../common/state/groupFieldNames/UseGroupFieldNamesData";

const useGroupAttributes = () => {
  const { GROUP_CONSTANTS } = useGroupFieldNamesData();
  const { groupState } = useGroupData();

  return {
    isLoaded: groupState.isObjLoaded,
    groupTypeName: groupState.objData.groupType?.typeName || '',
    GROUP_CONSTANTS
  };
};

export default useGroupAttributes;