import DeleteIcon from "../../../../../common/components/icons/DeleteIcon";
import EditIcon from "../../../../../common/components/icons/EditIcon";

import global from '../../../../../common/components/GlobalStyle.module.css';

const SmallGridRow = ({ member, onEditClicked, onDeleteClicked }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableRowHead}><span className="hidden-xs">{member.firstName || ''} {member.lastName || ''}</span>&nbsp;
      {onDeleteClicked &&
        <button
          className={global.IconButtonMobileMargin}
          type="button"
          onClick={() => onDeleteClicked(member)}>
          <DeleteIcon />
        </button>
      }
      {onEditClicked &&
        <button
          className={global.IconButtonMobileMargin}
          type="button"
          onClick={() => onEditClicked(member)}>
          <EditIcon />
        </button>
      }
    </div>
    <div className={global.SmallTableRowInfo}>
      <div className="row">
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>Name</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>{member.firstName || ''} {member.lastName || ''}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Member Id</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{member.memberId || ''}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Email</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{member.email || ''}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Potential/Confirmed</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{member.potentialOrConfirmed || ''}</div>
      </div>
    </div>
  </div>
);

const ListGroupMemberGridSmall = ({ data, isLoading, onEditClicked, onDeleteClicked }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {isLoading === true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>&nbsp;</div>
          <div className={global.SmallTableRowInfo}>
            <div className={global.SmallTableRowLabels}>Loading...</div>
          </div>
        </div>
      )
      : data.length > 0
        ? data.map((member, i) => <SmallGridRow key={i} member={member} onEditClicked={onEditClicked} onDeleteClicked={onDeleteClicked} />)
        : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>&nbsp;</div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Group Members</div>
            </div>
          </div>
        )
    }
  </div>
);

export default ListGroupMemberGridSmall;