import Checkbox from "../../../../../common/components/checkboxes/Checkbox";
import SGColumnHeader from "../../../../../common/components/grids/SGColumnHeader";

import SGConstants from "../../../../../common/utils/SGConstants";
import useSortableGrid from "../../../../../common/utils/UseSortableGrid";

import global from '../../../../../common/components/GlobalStyle.module.css';

const LargeGridRow = ({ member, selectedPersons, onCheckboxClicked }) => (
  <tr>
    <td>{member.firstName || ''} {member.lastName || ''}</td>
    <td>{member.eventName || ''}</td>
    <td>{member.swimTime || ''}</td>
    <td>{member.rank || ''}</td>
    <td>
      {onCheckboxClicked && (member.isSelected === true || !selectedPersons.some(x => x === member.personOrgGroupId)) &&
        <Checkbox
          label=""
          name="isSelected"
          onChange={() => { onCheckboxClicked(member.groupQualificationEventId, member.personOrgGroupId) }}
          checked={member.isSelected} />
      }
    </td>
  </tr>
);

const InviteGroupMemberGridLarge = ({ data, selectedPersons, isLoading, onCheckboxClicked }) => {
  const {
    sortableGridState,
    onColumnClicked
  } = useSortableGrid(data);

  return (
    <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
      <thead>
        <tr>
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Name'} columnField={'lastName'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Event'} columnField={'eventName'}
            sortType={SGConstants.SORT_TYPE_LENGTH} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Swim Time'} columnField={'swimTime'}
            sortType={SGConstants.SORT_TYPE_TIME} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Rank'} columnField={'rank'}
            sortType={SGConstants.SORT_TYPE_LENGTH} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Is Selected?'} columnField={'isSelected'}
            sortType={SGConstants.SORT_TYPE_NUMERICAL} onColumnClicked={onColumnClicked} />
        </tr>
      </thead>
      <tbody>
        {isLoading === true
          ? <tr><td colSpan="5">Loading...</td></tr>
          : sortableGridState.sortedGridData.length > 0
            ? sortableGridState.sortedGridData.map((member, i) => <LargeGridRow key={i} member={member} selectedPersons={selectedPersons} onCheckboxClicked={onCheckboxClicked} />)
            : <tr><td colSpan="5">No Alternate Athletes</td></tr>
        }
      </tbody>
    </table>
  );
};

export default InviteGroupMemberGridLarge;