import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from '../../../../../common/wrappers/ReactRouterDom';

import validate from './GroupMembersAddValidation';

import NavLinks from '../../NavLinks';

import useGroupCopyMembersData from '../../../../state/groupCopyMembers/UseGroupCopyMembersData';

import useSecurityData from '../../../../../common/state/security/UseSecurityData';

import useForm from "../../../../../common/utils/UseForm";
import Constants from '../../../../../common/utils/Constants';

const INITIAL_FORM_STATE = {
  orgGroupId: ''
};

const INITIAL_STATE = {
  orgGroupId: Constants.DEFAULT_ID
};

const useGroupMembersAdd = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { securityState } = useSecurityData();
  const { groupCopyMembersState, postGroupMembersCopy } = useGroupCopyMembersData();
  const { formState, errorState, isSubmitting, setFormData, updateFormState, handleSubmit
  } = useForm(INITIAL_FORM_STATE, onSubmitFormCallback, validate);
  const [state, setState] = useState(INITIAL_STATE);

  const onSkipClicked = () => {
    if (Array.isArray(securityState?.userInfo?.appRoutes)) {
      const appRoutes = securityState.userInfo.appRoutes;

      if (appRoutes.some(x => x.routeName === 'GROUP_SEARCH')) {
        navigate(NavLinks.GROUP_SEARCH);
      } else if (appRoutes.some(x => x.routeName === 'GROUP_MY_GROUPS')) {
        navigate(NavLinks.GROUP_MY_GROUPS);
      }
    }
  };

  useEffect(() => {
    if (location.state?.orgGroupId > 0) {
      setState({
        ...state,
        orgGroupId: location.state.orgGroupId
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state]);

  function onSubmitFormCallback(formState) {
    const postGroupMembersCopyPromise = postGroupMembersCopy(state.orgGroupId, formState.orgGroupId);

    if (postGroupMembersCopyPromise !== null) {
      postGroupMembersCopyPromise.then((newState) => {
        if (newState !== null) {
          //continue 
          onSkipClicked();
        }
      }).catch((e) => {
        console.log(e);
      });
    }
  };

  return {
    isLoading: isSubmitting || groupCopyMembersState.isObjLoading,
    formState,
    errorState,
    onFormValueChanged: updateFormState,
    handleSubmit,
    setFormData,
    onSkipClicked
  };
};

export default useGroupMembersAdd;