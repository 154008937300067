/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment } from 'react';

import EditIcon from '../../../common/components/icons/EditIcon';
import SGColumnHeader from '../../../common/components/grids/SGColumnHeader';

import useSortableGrid from '../../../common/utils/UseSortableGrid';
import SGConstants from '../../../common/utils/SGConstants';

import global from '../../../common/components/GlobalStyle.module.css';

const LargeGridRow = ({ group, onEditGroup }) => (
  <tr>
    <td><a onClick={(e) => onEditGroup(e, group)}>{group?.groupName || ''}</a></td>
    <td>{group?.groupCode || ''}</td>
    <td>{group?.orgUnitName || ''}</td>
    <td>{group?.groupCategory || ''}</td>
    <td>{group?.groupType || ''}</td>
  </tr>
);

const LargeGrid = ({ state, onEditGroup }) => {
  const {
    sortableGridState,
    onColumnClicked
  } = useSortableGrid(state.arrayData);

  return (
    <Fragment>
      <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
        <thead>
          <tr>
            <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Group Name'} columnField={'groupName'}
              sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
            <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Group Code'} columnField={'groupCode'}
              sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
            <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Group Owner'} columnField={'orgUnitName'}
              sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
            <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Group Category / Hierarchy'} columnField={'groupCategory'}
              sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
            <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Group Type'} columnField={'groupType'}
              sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          </tr>
        </thead>
        <tbody>
          {state.isArrayLoaded !== true
            ? (<Fragment>
              {state.isArrayLoading === true ?
                <tr><td colSpan="5">Loading...</td></tr> :
                <tr><td colSpan="5">No Results</td></tr>}
            </Fragment>)
            : Array.isArray(sortableGridState.sortedGridData) && sortableGridState.sortedGridData.length > 0
              ? sortableGridState.sortedGridData.map((group, i) => <LargeGridRow key={i} group={group} onEditGroup={onEditGroup} />)
              : <tr><td colSpan="5">No Results</td></tr>}
        </tbody>
      </table>
    </Fragment>
  );
};

const SmallGridRow = ({ group, onEditGroup }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableRowHead}><span className='hidden-xs'>{group?.groupName}</span>&nbsp;
      <button
        className={global.IconButton}
        type="button"
        onClick={(e) => onEditGroup(e, group)}>
        <EditIcon />
      </button>
    </div>
    <div className={global.SmallTableRowInfo}>
      <div className='row'>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 visible-xs'].join(' ')}>Group Name</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 visible-xs'].join(' ')}>{group?.groupName || <span>&nbsp;</span>}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6'].join(' ')}>Group Code</div>
        <div className={[global.SmallTableRowData, 'col-xs-6'].join(' ')}>{group?.groupCode || <span>&nbsp;</span>}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6'].join(' ')}>Group Owner</div>
        <div className={[global.SmallTableRowData, 'col-xs-6'].join(' ')}>{group?.orgUnitName || <span>&nbsp;</span>}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6'].join(' ')}>Group Category / Hierarchy</div>
        <div className={[global.SmallTableRowData, 'col-xs-6'].join(' ')}>{group?.groupCategory}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6'].join(' ')}>Group Type</div>
        <div className={[global.SmallTableRowData, 'col-xs-6'].join(' ')}>{group?.groupType}</div>
      </div>
    </div>
  </div>
);

const SmallGrid = ({ state, onEditGroup }) => (
  <Fragment>
    <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
      {state.isArrayLoaded !== true
        ? (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>
              &nbsp;
            </div>
            <div className={global.SmallTableRowInfo}>
              {state.isArrayLoading === true ?
                <div className={global.SmallTableRowLabels}>
                  Loading...
                </div> :
                <div className={global.SmallTableRowLabels}>
                  No Results
                </div>}
            </div>
          </div>
        ) : Array.isArray(state.arrayData) && state.arrayData.length > 0
          ? state.arrayData.map((group, i) => <SmallGridRow key={i} group={group} onEditGroup={onEditGroup} />)
          : (
            <div className={global.SmallTableRow}>
              <div className={global.SmallTableRowHead}>
                &nbsp;
              </div>
              <div className={global.SmallTableRowInfo}>
                <div className={global.SmallTableRowLabels}>
                  No Results
                </div>
              </div>
            </div>
          )}
    </div>
  </Fragment>
);

const SearchMyGroupsGrid = ({ groupState, searchGroupState, onEditGroup, onFiltersToggle }) => (
  <Fragment>
    {groupState.isArrayLoading === false
      ? <div className="row">
        <div className="col-xs-12 col-sm-10">
          {groupState.arrayData.length > 49
            ? <p className={global.ErrorMessage}>More than 50 Search Results returned. Please add additional filters to narrow your search results.</p>
            : <p>{groupState.arrayData.length === 1 ? `${groupState.arrayData.length} Search Result` : `${groupState.arrayData.length} Search Results`}</p>}
        </div>
        {onFiltersToggle ?
          <div className="col-xs-12 col-sm-2">
            <button className={global.ToggleButton} type="button" onClick={() => onFiltersToggle()}>{searchGroupState.showFilters === true ? 'Hide Filters' : 'Show Filters'}</button>
          </div> : <Fragment />}
      </div>
      :
      <Fragment>
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        </div>
      </Fragment >}
    <LargeGrid state={groupState} onEditGroup={onEditGroup} />
    <SmallGrid state={groupState} onEditGroup={onEditGroup} />
  </Fragment>
);

export default SearchMyGroupsGrid;