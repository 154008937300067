import { useContext } from 'react';

import { SearchGroupStateContext } from './SearchGroupContextProvider';

const useSearchGroupData = () => {
  const [searchGroupState, setSearchGroupState] = useContext(SearchGroupStateContext);

  return {
    searchGroupState,
    setSearchGroupState
  };
};

export default useSearchGroupData;