import React, { Fragment } from 'react';

import useGroupMembersAdd from './UseGroupMembersAdd';

import SearchGroupPopup from '../../components/dialogs/SearchGroupPopup';

import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';
import PrimaryButton from '../../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../../common/components/buttons/SecondaryButton';
import Headings from '../../../../../common/components/headings/Headings';

import Constants from '../../../../../common/utils/Constants';

import global from '../../../../../common/components/GlobalStyle.module.css';

const GroupMembersAdd = () => {
  const {
    isLoading,
    formState,
    errorState,
    handleSubmit,
    setFormData,
    onFormValueChanged,
    onSkipClicked
  } = useGroupMembersAdd();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Copy Group Members From Existing Group</Headings.H3>
        </div>
      </div>
      <form onSubmit={handleSubmit} noValidate>
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          <div className="col-xs-12 col-sm-6 col-md-4">
            <SearchGroupPopup
              viewFormState={formState}
              viewErrorState={errorState}
              setViewFormData={setFormData}
              onViewFormValueChanged={onFormValueChanged} />
          </div>
        </div>
        {errorState.apiError &&
          <div className="row">
            <div className="col-xs-12 usas-extra-top-margin">
              <p className={global.ErrorMessage}>{errorState.apiError}</p>
            </div>
          </div>
        }
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="submit">Copy Group Members</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onSkipClicked}>Skip</SecondaryButton>
          </div>
        </div>
      </form>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
    </Fragment>
  );
};

export default GroupMembersAdd;