import React, { Fragment } from 'react';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';

import SelectOrgUnit from '../../../../../common/components/selectOrgUnit/SelectOrgUnit';
import Constants from '../../../../../common/utils/Constants';

import useOrgUnitSelection from './UseOrgUnitSelection';

const OrgUnitSelection = () => {
  const {
    isLoaded,
    NavLinks,
    GROUP_SELECTOR_ROLES_ROLE_GROUP_ID
  } = useOrgUnitSelection();

  return (
    <Fragment>
      {isLoaded === true
        ? (
          <SelectOrgUnit
            pageTitle={'Add Group'}
            navLink={NavLinks.GROUP_ADD_SELECTION}
            roleGroupId={GROUP_SELECTOR_ROLES_ROLE_GROUP_ID} />
        )
        : (
          <PopUpModal
            widthPct={50}
            maxWidth={250}
            title={Constants.LOADING_MSG}
            displayPopUp={true} />
        )
      }
    </Fragment>
  );
};

export default OrgUnitSelection;