import { useContext } from 'react';

import { OrgGroupCategoriesStateContext } from './OrgGroupCategoriesContextProvider';
import OrgGroupCategoriesData from './OrgGroupCategoriesData';

const useOrgGroupCategoriesData = () => {
  const [orgGroupCategoriesState, setOrgGroupCategoriesState] = useContext(OrgGroupCategoriesStateContext);

  const getOrgGroupCategories = (level) => {
    OrgGroupCategoriesData.getOrgGroupCategories(level, orgGroupCategoriesState, setOrgGroupCategoriesState);
  };

  const getOrgGroupCategoriesH = (level) => {
    OrgGroupCategoriesData.getOrgGroupCategoriesH(level, orgGroupCategoriesState, setOrgGroupCategoriesState);
  };

  return {
    orgGroupCategoriesState,
    getOrgGroupCategories,
    getOrgGroupCategoriesH
  };
};

export default useOrgGroupCategoriesData;