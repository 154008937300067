import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from '../../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../../NavLinks';

import { createGroupObjFromGroupInfoForm } from '../../utils/GroupUtils';

import useGroupAddData from '../../../../state/groupAdd/UseGroupAddData';

import validate from '../../../../../common/components/forms/groups/GroupInfoFormValidation';

import useGroupCategoryData from '../../../../../common/state/groupCategory/UseGroupCategoryData';
import useSelectOrgUnitData from '../../../../../common/state/selectOrgUnit/UseSelectOrgUnitData';
import useSecurityData from '../../../../../common/state/security/UseSecurityData';

import useForm from '../../../../../common/utils/UseForm';
import Constants from '../../../../../common/utils/Constants';
import HierarchicalDataUtils from '../../../../../common/utils/HierarchicalDataUtils';

const useGroupListAdd = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { securityState } = useSecurityData();
  const { selectOrgUnitState } = useSelectOrgUnitData();
  const { groupAddState, updateGroupAddObj } = useGroupAddData();
  const { groupCategoryState } = useGroupCategoryData();
  const { formState, errorState, onFormValueChanged, handleSubmit, setFormState, setIsDirty
  } = useForm(getInitialFormState, submitFormCallback, validate);
  const [state, setState] = useState(getInitialState);

  const onBackClicked = () => {
    if (Array.isArray(securityState?.userInfo?.appRoutes)) {
      const appRoutes = securityState.userInfo.appRoutes;

      if (appRoutes.some(x => x.routeName === 'GROUP_SEARCH')) {
        navigate(NavLinks.GROUP_ADD_SELECTION);
      } else if (appRoutes.some(x => x.routeName === 'GROUP_MY_GROUPS')) {
        navigate(NavLinks.GROUP_MY_GROUPS);
      }
    }
  };

  useEffect(() => {
    if (groupCategoryState.isArrayLoaded === true && groupCategoryState.id === selectOrgUnitState.orgUnitId) {
      // On entry from group select type
      if (location.state?.groupTypeId && location.state?.groupTypeName && selectOrgUnitState.orgUnitId) {
        setFormState({
          ...formState,
          groupTypeId: location.state.groupTypeId,
          groupTypeName: location.state.groupTypeName,
          orgUnitId: selectOrgUnitState.orgUnitId
        });

        setIsDirty(true);
      } else if (groupAddState.isObjLoaded === true) { // On entry from a back button 
        const groupObj = groupAddState.objData;

        setFormState({
          ...formState,
          orgUnitId: groupObj.orgUnitId,
          groupTypeId: groupObj.groupTypeId,
          groupTypeName: groupAddState.groupTypeName,
          groupCategories: HierarchicalDataUtils.GetNameIdPairs(groupCategoryState.treeData, [groupObj.groupCategoryId]),
          groupName: groupObj.groupName,
          groupCode: groupObj.groupCode,
          groupDescription: groupObj.groupDescription
        });

        setIsDirty(true);
      } else {
        navigate(NavLinks.GROUP_ADD_SELECTION);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupCategoryState]);

  useEffect(() => {
    if (state.tryRedirect === true) { // After continue button clicked
      navigate(NavLinks.GROUP_ADD_REGISTRATION);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.tryRedirect]);

  function submitFormCallback(formState) {
    updateGroupAddObj(createGroupObjFromGroupInfoForm(groupAddState.objData, formState), formState.groupTypeName);

    setState({ ...state, tryRedirect: true });
  };

  function getInitialFormState() {
    return {
      orgUnitId: '',
      groupTypeId: Constants.DEFAULT_ID,
      groupTypeName: '',
      groupCategories: [],
      groupName: '',
      groupCode: '',
      groupDescription: ''
    };
  };

  function getInitialState() {
    return {
      tryRedirect: false
    };
  };

  return {
    isLoading: groupCategoryState.isArrayLoading,
    formState,
    errorState,
    handleSubmit,
    onFormValueChanged,
    onBackClicked,
    orgUnitId: selectOrgUnitState.orgUnitId
  };
};

export default useGroupListAdd;