import { useState } from "react";
import { BASIC_INITIAL_STATE } from "../../../common/utils/HttpHelper";

import GroupCopyMembersData from "./GroupCopyMembersData";

const INITIAL_GROUP_COPY_MEMBERS_STATE = {
  ...BASIC_INITIAL_STATE
};

const useGroupCopyMembersData = () => {
  const [groupCopyMembersState, setGroupCopyMembersState] = useState(INITIAL_GROUP_COPY_MEMBERS_STATE);

  const postGroupMembersCopy = (orgGroupId, copyOrgGroupId) => {
    return GroupCopyMembersData.postGroupMembersCopyData(orgGroupId, copyOrgGroupId, groupCopyMembersState, setGroupCopyMembersState);
  };

  return {
    groupCopyMembersState,
    postGroupMembersCopy
  };
};

export default useGroupCopyMembersData;