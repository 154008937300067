import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Navigate } from '../../../common/wrappers/ReactRouterDom';

import GroupAddContextProvider from '../../state/groupAdd/GroupAddContextProvider';

import LoadingModal from '../../../common/components/dialogs/LoadingModal';
import SecuredRouteElement from '../../../common/components/security/SecuredRouteElement';

import useNavRoutes from '../../../common/state/security/UseNavRoutes';
import GroupContextProvider from '../../../common/state/group/GroupContextProvider';
import GroupFiltersContextProvider from '../../../common/state/group/GroupFiltersContextProvider';
import SearchGroupContextProvider from '../../../common/state/searchGroup/SearchGroupContextProvider';
import BasePersonContextProvider from '../../../common/state/personBase/BasePersonContextProvider';
import BasePersonFiltersContextProvider from '../../../common/state/personBase/BasePersonFiltersContextProvider';
import OrgGroupTypesContextProvider from '../../../common/state/orgGroupTypes/OrgGroupTypesContextProvider';
import OrgGroupCategoriesContextProvider from '../../../common/state/orgGroupCategories/OrgGroupCategoriesContextProvider';
import OrgGroupPropertiesContextProvider from '../../../common/state/orgGroupProperties/OrgGroupPropertiesContextProvider';
import SearchMemberContextProvider from '../../../common/state/searchMember/SearchMemberContextProvider';
import OrgUnitContextProvider from '../../../common/state/orgUnit/OrgUnitContextProvider';
import OrgRoleHierarchyContextProvider from '../../../common/state/orgRoleHierarchy/OrgRoleHierarchyContextProvider';
import GroupCategoryContextProvider from '../../../common/state/groupCategory/GroupCategoryContextProvider';
import SelectOrgUnitContextProvider from '../../../common/state/selectOrgUnit/SelectOrgUnitContextProvider';
import PersonOrgRoleContextProvider from '../../../common/state/personOrgRole/PersonOrgRoleContextProvider';
import OrgRoleStaffRoleHierarchyContextProvider from '../../../common/state/orgRoleStaffRoleHierarchy/OrgRoleStaffRoleHierarchyContextProvider';
import GroupCoachesContextProvider from '../../../common/state/groupCoaches/GroupCoachesContextProvider';
import GroupFacilitiesContextProvider from '../../../common/state/groupFacilities/GroupFacilitiesContextProvider';
import LscContextProvider from '../../../common/state/lsc/LscContextProvider';
import GroupPersonCoachesContextProvider from '../../../common/state/groupPersonCoaches/GroupPersonCoachesContextProvider';

import GroupContextView from './GroupContextView';

import GroupSearch from './groupSearch/GroupSearch';
import OrgUnitSelection from './groupAdd/orgUnitSelection/OrgUnitSelection';
import GroupSelectType from './groupAdd/selectGroupType/GroupSelectType';
import GroupListAdd from './groupAdd/addList/GroupListAdd';
import GroupRegistrationAdd from './groupAdd/addRegistration/GroupRegistrationAdd';
import GroupCompetitorListAdd from './groupAdd/addCompetitorList/GroupCompetitorListAdd';
import GroupCampAdd from './groupAdd/addCamp/GroupCampAdd';
import GroupNationalTeamAdd from './groupAdd/addNationalTeam/GroupNationalTeamAdd';
import GroupSAAAdd from './groupAdd/addSAA/GroupSAAAdd';
import GroupInfo from './groupInfo/GroupInfo';
import GroupAttributes from './groupAttributes/GroupAttributes';
import GroupRegistration from './groupRegistration/GroupRegistration';
import GroupMembers from './groupMembers/GroupMembers';
import GroupMemberAdd from './groupMembers/addMember/GroupMemberAdd';
import GroupMemberImport from './groupMemberImport/GroupMemberImport';
import GroupInviteAlternateMembers from './groupMembers/inviteAlternateMembers/GroupInviteAlternateMembers';
import GroupInviteAlternateMembersIMX from './groupMembers/inviteAlternateMembersIMX/GroupInviteAlternateMembersIMX';
import GroupSelection from './groupSelection/GroupSelection';
import GroupMyGroups from './groupMyGroups/GroupMyGroups';
import GroupMemberEdit from './groupMembers/editMember/GroupMemberEdit';
import GroupMembersAdd from './groupAdd/addMembers/GroupMembersAdd';
import GroupReporting from './groupReporting/GroupReporting';

const ContextRoutes = ({ navRoutes }) => (
  <GroupContextView>
    <Routes>
      <Route path={navRoutes['GROUP_INFO']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['GROUP_INFO']}
            editElement={<GroupInfo />} />
        } />
      <Route path={navRoutes['GROUP_ATTRIBUTES']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['GROUP_ATTRIBUTES']}
            editElement={<GroupAttributes />} />
        } />
      <Route path={navRoutes['GROUP_REGISTRATION']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['GROUP_REGISTRATION']}
            editElement={<GroupRegistration />} />
        } />
      <Route path={navRoutes['GROUP_MEMBERS']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['GROUP_MEMBERS']}
            editElement={<GroupMembers />} />
        } />
      <Route path={navRoutes['GROUP_MEMBERS_ADD']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['GROUP_MEMBERS_ADD']}
            editElement={<GroupMemberAdd />} />
        } />
      <Route path={navRoutes['GROUP_MEMBERS_EDIT']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['GROUP_MEMBERS_EDIT']}
            editElement={<GroupMemberEdit />} />
        } />
      <Route path={navRoutes['GROUP_MEMBERS_INVITE_ALTERNATE']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['GROUP_MEMBERS_INVITE_ALTERNATE']}
            editElement={<GroupInviteAlternateMembers />} />
        } />
      <Route path={navRoutes['GROUP_MEMBERS_INVITE_ALTERNATE_IMX']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['GROUP_MEMBERS_INVITE_ALTERNATE_IMX']}
            editElement={<GroupInviteAlternateMembersIMX />} />
        } />
      <Route path={navRoutes['GROUP_MEMBER_IMPORT']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['GROUP_MEMBER_IMPORT']}
            editElement={<GroupMemberImport />} />
        } />
      <Route path={navRoutes['GROUP_REPORTING']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['GROUP_REPORTING']}
            editElement={<GroupReporting />} />
        } />
    </Routes>
  </GroupContextView>
);

const GroupRoutes = () => {
  const { navRoutes, isReadyToRoute } = useNavRoutes();

  if (isReadyToRoute !== true) {
    return <LoadingModal />;
  }

  return (
    <Routes>
      <Route path={navRoutes['GROUP_SEARCH']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['GROUP_SEARCH']}
            editElement={<GroupSearch />} />
        } />
      <Route path={navRoutes['GROUP_SELECTION']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['GROUP_SELECTION']}
            editElement={<GroupSelection />} />
        } />
      <Route path={navRoutes['GROUP_MY_GROUPS']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['GROUP_MY_GROUPS']}
            editElement={<GroupMyGroups />} />
        } />
      <Route path={navRoutes['GROUP_ADD']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['GROUP_ADD']}
            editElement={<OrgUnitSelection />} />
        } />
      <Route path={navRoutes['GROUP_ADD_SELECTION']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['GROUP_ADD_SELECTION']}
            editElement={<GroupSelectType />} />
        } />
      <Route path={navRoutes['GROUP_ADD_LIST']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['GROUP_ADD_LIST']}
            editElement={<GroupListAdd />} />
        } />
      <Route path={navRoutes['GROUP_ADD_COMPETITOR_LIST']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['GROUP_ADD_COMPETITOR_LIST']}
            editElement={<GroupCompetitorListAdd />} />
        } />
      <Route path={navRoutes['GROUP_ADD_CAMP']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['GROUP_ADD_CAMP']}
            editElement={<GroupCampAdd />} />
        } />
      <Route path={navRoutes['GROUP_ADD_SCHOLASTIC']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['GROUP_ADD_SCHOLASTIC']}
            editElement={<GroupSAAAdd />} />
        } />
      <Route path={navRoutes['GROUP_ADD_NATIONALTEAM']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['GROUP_ADD_NATIONALTEAM']}
            editElement={<GroupNationalTeamAdd />} />
        } />
      <Route path={navRoutes['GROUP_ADD_REGISTRATION']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['GROUP_ADD_REGISTRATION']}
            editElement={<GroupRegistrationAdd />} />
        } />
      <Route path={navRoutes['GROUP_ADD_MEMBERS']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['GROUP_ADD_MEMBERS']}
            editElement={<GroupMembersAdd />} />
        } />
      <Route path={'/*'} element={<ContextRoutes navRoutes={navRoutes} />} />
      <Route path={'/'} element={<Navigate to={navRoutes['GROUP_SEARCH']?.route} replace />} />
    </Routes>
  );
};

const Group = () => (
  <GroupContextProvider>
    <GroupFiltersContextProvider>
      <SearchGroupContextProvider>
        <GroupAddContextProvider>
          <OrgRoleStaffRoleHierarchyContextProvider>
            <LscContextProvider>
              <BasePersonContextProvider>
                <BasePersonFiltersContextProvider>
                  <SearchMemberContextProvider>
                    <OrgUnitContextProvider>
                      <OrgRoleHierarchyContextProvider>
                        <SelectOrgUnitContextProvider>
                          <PersonOrgRoleContextProvider>
                            <OrgGroupTypesContextProvider>
                              <OrgGroupCategoriesContextProvider>
                                <GroupCategoryContextProvider>
                                  <GroupCoachesContextProvider>
                                    <GroupPersonCoachesContextProvider>
                                      <GroupFacilitiesContextProvider>
                                        <OrgGroupPropertiesContextProvider>
                                          <GroupRoutes />
                                        </OrgGroupPropertiesContextProvider>
                                      </GroupFacilitiesContextProvider>
                                    </GroupPersonCoachesContextProvider>
                                  </GroupCoachesContextProvider>
                                </GroupCategoryContextProvider>
                              </OrgGroupCategoriesContextProvider>
                            </OrgGroupTypesContextProvider>
                          </PersonOrgRoleContextProvider>
                        </SelectOrgUnitContextProvider>
                      </OrgRoleHierarchyContextProvider>
                    </OrgUnitContextProvider>
                  </SearchMemberContextProvider>
                </BasePersonFiltersContextProvider>
              </BasePersonContextProvider>
            </LscContextProvider>
          </OrgRoleStaffRoleHierarchyContextProvider>
        </GroupAddContextProvider>
      </SearchGroupContextProvider>
    </GroupFiltersContextProvider>
  </GroupContextProvider>
);

export default Group;