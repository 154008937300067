import React, { Fragment } from 'react';

import useGroupRegistration from './UseGroupRegistration';

import GroupRegistrationForm from '../components/forms/GroupRegistrationForm';

import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import Headings from '../../../../common/components/headings/Headings';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';

import Constants from '../../../../common/utils/Constants';

import global from '../../../../common/components/GlobalStyle.module.css';

const GroupRegistration = () => {
  const {
    isLoading,
    isSaving,
    formState,
    errorState,
    handleSubmit,
    onFormValueChanged,
    onCancelClicked,
    GROUP_ROLES_ROLE_GROUP_ID
  } = useGroupRegistration();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Group Registration</Headings.H3>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <GroupRegistrationForm
          formState={formState}
          errorState={errorState}
          onFormValueChanged={onFormValueChanged}
          roleGroupId={GROUP_ROLES_ROLE_GROUP_ID} />
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
            <PrimaryButton type="submit">Save</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onCancelClicked}>Cancel</SecondaryButton>
            {errorState && errorState.errorOnSubmitAction
              && <p className={global.ErrorMessage}>{errorState.errorOnSubmitAction}</p>
            }
          </div>
        </div>
      </form>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={isSaving} />
    </Fragment>
  );
};

export default GroupRegistration;