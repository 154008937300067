import React, { createContext, useState } from 'react';

import SearchGroupData from './SearchGroupData';

export const SearchGroupStateContext = createContext();

const SearchGroupContextProvider = ({ children }) => {
  const stateHook = useState(SearchGroupData.INITIAL_STATE);

  return (
    <SearchGroupStateContext.Provider value={stateHook}>
      {children}
    </SearchGroupStateContext.Provider>
  );
};

export default SearchGroupContextProvider;