import { useState } from 'react';

import GroupLookupData from './GroupLookupData';

import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const useGroupLookupData = () => {
  const [groupLookupState, setGroupLookupState] = useState(INITIAL_STATE);

  const searchGroupByGroupCode = (groupCode) => {
    const filterObject = {
      groupCode
    };

    return GroupLookupData.searchGroupByGroupCodeData(JSON.stringify(filterObject), groupLookupState, setGroupLookupState);
  };

  return {
    groupLookupState,
    searchGroupByGroupCode
  };
};

export default useGroupLookupData;