import { useState } from 'react';

import validate from './SearchGroupPopupValidation';

import useGroupLookupData from '../../../../state/groupLookup/UseGroupLookupData';

import UseForm from '../../../../../common/utils/UseForm';

const INITIAL_FORM_STATE = {
  groupCode: ''
};

const INITIAL_STATE = {
  displayPopup: false
};

const useSearchGroupPopup = (viewFormState, setViewFormData, orgGroupIdKey) => {
  const { groupLookupState, searchGroupByGroupCode } = useGroupLookupData();
  const { formState, errorState, updateFormState, handleSubmit
  } = UseForm(INITIAL_FORM_STATE, onSubmitFormCallback, validate);
  const [state, setState] = useState(INITIAL_STATE);

  const onDisplayPopupClicked = () => {
    setState({ ...state, displayPopup: true });
  };

  const onHidePopupClicked = () => {
    setState({ ...state, displayPopup: false });
  };

  const onSelectGroupClicked = (orgGroupId) => {
    setViewFormData({ ...viewFormState, [orgGroupIdKey]: orgGroupId }, true);
    setState({ ...state, displayPopup: false });
  };

  function onSubmitFormCallback(formState) {
    searchGroupByGroupCode(formState.groupCode);
  }

  return {
    state,
    formState,
    errorState,
    groupLookupState,
    onFormValueChanged: updateFormState,
    handleSubmit,
    onDisplayPopupClicked,
    onHidePopupClicked,
    onSelectGroupClicked
  };
};

export default useSearchGroupPopup;