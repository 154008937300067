import React, { Fragment } from 'react';

import useGroupMemberImport from './UseGroupMemberImport';

import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import Headings from '../../../../common/components/headings/Headings';

import Constants from '../../../../common/utils/Constants';

import global from '../../../../common/components/GlobalStyle.module.css';

const GroupMemberImport = () => {
  const {
    isSaving,
    groupMemberUploadState,
    memberIdArrayState,
    fileState,
    errorState,
    onLoadFile,
    onDownloadJSON,
    onUploadMemberIds
  } = useGroupMemberImport();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Group Member Import</Headings.H3>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
          <label htmlFor="member-id-select-file" className={global.FileUpload}>
            Select A File
          </label>
          <input hidden id="member-id-select-file" type="file" onChange={(e) => onLoadFile(e)} />
          &nbsp;(*.csv)
        </div>
        {errorState && <div className="col-xs-12"><p className={global.ErrorMessage}>{errorState}</p></div>}
      </div>
      {!errorState && fileState.size > 0 &&
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
            <p><b>Member Ids Found:</b> {memberIdArrayState.length}</p>
          </div>
          {memberIdArrayState.length === 0 &&
            <div className="col-xs-12"><p className={global.ErrorMessage}>No Member Ids were detected inside {fileState.name}</p></div>
          }
        </div>
      }
      {!errorState && groupMemberUploadState.isSaved !== true && fileState.size > 0 && memberIdArrayState.length > 0 &&
        <div className='row'>
          <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
            <SecondaryButton type="button" onClick={onDownloadJSON}>Download JSON File</SecondaryButton>&nbsp;
            <PrimaryButton type="button" onClick={onUploadMemberIds}>Upload Member Ids</PrimaryButton>
          </div>
        </div>
      }
      {groupMemberUploadState.isSaved === true &&
        <div className='row'>
          <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
            <p><b>Upload Status:</b> {groupMemberUploadState.objData?.result}</p>
            {groupMemberUploadState.objData?.membersAlreadyInGroupCount > 0 &&
              <p><b>Members Already In Group:</b> {groupMemberUploadState.objData?.membersAlreadyInGroupCount}</p>
            }
            <p><b>Members Added To Group:</b> {groupMemberUploadState.objData?.membersInsertCount}</p>
          </div>
        </div>
      }
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.UPLOADING_MSG}
        displayPopUp={isSaving} />
    </Fragment >
  );
};

export default GroupMemberImport;