import React, { Fragment } from 'react';

import useGroupNationalTeamAttributes from './UseGroupNationalTeamAttributes';

import NationalTeamAttributesForm from '../../components/forms/NationalTeamAttributesForm';

import PrimaryButton from '../../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../../common/components/buttons/SecondaryButton';
import Headings from '../../../../../common/components/headings/Headings';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';

import Constants from '../../../../../common/utils/Constants';

import global from '../../../../../common/components/GlobalStyle.module.css';

const GroupNationalTeamAttributes = () => {
  const {
    isLoading,
    isSaving,
    formState,
    errorState,
    handleSubmit,
    onFormValueChanged,
    onCancelClicked
  } = useGroupNationalTeamAttributes();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>National Team Group Attributes</Headings.H3>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <NationalTeamAttributesForm
          formState={formState}
          errorState={errorState}
          onFormValueChanged={onFormValueChanged} />
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
            <PrimaryButton type="submit">Save</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onCancelClicked}>Cancel</SecondaryButton>
            {errorState && errorState.errorOnSubmitAction
              && <p className={global.ErrorMessage}>{errorState.errorOnSubmitAction}</p>
            }
          </div>
        </div>
      </form>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={isSaving} />
    </Fragment>
  );
};

export default GroupNationalTeamAttributes;