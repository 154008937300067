import React, { Fragment } from 'react';

import useGroupClubSiteMembers from './UseGroupClubSiteMembers';

import DefaultGroupMemberGrid from '../../components/grids/DefaultGroupMemberGrid';

import Headings from '../../../../../common/components/headings/Headings';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';
import ActionIntraPageButton from '../../../../../common/components/buttons/ActionIntraPageButton';
import PrimaryButton from '../../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../../common/components/buttons/SecondaryButton';

import Constants from '../../../../../common/utils/Constants';

const GroupClubSiteMembers = () => {
  const {
    isLoading,
    isSaving,
    gridState,
    modalState,
    onAddMemberToGroupClicked,
    onDeleteGroupMember,
    onDeleteModalConfirmClicked,
    onDeleteModalCancelClicked
  } = useGroupClubSiteMembers();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Club Site Group Members</Headings.H3>
        </div>
      </div>
      <div className="row">
        <div className='col-xs-12 usas-extra-top-margin'>
          <ActionIntraPageButton onClick={onAddMemberToGroupClicked}>Add Member to Group</ActionIntraPageButton>
        </div>
      </div>
      <div className='row'>
        <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
          <DefaultGroupMemberGrid
            data={gridState.arrayData}
            isLoading={gridState.isArrayLoading}
            onDeleteClicked={onDeleteGroupMember} />
        </div>
      </div>
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={'Remove Group Member?'}
        displayPopUp={modalState.displayModal}
        onModalCanceled={onDeleteModalCancelClicked}>
        <div className="row">
          <div className="col-xs-12">
            <p><b>Member:</b> {modalState.firstName} {modalState.lastName}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={onDeleteModalConfirmClicked}>Remove</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onDeleteModalCancelClicked}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={isSaving} />
    </Fragment>
  );
};

export default GroupClubSiteMembers;