import Checkbox from "../../../../../common/components/checkboxes/Checkbox";
import SGColumnHeader from "../../../../../common/components/grids/SGColumnHeader";

import SGConstants from "../../../../../common/utils/SGConstants";
import useSortableGrid from "../../../../../common/utils/UseSortableGrid";

import global from '../../../../../common/components/GlobalStyle.module.css';

const LargeGridRow = ({ member, onCheckboxClicked }) => (
  <tr>
    <td>{member.firstName || ''} {member.lastName || ''}</td>
    <td>{member.imxScore || ''}</td>
    <td>
      {onCheckboxClicked &&
        <Checkbox
          label=""
          name="isSelected"
          onChange={() => { onCheckboxClicked(member.groupQualificationImxId) }}
          checked={member.isSelected} />
      }
    </td>
  </tr>
);

const InviteGroupMemberIMXGridLarge = ({ data, isLoading, onCheckboxClicked }) => {
  const {
    sortableGridState,
    onColumnClicked
  } = useSortableGrid(data);

  return (
    <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
      <thead>
        <tr>
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Name'} columnField={'lastName'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'IMX Score'} columnField={'imxScore'}
            sortType={SGConstants.SORT_TYPE_NUMERICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Is Selected?'} columnField={'isSelected'}
            sortType={SGConstants.SORT_TYPE_NUMERICAL} onColumnClicked={onColumnClicked} />
        </tr>
      </thead>
      <tbody>
        {isLoading === true
          ? <tr><td colSpan="3">Loading...</td></tr>
          : sortableGridState.sortedGridData.length > 0
            ? sortableGridState.sortedGridData.map((member, i) => <LargeGridRow key={i} member={member} onCheckboxClicked={onCheckboxClicked} />)
            : <tr><td colSpan="3">No Alternate Athletes</td></tr>
        }
      </tbody>
    </table>
  );
};

export default InviteGroupMemberIMXGridLarge;