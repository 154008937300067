import Constants from '../../../../../common/utils/Constants';
import { isValidDate } from '../../../../../common/utils/validation';

const GroupRegistrationFormValidation = (formState) => {
  let errors = {};

  if (formState.hasRegistration !== true && formState.hasRegistration !== false) {
    errors.hasRegistration = 'Must select yes or no for this field';
  }

  if (formState.hasRequiresApproval === true) {
    if (formState.requiresApproval !== true && formState.requiresApproval !== false) {
      errors.requiresApproval = 'Must select yes or no for this field';
    }
  }

  if (formState.hasRegistration === true) {
    if (formState.hasOpenRegistration !== true && formState.hasOpenRegistration !== false) {
      errors.hasOpenRegistration = 'Must select yes or no for this field';
    }
  }

  if (formState.hasRegistration === true && formState.hasOpenRegistration === true) {
    if (formState.hasOrgRoleRestriction !== true && formState.hasOrgRoleRestriction !== false) {
      errors.hasOrgRoleRestriction = 'Must select yes or no for this field';
    }
  }

  if (formState.hasRegistration === true) {
    const registrationOpenDate = new Date(formState.registrationOpenDate);
    const registrationCloseDate = new Date(formState.registrationCloseDate);

    if (formState.registrationOpenDate === Constants.BLANK_DATE_STRING || formState.registrationOpenDate === '') {
      errors.registrationOpenDate = 'Registration Start Date is required';
    } else if (!isValidDate(formState.registrationOpenDate)) {
      errors.registrationOpenDate = 'Registration Start Date must be a valid date';
    } else if (registrationCloseDate < registrationOpenDate || formState.registrationOpenDate === formState.registrationCloseDate) {
      errors.registrationOpenDate = 'Registration Start Date must be before the Registration End Date';
    }

    if (formState.registrationCloseDate === Constants.BLANK_DATE_STRING || formState.registrationCloseDate === '') {
      errors.registrationCloseDate = 'Registration End Date is required';
    } else if (!isValidDate(formState.registrationCloseDate)) {
      errors.registrationCloseDate = 'Registration End Date must be a valid date';
    } else if (registrationCloseDate < registrationOpenDate || formState.registrationOpenDate === formState.registrationCloseDate) {
      errors.registrationCloseDate = 'Registration End Date must be after the Registration Start Date';
    }
  }

  if (formState.hasRegistration === true && formState.hasOpenRegistration === true && formState.hasOrgRoleRestriction === true) {
    if (formState.eligibleRoles.length === 0) {
      errors.eligibleRoles = 'Must select at least one Allowed Role'
    }
  }

  return errors;
};

export default GroupRegistrationFormValidation;