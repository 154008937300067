import React, { Fragment } from 'react';

import useGroupSelection from './UseGroupSelection';

import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import SelectOrgUnit from '../../../../common/components/selectOrgUnit/SelectOrgUnit';

import Constants from '../../../../common/utils/Constants';

const GroupSelection = () => {
  const {
    isLoaded,
    NavLinks,
    GROUP_SELECTOR_ROLES_ROLE_GROUP_ID
  } = useGroupSelection();

  return (
    <Fragment>
      {isLoaded === true
        ? (
          <SelectOrgUnit
            pageTitle={'My Groups'}
            navLink={NavLinks.GROUP_MY_GROUPS}
            roleGroupId={GROUP_SELECTOR_ROLES_ROLE_GROUP_ID} />
        )
        : (
          <PopUpModal
            widthPct={50}
            maxWidth={250}
            title={Constants.LOADING_MSG}
            displayPopUp={true} />
        )
      }
    </Fragment>
  );
};

export default GroupSelection;