import { useEffect, useState } from 'react';

import validate from './SearchMyGroupsValidation';

import useGroupData from '../../../common/state/group/UseGroupData';
import useSearchGroupData from '../../../common/state/searchGroup/UseSearchGroupData';
import useGroupCategoryData from '../../../common/state/groupCategory/UseGroupCategoryData';

import useForm from '../../../common/utils/UseForm';
import HierarchicalDataUtils from '../../../common/utils/HierarchicalDataUtils';

const useSearchMyGroups = (selectOrgUnitState) => {
  const { groupState, searchGroups, groupFiltersState } = useGroupData();
  const { searchGroupState, setSearchGroupState } = useSearchGroupData();
  const { groupCategoryState } = useGroupCategoryData();
  const { formState, errorState, handleSubmit, updateFormState, setFormState, setErrors,
    setIsDirty, isDirty } = useForm(getInitialFormState(), submitFormCallback, validate);
  const [state, setState] = useState(getInitialState);

  const clearForm = () => {
    setFormState(getInitialFormState());
    setErrors({});
  };

  const onFiltersToggle = () => {
    setSearchGroupState({ ...searchGroupState, showFilters: !searchGroupState.showFilters });
    setIsDirty(true);
  };

  useEffect(() => {
    if (Object.keys(groupFiltersState.filterObject).length > 0 && groupCategoryState.isArrayLoaded === true) {
      const oldSearchValues = JSON.parse(JSON.stringify(groupFiltersState.filterObject));

      setFormState({
        ...formState,
        ...oldSearchValues,
        groupCategories: HierarchicalDataUtils.GetNameIdPairs(groupCategoryState.treeData, oldSearchValues.groupCategoryId) || [],
      });
      setIsDirty(true);

      if (groupState.isArrayLoading === false && state.isInitialLoad === true) {
        searchGroups(groupFiltersState.filterObject);

        setState({
          ...state,
          isInitialLoad: false
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupCategoryState]);

  useEffect(() => {
    if (searchGroupState.showFilters === true && isDirty === false) {
      setIsDirty(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchGroupState.showFilters, isDirty]);

  useEffect(() => {
    // initial search on page load
    if (Object.keys(groupFiltersState.filterObject).length === 0 && selectOrgUnitState.orgUnitId) {
      searchGroups(createSearchFilterObj(formState));

      setState({
        ...state,
        isInitialLoad: false
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function submitFormCallback(formState) {
    setSearchGroupState({ ...searchGroupState, tryRedirect: false });

    if (groupState.isArrayLoading === false) {
      const filterObject = createSearchFilterObj(formState);
      searchGroups(filterObject);
    }
  };

  function createSearchFilterObj(formState) {
    return {
      orgUnitId: selectOrgUnitState?.orgUnitId || undefined,
      groupCategoryId: formState.groupCategories.length > 0 ? formState.groupCategories.map(x => x.id) : undefined,
      groupName: formState.groupName ? formState.groupName.trim() : undefined,
      groupCode: formState.groupCode ? formState.groupCode.trim() : undefined
    };
  };

  function getInitialFormState() {
    return {
      groupCategories: [],
      groupName: '',
      groupCode: ''
    };
  };

  function getInitialState() {
    return {
      isInitialLoad: true
    };
  };

  return {
    formState,
    errorState,
    groupState,
    searchGroupState,
    setSearchGroupState,
    groupCategoryState,
    clearForm,
    onFormValueChanged: updateFormState,
    handleSubmit,
    setIsDirty,
    onFiltersToggle
  };
};

export default useSearchMyGroups;