import { useState } from "react";

import GroupMemberUploadData from "./GroupMemberUploadData";

const useGroupMemberUploadData = () => {
  const [groupMemberUploadState, setGroupMemberUploadState] = useState(GroupMemberUploadData.INITIAL_STATE);

  const postGroupMemberIds = (orgGroupId, memberIds) => GroupMemberUploadData.postGroupMemberIds(orgGroupId, memberIds, groupMemberUploadState, setGroupMemberUploadState);

  const confirmSave = () => {
    setGroupMemberUploadState({
      ...groupMemberUploadState,
      isSaved: false
    });
  };

  return {
    groupMemberUploadState,
    postGroupMemberIds,
    confirmSave
  };
};

export default useGroupMemberUploadData;