import React, { Fragment } from 'react';

const AppStateContextProviders = ({ children }) => {
  return (
    <Fragment>
      {children}
    </Fragment>
  );
};

export default AppStateContextProviders;