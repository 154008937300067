import { useEffect, useState } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../NavLinks';

import { createGroupObjFromGroupInfoForm } from '../utils/GroupUtils';

import validate from '../../../../common/components/forms/groups/GroupInfoFormValidation';

import useGroupCategoryData from '../../../../common/state/groupCategory/UseGroupCategoryData';
import useGroupData from '../../../../common/state/group/UseGroupData';
import useSecurityData from '../../../../common/state/security/UseSecurityData';

import useForm from '../../../../common/utils/UseForm';
import HierarchicalDataUtils from '../../../../common/utils/HierarchicalDataUtils';
import Constants from '../../../../common/utils/Constants';

const INITIAL_MODAL_STATE = {
  displayModal: false,
  deleteGroup: false,
  groupName: '',
  groupType: '',
  groupCategory: ''
};

const useGroupInfo = () => {
  const navigate = useNavigate();
  const { groupCategoryState } = useGroupCategoryData();
  const { groupState, putGroup, deleteGroup, clearObjData } = useGroupData();
  const { securityState } = useSecurityData();
  const { formState, errorState, onFormValueChanged, handleSubmit, setFormData, resetForm
  } = useForm(getInitialFormState, submitFormCallback, validate);
  const [modalState, setModalState] = useState(INITIAL_MODAL_STATE);

  const onDeleteGroupClicked = () => {
    const groupObj = groupState.objData;

    setModalState({
      ...modalState,
      displayModal: true,
      groupName: groupObj.groupName || '',
      groupType: groupObj.groupType?.typeName || '',
      groupCategory: groupObj.groupCategory?.categoryName || ''
    });
  };

  const onDeleteModalConfirmClicked = () => {
    deleteGroup(groupState.objData.orgGroupId);

    setModalState({
      ...modalState,
      displayModal: false,
      deleteGroup: true
    });
  };

  const onDeleteModalCancelClicked = () => {
    setModalState(INITIAL_MODAL_STATE);
  };

  useEffect(() => {
    if (groupCategoryState.isArrayLoaded === true && groupCategoryState.id === groupState.objData.orgUnitId
      && groupState.isObjLoaded === true) {
      const groupObj = groupState.objData;

      setFormData({
        ...formState,
        orgUnitId: groupObj.orgUnitId,
        groupTypeId: groupObj.groupTypeId,
        groupTypeName: groupObj.groupType?.typeName || '',
        groupCategories: HierarchicalDataUtils.GetNameIdPairs(groupCategoryState.treeData, [groupObj.groupCategoryId]),
        groupName: groupObj.groupName,
        groupCode: groupObj.groupCode,
        groupDescription: groupObj.groupDescription
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupCategoryState, groupState]);

  useEffect(() => {
    if (modalState.deleteGroup === true && groupState.isSaving === false && groupState.isSaved === true) {
      if (Array.isArray(securityState?.userInfo?.appRoutes)) {
        const appRoutes = securityState.userInfo.appRoutes;

        if (appRoutes.some(x => x.routeName === 'GROUP_SEARCH')) {
          goToGroupSearch();
        } else if (appRoutes.some(x => x.routeName === 'GROUP_MY_GROUPS')) {
          goToGroupMyGroups();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalState.deleteGroup, groupState]);

  function submitFormCallback(formState) {
    if (groupState.isSaving === false) {
      putGroup(groupState.objData.orgGroupId, createGroupObjFromGroupInfoForm(groupState.objData, formState));
    }
  };

  function goToGroupSearch() {
    clearObjData();
    navigate(NavLinks.GROUP_SEARCH);
  };

  function goToGroupMyGroups() {
    clearObjData();
    navigate(NavLinks.GROUP_MY_GROUPS);
  };

  function getInitialFormState() {
    return {
      orgUnitId: '',
      groupTypeId: Constants.DEFAULT_ID,
      groupTypeName: '',
      groupCategories: [],
      groupName: '',
      groupCode: '',
      groupDescription: ''
    };
  };

  return {
    isLoading: groupCategoryState.isArrayLoading || groupState.isObjLoading,
    isSaving: groupState.isSaving,
    formState,
    errorState,
    modalState,
    handleSubmit,
    onFormValueChanged,
    onCancelClicked: resetForm,
    onDeleteGroupClicked,
    onDeleteModalConfirmClicked,
    onDeleteModalCancelClicked,
    orgUnitId: groupState.objData.orgUnitId || ''
  };
};

export default useGroupInfo;