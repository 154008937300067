import { useEffect, useState } from "react";
import { useNavigate } from "../../../../../common/wrappers/ReactRouterDom";

import NavLinks from "../../NavLinks";

import useGroupData from "../../../../../common/state/group/UseGroupData";
import useGroupFieldNamesData from "../../../../../common/state/groupFieldNames/UseGroupFieldNamesData";

const INITIAL_STATE = {
  isMemberLoaded: false,
  personId: '',
  firstName: '',
  lastName: ''
};

const INITIAL_ERROR_MODAL_STATE = {
  displayModal: false,
  firstName: '',
  lastName: '',
};

const useGroupMemberAdd = () => {
  const navigate = useNavigate();
  const { groupState, putGroup, confirmSave } = useGroupData();
  const { GROUP_CONSTANTS } = useGroupFieldNamesData();
  const [state, setState] = useState(INITIAL_STATE);
  const [errorModalState, setErrorModalState] = useState(INITIAL_ERROR_MODAL_STATE);

  const onBackClicked = () => {
    navigate(NavLinks.GROUP_MEMBERS);
  };

  const onAddMemberSubmitFormCallback = (formState) => {
    if (formState.matchedMember?.personId) {
      const member = formState.matchedMember;

      if (groupState.objData.personOrgGroup.find(x => x.personId === member.personId)) {
        setErrorModalState({
          ...errorModalState,
          displayModal: true,
          firstName: member.firstName || '',
          lastName: member.lastName || ''
        });
      } else if (doesGroupTypeHavePersonFields(groupState.objData?.groupType?.typeName)) {
        setState({
          ...state,
          isMemberLoaded: true,
          personId: member.personId,
          firstName: member.firstName || '',
          lastName: member.lastName || ''
        });
      } else {
        const newGroupObj = JSON.parse(JSON.stringify(groupState.objData));

        newGroupObj.personOrgGroup.push({
          orgGroupId: newGroupObj.orgGroupId,
          personId: member.personId,
          personGroupTypeField: [],
          personOrgGroupQualification: []
        });

        putGroup(newGroupObj.orgGroupId, newGroupObj);
      }
    }
  };

  const onFormCompletion = (groupMemberObj) => {
    const newGroupObj = JSON.parse(JSON.stringify(groupState.objData));

    newGroupObj.personOrgGroup.push(groupMemberObj);

    putGroup(newGroupObj.orgGroupId, newGroupObj);
  };

  useEffect(() => {
    if (groupState.isSaved === true) {
      confirmSave();

      navigate(NavLinks.GROUP_MEMBERS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupState]);

  function doesGroupTypeHavePersonFields(groupTypeName) {
    switch (groupTypeName) {
      case GROUP_CONSTANTS.GROUP_TYPE_NAME_LIST:
        return true;
      case GROUP_CONSTANTS.GROUP_TYPE_NAME_COMPETITOR_LIST:
        return true;
      case GROUP_CONSTANTS.GROUP_TYPE_NAME_CAMP:
        return true;
      case GROUP_CONSTANTS.GROUP_TYPE_NAME_SAA:
        return true;
      case GROUP_CONSTANTS.GROUP_TYPE_NAME_NATIONAL_TEAM:
        return true;
      default:
        return false;
    }
  };

  return {
    GROUP_CONSTANTS,
    isSaving: groupState.isSaving,
    state,
    errorModalState,
    groupObj: groupState.objData || {},
    memberGroupObj: {},
    onBackClicked,
    onAddMemberSubmitFormCallback,
    onFormCompletion,
  };
};

export default useGroupMemberAdd;