import { BASIC_INITIAL_STATE } from '../../utils/HttpHelper';

import searchGroups from './SearchGroupData';
import postGroup from './PostGroupData';
import getGroup from './GetGroupData';
import putGroup from './PutGroupData';
import deleteGroup from './DeleteGroupData';
import getGroupMembersData from './GetGroupMembersData';
import getGroupCoaches from './GetGroupCoachesData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE,
};

const GroupData = {
  INITIAL_STATE, searchGroups, postGroup, getGroup, putGroup, deleteGroup, getGroupMembersData,
  getGroupCoaches
};

export default GroupData;