import { useEffect, useState } from 'react';

import useGroupFieldNamesData from '../../../../../common/state/groupFieldNames/UseGroupFieldNamesData';
import useOrgGroupPropertiesData from '../../../../../common/state/orgGroupProperties/UseOrgGroupPropertiesData';

import UseForm from '../../../../../common/utils/UseForm';
import { createGroupMemberObj, getGroupPropertyOptions, getNewGroupStatus } from '../../../../../common/utils/GroupsUtils';

const INITIAL_FORM_STATE = {
  potentialOrConfirmedId: '',
  potentialOrConfirmedName: '--'
};

const INITIAL_OPTIONS_STATE = {
  areOptionsLoaded: false,
  potentialOrConfirmedOptions: []
};

const useListRegistrationForm = (personId, personOrgGroupId, personOrgGroupQualificationStatus, groupObj, memberGroupObj,
  onFormCompletion) => {
  const { orgGroupPropertiesState, getOrgGroupProperties } = useOrgGroupPropertiesData();
  const { GROUP_CONSTANTS } = useGroupFieldNamesData();
  const { formState, errorState, setFormState, onValueTextPairChanged, handleSubmit
  } = UseForm(INITIAL_FORM_STATE, submitFormCallback, () => { });
  const [optionsState, setOptionsState] = useState(INITIAL_OPTIONS_STATE);

  useEffect(() => {
    if (groupObj.groupTypeId > 0 && optionsState.areOptionsLoaded === false) {
      if (orgGroupPropertiesState.isArrayLoaded === false || groupObj.groupTypeId !== orgGroupPropertiesState.groupTypeId) {
        const getOrgGroupPropertiesPromise = getOrgGroupProperties(groupObj.groupTypeId);

        if (getOrgGroupPropertiesPromise !== null) {
          getOrgGroupPropertiesPromise.catch((e) => {
            //TODO
          });
        }
      } else {
        const potentialOrConfirmedOptions = getGroupPropertyOptions(GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_POTENTIAL_CONFIRMED, orgGroupPropertiesState.arrayData);

        potentialOrConfirmedOptions.unshift({ id: '', name: '--' })

        setOptionsState({
          ...optionsState,
          potentialOrConfirmedOptions,
          areOptionsLoaded: true
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupObj, orgGroupPropertiesState.isArrayLoaded]);

  useEffect(() => {
    if (optionsState.areOptionsLoaded === true && memberGroupObj) {
      const personGroupTypeField = memberGroupObj.personGroupTypeField || [];
      const potentialOrConfirmedName = personGroupTypeField.find(x => x.groupTypeField?.fieldName === GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_POTENTIAL_CONFIRMED)?.fieldValue || '';

      const potentialOrConfirmed = optionsState.potentialOrConfirmedOptions.find(x => x.name === potentialOrConfirmedName);

      setFormState({
        ...formState,
        potentialOrConfirmedId: potentialOrConfirmed?.id || optionsState.potentialOrConfirmedOptions[0]?.id || '',
        potentialOrConfirmedName: potentialOrConfirmed?.name || optionsState.potentialOrConfirmedOptions[0]?.name || '--'
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionsState.areOptionsLoaded, memberGroupObj]);

  function submitFormCallback(formState) {
    const orgGroupId = groupObj?.orgGroupId;
    const personOrgGroupQualification = Array.isArray(memberGroupObj.personOrgGroupQualification) && memberGroupObj.personOrgGroupQualification.length > 0
      ? memberGroupObj.personOrgGroupQualification[0]
      : undefined;

    if (personId && orgGroupId) {
      const groupStatus = getNewGroupStatus(personOrgGroupQualificationStatus, groupObj.requiresApproval);

      const groupMemberObj = createGroupMemberObj(personId, orgGroupId, [
        { fieldName: GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_POTENTIAL_CONFIRMED, fieldValue: formState.potentialOrConfirmedId }
      ], orgGroupPropertiesState.arrayData, personOrgGroupId, personOrgGroupQualification, groupStatus);

      onFormCompletion(groupMemberObj);
    }
  };

  return {
    formState,
    errorState,
    optionsState,
    onValueTextPairChanged,
    handleSubmit
  };
};

export default useListRegistrationForm;