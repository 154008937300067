import React, { Fragment } from 'react';

import YearDropdown from '../../../../components/dropdowns/YearDropdown';

const CompetitorListAttributesForm = ({ formState, errorState, onFormValueChanged }) => (
  <Fragment>
    <div className="row">
      <div className="col-xs-12 col-sm-6 col-md-4">
        <YearDropdown
          label="Year*"
          name="groupYear"
          value={formState.groupYear}
          error={errorState.groupYear}
          message={errorState.groupYear}
          countOfYears={15}
          startYearOffset={-5}
          onChange={(newValue) => { onFormValueChanged('groupYear', newValue) }} />
      </div>
    </div>
  </Fragment>
);

export default CompetitorListAttributesForm;