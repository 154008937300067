import React, { Fragment } from 'react';

import YearDropdown from '../../../../components/dropdowns/YearDropdown';

import DatePicker from '../../../../../common/components/datepickers/DatePicker';
import Input from '../../../../../common/components/inputs/Input';
import StatesCombobox from '../../../../../common/components/comboboxes/statesCombobox/StatesCombobox';

const CampAttributesForm = ({ formState, errorState, onFormValueChanged, onValueTextPairChanged }) => (
  <Fragment>
    <div className="row">
      <div className="col-xs-12 col-sm-6 col-md-4">
        <Input
          label="City*"
          name="city"
          value={formState.city}
          error={errorState.city}
          message={errorState.city}
          onChange={(value) => { onFormValueChanged('city', value) }} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <StatesCombobox
          label="State*"
          name="stateCode"
          valueToMatch={formState.stateName}
          error={errorState.stateCode}
          message={errorState.stateCode}
          isUSA={true}
          onChange={(newValue, newValueLabel, e) => {
            onValueTextPairChanged(newValue, 'stateCode', newValueLabel, 'stateName', e);
          }} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <Input
          label="Zip Code*"
          name="zip"
          value={formState.zip}
          error={errorState.zip}
          message={errorState.zip}
          onChange={(value) => { onFormValueChanged('zip', value) }} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <YearDropdown
          label="Year*"
          name="groupYear"
          value={formState.groupYear}
          error={errorState.groupYear}
          message={errorState.groupYear}
          countOfYears={15}
          startYearOffset={-5}
          onChange={(newValue) => { onFormValueChanged('groupYear', newValue) }} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <DatePicker 
          label="Qualifying Start Date*"
          name="qualifyingStartDate"
          value={formState.qualifyingStartDate}
          error={errorState.qualifyingStartDate}
          message={errorState.qualifyingStartDate}
          onChange={(value) => { onFormValueChanged('qualifyingStartDate', value); }}
          countOfYears={15}
          startYearOffset={-5} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <DatePicker 
          label="Qualifying End Date*"
          name="qualifyingEndDate"
          value={formState.qualifyingEndDate}
          error={errorState.qualifyingEndDate}
          message={errorState.qualifyingEndDate}
          onChange={(value) => { onFormValueChanged('qualifyingEndDate', value); }}
          countOfYears={15}
          startYearOffset={-5} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <DatePicker 
          label="Start Date*"
          name="startDate"
          value={formState.startDate}
          error={errorState.startDate}
          message={errorState.startDate}
          onChange={(value) => { onFormValueChanged('startDate', value); }}
          countOfYears={15}
          startYearOffset={-5} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <DatePicker 
          label="End Date*"
          name="endDate"
          value={formState.endDate}
          error={errorState.endDate}
          message={errorState.endDate}
          onChange={(value) => { onFormValueChanged('endDate', value); }}
          countOfYears={15}
          startYearOffset={-5} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <Input
          label="Total Number of Invited Athletes*"
          name="invitedAthletesCount"
          value={formState.invitedAthletesCount}
          error={errorState.invitedAthletesCount}
          message={errorState.invitedAthletesCount}
          onChange={(value) => { onFormValueChanged('invitedAthletesCount', value) }} />
      </div>
    </div>
  </Fragment>
);

export default CampAttributesForm;