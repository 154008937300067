import { useState } from 'react';

import TimeStandardComboboxData from './TimeStandardComboboxData';

const useTimeStandardComboboxData = () => {
  const [timeStandardComboboxState, setTimeStandardComboboxState] = useState(TimeStandardComboboxData.INITIAL_STATE);

  const getTimeStandards = (orgUnitId, timeStandardTypeId) => TimeStandardComboboxData.getTimeStandardByOrgUnitIdAndTimeStandardTypeId(orgUnitId, timeStandardTypeId, timeStandardComboboxState, setTimeStandardComboboxState);

  return {
    timeStandardComboboxState,
    getTimeStandards
  };
};

export default useTimeStandardComboboxData;