import { CommonHttpHelper, NO_DATA_MESSAGE } from "../../../../../common/utils/HttpHelper";

const localValidate = (formState) => {
  let errors = {};

  if (!(formState.orgGroupId > 0)) {
    errors.orgGroupId = 'A valid Group Id is required'
  }

  return errors;
};

const serverValidate = async (formState) => {
  let errors = {};

  const orgGroupIdForUrl = formState.orgGroupId ? encodeURIComponent(formState.orgGroupId) : 'NaN';
  const url = `/OrgGroup/${orgGroupIdForUrl}`;

  await CommonHttpHelper(url, 'GET')
    .then((objData) => {
      if (!objData) {
        throw new Error(NO_DATA_MESSAGE);
      }
    })
    .catch((e) => {
      errors.apiError = 'Group Id is not valid'
    });

  return errors;
};


const GroupMembersAddValidation = async (formState) => {
  let errors = localValidate(formState);

  if (!errors || Object.keys(errors).length === 0) {
    errors = await serverValidate(formState);
  }

  return errors ? errors : {};
};

export default GroupMembersAddValidation;