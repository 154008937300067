import { useContext } from 'react';

import { GroupAddStateContext } from './GroupAddContextProvider';

import GroupAddData from './GroupAddData';

const useGroupAddData = () => {
  const [groupAddState, setGroupAddState] = useContext(GroupAddStateContext);

  const postGroup = (orgGroupObj) => GroupAddData.postGroup(orgGroupObj, groupAddState, setGroupAddState);

  const updateGroupAddObj = (orgGroupObj, groupTypeName = undefined) => {
    setGroupAddState({
      ...groupAddState,
      isObjLoaded: true,
      objData: orgGroupObj,
      groupTypeName: groupTypeName || groupAddState.groupTypeName
    });
  };

  const clearObjData = () => {
    setGroupAddState({
      ...groupAddState,
      isObjLoading: false,
      isObjLoaded: false,
      isSaving: false,
      isSaved: false,
      objData: JSON.parse(JSON.stringify(GroupAddData.GROUP_OBJ)),
      groupTypeName: '',
      message: ''
    });
  };

  return {
    groupAddState,
    postGroup,
    updateGroupAddObj,
    clearObjData
  };
};

export default useGroupAddData;