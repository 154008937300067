import React, { Fragment } from 'react';
import Headings from '../../../../common/components/headings/Headings';

import useGroupMyGroups from './UseGroupMyGroups';

import SearchMyGroups from '../../../components/searches/SearchMyGroups';

import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import LeftArrowIcon from '../../../../common/components/icons/LeftArrowIcon';

import Constants from '../../../../common/utils/Constants';

import global from '../../../../common/components/GlobalStyle.module.css'

const GroupMyGroups = () => {
  const {
    isLoading,
    searchGroupState,
    selectOrgUnitState,
    errorModalState,
    onAddGroup,
    onEditGroup,
    onSelectOrgUnitClicked
  } = useGroupMyGroups();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <button className={global.HeaderButton}>
            <Headings.H3
              className={[global.NoBottomMargin, 'col-xs-12'].join(' ')}
              excludeBar={true}>
              My Groups
            </Headings.H3>
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <hr className="usas-bar-turmeric" />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <button className={global.BackToSearchButton}
            type="button"
            onClick={onSelectOrgUnitClicked}>
            <LeftArrowIcon /> Select Org Unit
          </button>
        </div>
      </div>
      <div className="row usas-extra-bottom-margin">
        <div className={["col-xs-12", global.HeaderText].join(' ')}>
          <p className={global.HeaderText}><strong>Editing:</strong> {selectOrgUnitState.orgUnitName}</p>
        </div>
      </div>
      <SearchMyGroups
        pageTitle={'Groups'}
        onAddGroup={onAddGroup}
        onEditGroup={onEditGroup}
        searchGroupState={searchGroupState}
        selectOrgUnitState={selectOrgUnitState} />
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={'Error'}
        displayPopUp={errorModalState.displayPopup}
        onModalCanceled={onSelectOrgUnitClicked}>
        <div className="row">
          <div className="col-xs-12">
            <p>No Org Unit Loaded</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={onSelectOrgUnitClicked}>Reload My Groups</PrimaryButton>
          </div>
        </div>
      </PopUpModal>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
    </Fragment>
  );
};

export default GroupMyGroups;