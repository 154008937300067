import { Fragment } from "react";

import ListGroupMemberGridLarge from "./ListGroupMemberGridLarge";
import ListGroupMemberGridSmall from "./ListGroupMemberGridSmall";

const ListGroupMemberGrid = ({ data, isLoading, onEditClicked, onDeleteClicked }) => (
  <Fragment>
    <ListGroupMemberGridLarge data={data} isLoading={isLoading} onEditClicked={onEditClicked} onDeleteClicked={onDeleteClicked} />
    <ListGroupMemberGridSmall data={data} isLoading={isLoading} onEditClicked={onEditClicked} onDeleteClicked={onDeleteClicked} />
  </Fragment>
);

export default ListGroupMemberGrid;