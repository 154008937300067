import { useState } from "react";
import Constants from "../../../common/utils/Constants";

import GroupAlternateMembersData from "./GroupAlternateMembersData";

const useGroupAlternateMembersData = () => {
  const [groupAlternateMembersState, setGroupAlternateMembersState] = useState(GroupAlternateMembersData.INITIAL_STATE);

  const getGroupAlternateMembers = (orgGroupId) => GroupAlternateMembersData.getGroupAlternateMembersData(orgGroupId, groupAlternateMembersState, setGroupAlternateMembersState);

  const getGroupAlternateMembersIMX = (orgGroupId) => GroupAlternateMembersData.getGroupAlternateMembersIMXData(orgGroupId, groupAlternateMembersState, setGroupAlternateMembersState);

  const putGroupAlternateMembers = (orgGroupId, alternateMembersArray) => GroupAlternateMembersData.putGroupAlternateMembersData(orgGroupId, alternateMembersArray, groupAlternateMembersState, setGroupAlternateMembersState);

  const putGroupAlternateMembersIMX = (orgGroupId, alternateMembersArray) => GroupAlternateMembersData.putGroupAlternateMembersIMXData(orgGroupId, alternateMembersArray, groupAlternateMembersState, setGroupAlternateMembersState);

  const updateMemberEventIsSelected = (groupQualificationEventId) => {
    const targetIndex = groupAlternateMembersState.arrayData.findIndex(x => x.groupQualificationEventId === groupQualificationEventId);

    if (targetIndex > Constants.DEFAULT_ID) {
      const newArrayData = JSON.parse(JSON.stringify(groupAlternateMembersState.arrayData));
      newArrayData[targetIndex].isSelected = !groupAlternateMembersState.arrayData[targetIndex].isSelected;

      setGroupAlternateMembersState({
        ...groupAlternateMembersState,
        arrayData: newArrayData
      });
    }
  };

  const updateMemberIMXIsSelected = (groupQualificationImxId) => {
    const targetIndex = groupAlternateMembersState.arrayData.findIndex(x => x.groupQualificationImxId === groupQualificationImxId);

    if (targetIndex > Constants.DEFAULT_ID) {
      const newArrayData = JSON.parse(JSON.stringify(groupAlternateMembersState.arrayData));
      newArrayData[targetIndex].isSelected = !groupAlternateMembersState.arrayData[targetIndex].isSelected;

      setGroupAlternateMembersState({
        ...groupAlternateMembersState,
        arrayData: newArrayData
      });
    }
  };

  return {
    groupAlternateMembersState,
    getGroupAlternateMembers,
    getGroupAlternateMembersIMX,
    putGroupAlternateMembers,
    putGroupAlternateMembersIMX,
    updateMemberEventIsSelected,
    updateMemberIMXIsSelected
  };
};

export default useGroupAlternateMembersData;